import { DEFAULT_LOCALE } from "../../lib/constants"
import { useLocation, useParameters } from "../../utils";
import utils from '../../lib/utils'
import { getStateParam, addRehydrateListener, rehydrate } from "../container"
import { useHistory } from "react-router";
import React, { useEffect } from "react";

export function LangaugeConatiner(p: { children?: React.ReactNode}) {
    const params = useParameters<{
        lang: string
    }>();
    const location = useLocation();
    const nav = useHistory();
    function onEnterLanguageMatch() {
        /*
        Responsibilities:
        - Handle path of the form base/lang/<whatever>
        - Redirect to valid language if needed
        - Add trailing slash if needed
        */
        if (getStateParam('noStore').rehydrated) {
            let path = location.pathname
            let lang = params.lang
            if (!utils.isLanguageSupported(lang)) {
                nav.replace(
                    '/' +
                    DEFAULT_LOCALE +
                    utils.forceTrailingSlash(path) +
                    location.search
                )
            }
            if (!utils.hasTrailingSlash(path)) {
                nav.replace(utils.forceTrailingSlash(path) + location.search)
                return
            }
        } else {
            addRehydrateListener(onEnterLanguageMatch)
            rehydrate(params.lang)
        }
    }
    useEffect(onEnterLanguageMatch, [params.lang]);
    return <> 
        {p.children}
    </>
}