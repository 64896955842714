import React from 'react'

class ConfirmationsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSpinner: true,
      showFail: false,
      allowConfirmation:true
    }
  }
  componentWillMount() {
    const key = this.props.location.query.key
    if (key && this.state.allowConfirmation) {
      this.props.onConfirmAccount(key)
        .then(() => {
          // Show success info, link to login
          this.setState({ showSpinner: false, showFail: false, blockAutoClick: false, allowConfirmation: false })
        })
        .catch(e => {
          // Show fail info
          this.setState({ showSpinner: false, showFail: false, blockAutoClick: false, allowConfirmation: false })
        })
    }
  }
  componentDidMount() {
    let currentLocationUrl = new URLSearchParams(window.location.search)
    let eBookUrl = currentLocationUrl.get('bookUrl')
    this.autoDownload(eBookUrl)
  }

  autoDownload(eBookUrl) {
    if (eBookUrl) {
      document.querySelector('#ebook-download-element').click();
    }
  }
  render() {
    let currentLocationUrl = new URLSearchParams(window.location.search)
    let eBookUrl = currentLocationUrl.get('bookUrl')
    var content = null
    if (this.state.showSpinner) {
      content = (
        <div>
          Loading...
        </div>
      )
    }
    else if (this.state.showFail) {
      content = (
        <div>
          Failed to activate the account
        </div>
      )
    }
    else {
      const action_type = this.props.location.query.action_type
      if (action_type == 'base_activation' || action_type == 'sn_and_base_activation') {
        content = (
          <div>
            <div>
              <h1>Your account has been activated</h1>
              <p>Please proceed by <a onClick={this.props.onShowSignupLoginModal.bind(null)}>logging in.</a></p>
            </div>
            <a className="confirmation-page-ebook-container" style={{ display: eBookUrl ? "block" : "none" }} download="Ebook.pdf" href={`https://designrr.s3.amazonaws.com/marketing_at_handiscover.com_30843/${decodeURIComponent(eBookUrl)}`} target="_blank">
              <div className="confirmation-page-ebook-button">
                <p>
                  Download eBook
                  </p>
              </div></a>
          </div>
        )
      }
      else if (action_type == 'sn_activation') {
        content = (
          <div>
            <div>
              <h1>Your account has been activated</h1>
              <p>Please proceed by <a onClick={this.props.onShowSignupLoginModal.bind(null)}>logging in.</a></p>
            </div>
            <a className="confirmation-page-ebook-container" style={{ display: eBookUrl ? "block" : "none" }} download="Ebook.pdf" href={`https://designrr.s3.amazonaws.com/marketing_at_handiscover.com_30843/${decodeURIComponent(eBookUrl)}`} target="_blank">
              <div className="confirmation-page-ebook-button">
                <p>
                  Download eBook
                  </p>
              </div></a>
          </div>
        )
      }
      else {
        //Fail
        content = (
          <div>
            Fail
          </div>
        )
      }
    }

    return (
      <div className='confirmations-page'>
        {content}
        <a style={{ display: "none" }} id="ebook-download-element" download="Ebook.pdf" href={`https://designrr.s3.amazonaws.com/marketing_at_handiscover.com_30843/${decodeURIComponent(eBookUrl)}`} target="_blank"></a>
      </div>
    )
  }
}

export default ConfirmationsPage
