import React from 'react'

class Banner extends React.Component{
  constructor (props){
    super(props)
    this.renderBannerContent = this.renderBannerContent.bind(this)
    this.closeBanner = this.closeBanner.bind(this)
    this.state = {
      show: true
    }
  }
  componentDidMount(){
    const closedCovidBanner = sessionStorage.getItem('covid-banner')
    if(closedCovidBanner === 'closed'){
      this.setState({show: false})
    }
  }
  componentDidUpdate(){
    if(this.state.show){
      const closedCovidBanner = sessionStorage.getItem('covid-banner')
      if(closedCovidBanner === 'closed'){
        this.setState({show: false})
      }
    }
  }
  closeBanner(e){
    e.preventDefault()
    sessionStorage.setItem('covid-banner', 'closed')
    this.props.closeBanner()
  }

  renderBannerContent(){
    return(
      <div className="banner-wrapper" >
        <a className="banner-content" data-nosnippet="data-nosnippet" href="https://handiscover.zendesk.com/hc/en-us/articles/207601105-What-does-cancellation-policy-mean-" target="blank">
          <div>
          Updated host cancellation policy considering actual COVID-19 situation
          </div>
        </a>
        <div className="banner-button-wrapper">
          <button className="banner-button" onClick={this.closeBanner}>X</button>
        </div>
      </div>
    )
  }
  render(){
    let bannerLayout = null
    if(this.props.show && this.state.show){
      bannerLayout = this.renderBannerContent()
    }
    return (
      bannerLayout
    )
  }
}

export default Banner