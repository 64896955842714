'use strict'
import React from 'react'
import Modal from './modal.jsx'
import FormGenerator from '../lib/form-generator.jsx'
import clamp from '../vendor/clamp.js'
import utils from '../lib/utils.js'
import InputField from './input-field.jsx'
import { withTranslation as translate } from 'react-i18next'

function getDefaultModalStyle() {
  return {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    content: {
      position: 'relative',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '568px',
      background: '#fff',
      border: 'none',
      borderRadius: '3px',
      outline: 'none',
      padding: 0,
      margin: 0,
      boxSizing: 'content-box',
    }
  }
}

class Signup extends React.Component {
  constructor(props) {
    super(props)
    const _t = this.props.t
    const signupGen = new FormGenerator(_t('signup_login_create'))
    signupGen.addField({ name: 'firstName', placeholder: _t('profile_page_first_name'), infoBubbleText: 'Your full name', errorBubbleText: _t('settings_page_error_first_name') })
    signupGen.addField({ name: 'lastName', placeholder: _t('profile_page_last_name'), infoBubbleText: 'Your full name', errorBubbleText: _t('settings_page_error_last_name') })
    signupGen.addField({ name: 'email', placeholder: _t('general_email'), autoFocus: true, errorBubbleText: _t('settings_page_error_email') })
    signupGen.addField({ name: 'password', placeholder: _t('general_password'), infoBubbleText: utils.passwordDescription, errorBubbleText: utils.passwordDescription, isPassword: true })
    signupGen.addField({ name: 'confirmPassword', placeholder: _t('signup_login_password_repeat'), isPassword: true, infoBubbleText: 'Enter your password again', errorBubbleText: _t('settings_page_password_error_mismatch') })

    signupGen.addValidator(utils.isNotEmptyString, null, 'firstName')
    signupGen.addValidator(utils.isNotEmptyString, null, 'lastName')
    signupGen.addValidator(utils.isValidEmail, null, 'email')
    signupGen.addValidator(utils.isValidPassword, null, 'password')
    signupGen.addValidator(utils.isEqualStrings, null, 'password', 'confirmPassword')
    this.signupGen = signupGen

    this.state = {
      agreeToTos: true,
      displayMsg: "",
      showRawServerError: true,
    }
  }
  componentWillMount() {
    this.SignupForm = this.signupGen.generate()
  }
  onSubmit(formFields) {
    this.props.onSignup(
      formFields.firstName.value,
      formFields.lastName.value,
      formFields.email.value,
      formFields.password.value,
      {})
      .then(() => {
        // Show email confirmation info
        if (this.props.onSignupSuccess) {
          this.props.onSignupSuccess()
        }
        this.props.onShowEmailConfirmationInfo()
      })
      .catch(msg => {
        this.props.onDebug('signup', msg)
        var displayMsg = "";
        for (const prop in msg) {
          if (prop === "status") continue;
          displayMsg += msg[prop] instanceof Array ? msg[prop].join(" ") : msg[prop];
        }
        this.setState({ displayMsg });
      })
  }

  onSubmitFailed() {
    const el = document.getElementsByClassName('ReactModal__Content')[0]
    utils.scrollToY(el, 0, 2500)
  }

  render() {
    const _t = this.props.t
    const { SignupForm } = this
    return (
      <div className='signup-login signup'>
        <div>
          <SignupForm
            // ref={form => this.signupForm = form}
            onSubmit={this.onSubmit.bind(this)}
            onSubmitFailed={this.onSubmitFailed.bind(this)} />
          {this.state.showRawServerError && (
            <div className="signup-login-error">
              {_t(this.state.displayMsg)}
            </div>
          )}
          <p className="tos">{_t('signup_join_intro')} <a href={`${HOST_URL}/${this.props.state.locale}/terms/`}>{_t('general_tos')}</a>
            &nbsp;{_t('general_and')} <a href={`${HOST_URL}/${this.props.state.locale}/privacy-policy/`}>{_t('privacy_policy')}</a></p>
        </div>
      </div>
    )
  }
}


class Login extends React.Component {
  constructor(props) {
    super(props)
    const _t = this.props.t
    this.state = {
      showPasswordReset: false,
      showPasswordResetSent: false,
      showLoginError: false,
      showRawServerError: false
    }

    var loginGen = new FormGenerator(_t('menu_login'))
    loginGen.addField({ name: 'email', placeholder: _t('general_email'), autoFocus: true, errorBubbleText: _t('settings_page_error_email') })
    loginGen.addField({ name: 'password', placeholder: _t('general_password'), isPassword: true, errorBubbleText: _t('settings_page_password_error') })
    loginGen.addValidator(utils.isValidEmail, null, 'email')
    loginGen.addValidator(utils.isValidPassword, null, 'password')
    this.loginGen = loginGen

    var passwordResetGen = new FormGenerator(_t('general_send'))
    passwordResetGen.addField({ name: 'email', placeholder: _t('general_email'), autoFocus: true, errorBubbleText: _t('settings_page_error_email') })
    passwordResetGen.addValidator(utils.isValidEmail, null, 'email')
    this.passwordResetGen = passwordResetGen
  }

  componentWillMount() {
    this.LoginForm = this.loginGen.generate()
  }

  onPasswordResetSubmit(formFields) {
    this.props.onSendPasswordReset(formFields.email.value)
      .then(() => {
        // Show info
        this.setState({ showPasswordReset: false, showPasswordResetSent: true })
      })
      .catch(e => {
        // (external) error msg?
        this.setState({ showPasswordReset: false })
      })
  }

  onSubmit(formFields) {
    this.props.onLogin(
      formFields.email.value,
      formFields.password.value)
      .catch(err => {
        let errorCode = 'security_bad_password'
        if (err.status && err.status === 401) {
          errorCode = 'security_inactive_account'
        }
        this.setState({
          showLoginError: errorCode
        })
        throw new Error(errorCode);
      })
      .then(this.props.onFetchProfile)
      .then(() => {
        // Success callback
        if (this.props.onLoginSuccess) {
          this.props.onLoginSuccess()
        }

        this.props.onRequestClose()
      })
      .catch(err => {
        this.props.onDebug('login', err)
      })
  }

  onChange() {
    if (this.state.showLoginError) {
      /* buggy because we have re-render after setState, and form lost a focus from input field
      when user typing something */
      this.setState({
        showLoginError: false
      })
    }
  }

  render() {
    const _t = this.props.t
    var extErrMsg = null
    // if (this.props.loginRequest.requestInitiated && this.props.loginRequest.requestFailed) {
    //   extErrMsg = 'Login failed'
    // }

    var content = null
    if (this.state.showPasswordReset) {
      const PasswordResetForm = this.passwordResetGen.generate()
      content = (
        <PasswordResetForm
          onSubmit={this.onPasswordResetSubmit.bind(this)} />
      )
    }
    else if (this.state.showPasswordResetSent) {
      content = (
        <div>
          <h4>{_t('settings_page_password_reset')}</h4>
        </div>
      )
    }
    else {
      const { LoginForm } = this;
      content = (
        <div>
          <LoginForm
            key="loginForm"
            onSubmit={this.onSubmit.bind(this)}
            onChange={this.onChange.bind(this)}
            extErrMsg={extErrMsg} />
          {this.state.showLoginError && (
            <div className="signup-login-error">
              {_t(this.state.showLoginError)}
            </div>
          )}
          <a onClick={() => this.setState({ showPasswordReset: true })}>{_t('settings_page_password_forgot')}</a>
        </div>
      )
    }

    return (
      <div className='signup-login login'>
        {content}
      </div>
    )
  }
}
const emailGen = new FormGenerator('Send')
emailGen.addField({ name: 'email', placeholder: 'Email', autoFocus: true, errorBubbleText: "This email address doesn´t look right" })
emailGen.addValidator(utils.isValidEmail, null, 'email')
const EmailForm = emailGen.generate()

class SignupLoginModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // Modalsource is a way to keep track of the
      // component where the user opened the form.
      // Good for e.g. signup funnels
      modalSource: null,
      fbAskForEmail: false,
      showEmailConfirmationInfo: false,
    }
  }
  onGoogleAuth() {
    if (!this.props.state.noStore.googleAuthRequestInFlight) {
      window.googleAuth.signIn()
        .then(googleUser => {
          const basicProfile = googleUser.getBasicProfile()
          const googleToken = googleUser.getAuthResponse(true).id_token
          const firstName = basicProfile.getGivenName()
          const lastName = basicProfile.getFamilyName()
          const imageUrl = basicProfile.getImageUrl()
          const email = basicProfile.getEmail()
          this.props.onGoogleAuth(googleToken, email)
            .then(this.props.onFetchProfile)
            .then(() => {
              this.props.refreshUserData()
                .then(() => {
                  if (this.props.onSignupSuccess) {
                    this.props.onSignupSuccess()
                  }

                  this.props.onRequestClose()
                })
            })
            .catch(msg => {
              this.props.onDebug('google_login', { msg, token })
              if (DEBUG) { console.log('sign out google.', msg) }
              // TODO: Replace with proper signout ??
              window.googleAuth.signOut()
            })
        }, e => {
          // Auth failed, should we do anything here at all?
          if (DEBUG) {
            console.log('gerr', e)
          }
        })
    }
  }

  onFacebookAuth() {
    window.FB.login(response => {
      if (response.status === 'connected') {
        // Logged into your app and Facebook.
        // TODO: look for email
        console.log('facebook:', response)
        window.FB.api('/me', { fields: 'email' }, meResponse => {
          console.log('Successful login for:', meResponse)
          this.facebookAccessToken = response.authResponse.accessToken
          this.facebookUser = meResponse
          // Check for email?
          this.props.onFacebookAuth(response.authResponse.accessToken, meResponse.email)
            .then(this.props.onFetchProfile)
            .then(() => {
              // Success callback
              if (this.props.onSignupSuccess) {
                this.props.onSignupSuccess()
              }

              this.props.onRequestClose()
            })
            .catch(msg => {
              this.props.onDebug('fb_login', { msg, fb_res: meResponse })
              // Check for email?
              if (msg.status === 404) {
                this.setState({ fbAskForEmail: true })
              }
            })
        })
      }
      else {
        // The person is not logged into this app or we are unable to tell.
        console.log('facebook fail?')
        this.props.onDebug('fb_login2', { msg: 'fb fail', fb_res: response })
      }
    },
      {
        scope: 'public_profile, email'
      })
  }

  onFbEmailSubmit(formData) {
    this.props.onFacebookAuth(this.facebookAccessToken, formData.email.value)
      .then(() => {
        // Success callback
        if (this.props.onSignupSuccess) {
          this.props.onSignupSuccess()
        }

        this.setState({ fbAskForEmail: false, showEmailConfirmationInfo: true })
      })
      .catch(e => {
        // console.log('wtf', e)
      })
  }

  onRequestClose() {
    this.props.onRequestClose()
  }

  onShowEmailConfirmationInfo() {
    this.setState({ showEmailConfirmationInfo: true })
  }

  onToggleLoginClick() {
    if (this.props.onToggleLoginClick) {
      this.props.onToggleLoginClick()
    }
  }

  render() {
    const _t = this.props.t
    if (!this.props.isOpen) {
      return <div></div>
    }

    var modalContent = null
    if (this.state.showEmailConfirmationInfo) {
      modalContent = (
        <div id='signup-login-modal' className='signup-login-modal'>
          <h1>{_t('settings_page_email_confirmation_header')}</h1>
          <p>{_t('settings_page_email_confirmation_desc')}</p>
        </div>
      )
    }
    else {
      var content = null
      if (this.props.showLogin) {
        content = <Login {...this.props} />
      }
      else {
        content = <Signup

          onShowEmailConfirmationInfo={this.onShowEmailConfirmationInfo.bind(this)}
          {...this.props} />
      }

      var switchText = null
      var title = null
      if (this.props.showLogin) {
        switchText = _t('settings_page_member_not') + ' ' + _t('settings_page_member_join')
        title = _t('menu_login')
      }
      else {
        switchText = _t('settings_page_member_already')
        title = _t('settings_page_member_join')
      }

      // Add this to modal later..
      // isLoading={this.props.state.noStore.signupRequestInFlight || this.props.state.noStore.loginRequestInFlight}
      var social = null
      if (this.state.fbAskForEmail) {
        social = (
          <EmailForm
            onSubmit={this.onFbEmailSubmit.bind(this)}
          />
        )
      }
      else {
        social = (
          <div>
            <button
              className='fb-login-button social-login-button'
              id='fb-button'
              onClick={this.onFacebookAuth.bind(this)}>
              Login with Facebook
            </button>
            <button
              id='google-login-button'
              className='g-signin2 social-login-button'
              onClick={this.onGoogleAuth.bind(this)}>
              Login with Google
            </button>
          </div>
        )
      }

      modalContent = (
        <div id='signup-login-modal' className='signup-login-modal'>
          <h1>{title}</h1>
          {social}
          <div className='divider'>
            <div className='separator'></div>
            <p className='or'>{_t('general_or')}</p>
            <div className='separator'></div>
          </div>
          {content}
          <div className='switch-area'>
            <a className='switch-link' onClick={this.onToggleLoginClick.bind(this)}>{switchText}</a>
          </div>
        </div>
      )

    }

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.onRequestClose.bind(this)}
        isLoading={this.props.state.noStore.googleAuthRequestInFlight || this.props.state.noStore.facebookAuthRequestInFlight || this.props.state.noStore.loginRequestInFlight || this.props.state.noStore.signupRequestInFlight}
        style={getDefaultModalStyle()}>
        {modalContent}
      </Modal>
    )
  }

  setModalSource(modalSource) {
    this.setState({
      modalSource: modalSource,
    })
  }
}

export default translate()(SignupLoginModal)
