import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import utils from '../../lib/utils';
import { useEffectAsync, useHistory, useLocation } from '../../utils';
import { getStateParam } from '../container';
import { Suspense } from './custom-routes';
declare global {
    const API_URL: string
}
const PropertyPage = React.lazy(() => import('../property-page/property-page.jsx'));
export function SeoRedirect(p: any) {
    const location = useLocation();
    const [loaded, settLoaded] = useState(false);
    let path: string = useParams<{ 0: string }>()[0];
    let history = useHistory();
    async function seoRedirect() {
        try {
            let components = location.pathname.split('/')
            console.log(components);
            if (components[2] === 'content') {
                path = `${components[1]}/content/${components[4]}`;
            }
            if (components.includes('accounts')) {
                // for account links it should just pass the seo Redirect
                return
            }
            const res = await fetch(`${API_URL}/properties/${path}`, {
                method: 'GET'
            })
            const resForOutsideLinks = !res.ok ? await fetch(`${API_URL}/properties${location.pathname}`, { method: 'GET' }) : null
            if (!res.ok && (!resForOutsideLinks?.ok || !resForOutsideLinks) ) {
                throw res
            }

            const data: { property: unknown, owner: unknown } = await res?.json();
            const dataOutside: { property: unknown, owner: unknown } = await resForOutsideLinks?.json();

            if ((data.property || data.owner) || (dataOutside.property || dataOutside.owner)) {
                settLoaded(true);
            } else {
                // No path, go to search
                let p = utils.forceTrailingSlash(path);
                history.replace(
                    `/${getStateParam('locale')}/search/${p}${location.search}`
                )
            }
        } catch (e) {
            console.log(e);
            history.push(`/${getStateParam('locale')}/404`);
        }
    }
    useEffectAsync(seoRedirect, []);
    return !loaded ? <></> :
        <Suspense fallback={<div></div>}><PropertyPage {...p}></PropertyPage></Suspense>;

}