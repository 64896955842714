import { NamedObject } from "../../models/NamedObject";
import { NamedDeclaration } from "typescript";
import { lang } from "moment";
import { useEffect } from "react";


export function simpleLang(lang: string | undefined) {
  return lang?.split("-")?.[0];
}

export type PostCollections = "ambassadors" | "ebook" | "travel-info" | "post" | "destination" | "page" | "all" | "press-releases"
export function blogUrl(
  lang: string,
  post: { type?: string; orignalLang?: string, id: number, slug?: string, languages?: Array<{ id: number, languageCode: string, slug: string }> },
  collection: PostCollections = "post") {
  let orignalLang = post.orignalLang;
  collection = (post.type ?? collection) as PostCollections ;
  if (!orignalLang) {
    orignalLang = post.languages?.find(p => p.id === post.id)?.languageCode;
  }
  const langEntry = post.languages?.find(p => simpleLang(p.languageCode) === simpleLang(lang));
  if (langEntry) {
    return `/${lang}/content/${collection}/${langEntry.slug}`
  }
  if (orignalLang && simpleLang(orignalLang) != simpleLang(lang)) {
    return `/${lang}/content/${collection}/${post.slug}--${orignalLang}`;
  }
  else {
    return `/${lang}/content/${collection}/${post.slug}`;
  }
}

export function blogAllResults(lang: string, collection: PostCollections = "post") {
  return `/${lang}/content/results/${collection}`;
}

export function blogCategoryUrl(lang: string, category?: string, collection: PostCollections = "post") {
  return `/${lang}/content/results/${collection}/category/${category}`;
}

export async function changeLanguage<T extends {
  id: number,
  languages: Array<{ id: number, languageCode: string }>
}>({ blog, get, getByLang, language }: {
  blog: T,
  language: string,
  get: (id: number) => Promise<T>,
  getByLang: (id: number, lang: string) => Promise<T>,
}) {
  let blogEntry = blog.languages.find(o => simpleLang(o.languageCode) === simpleLang(language));

  if (blogEntry) {
    if (blogEntry.id === blog.id && !('isTranslated' in blog)) {
      return {
        ...blog,
        originalLang: language
      };
    }
    return {
      ...await get(blogEntry.id),
      originalLang: language
    };
  } else {
    return {
      ...blog,
      originalLang: language
    };
  }
}

export function prepareCategories(categories?: NamedObject[], includeKey: boolean = false) {
  if (!categories) {
    return { keyCategory: undefined, displayCategories: undefined }
  }
  let keyCategory: NamedObject | undefined = undefined;
  let displayCategories: NamedObject[] | undefined;
  for (const c of categories) {
    if (c.isKeyCategory && !keyCategory) {
      keyCategory = c;
      if (!includeKey) continue;
    }
    if (!c.isSuperCategory) {
      if (!displayCategories) {
        displayCategories = []
      }
      displayCategories.push(c);
    }
  }
  return { keyCategory, displayCategories }
}

export function hideBlockByClassName(className: string, content: string) {
  useEffect(() => {
    const queryElements = document.querySelectorAll<HTMLElement>(className);
    queryElements.forEach(e => {
      e.style.display = 'none';
    });
  }, [content]);
}

export function useAddTargetBlankAndUrls(classNameTarget: string, classNameUrl: string, content: string) {
  useEffect(() => {
    const queryElements = document.querySelectorAll(classNameUrl)
    queryElements.forEach(e => {
      e.addEventListener('click', clickEvent => {
        window.open(e.getAttribute('data-url')!);
      })
    })

    const anchorElements = document.querySelectorAll(classNameTarget)
    anchorElements.forEach(e => {
      e.setAttribute('target', '_blank')
    })
  }, [content])
}
export function removeOldUrlsDivsAndAnchors(anchorChange: boolean, content: string) {
  useEffect(() => {
    if (anchorChange) {
      const queryAnchor = document.querySelectorAll('a')
      queryAnchor.forEach(e => {
        if (e.getAttribute('href')?.includes('blog.handiscover.com')){
          e.setAttribute('href', `${e.getAttribute('href')?.replace('blog.handiscover.com','handiscover.com')}`)
        }
      })
    }
  }, [content])
}