import React from 'react'
import Modal from 'react-modal'
import UserDisabilityQuestions from './user-disability-questions.jsx'
import axios from 'axios'

class UserDisabilityForm extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      deviceSize: this.props.deviceSize,
      modal: this.props.open,
      question: 1,
      start: true,
      finish: false,
      residence: '',
      destinationField:'',
      destinations: [],
      questionWhoValue: '',
      questionAgeValue: 18,
      disabilities: [],
      mobileEquipment: [],
      allergies: [],
      mobility: 0,
      mobileModal: false,
      allergyExtraInfo: [],
      mobilityEquipmentExtraInfo: []
    }
    this.showTabs = this.showTabs.bind(this)
    this.renderFormHeader = this.renderFormHeader.bind(this)
    this.renderStart = this.renderStart.bind(this)
    this.renderQuestions = this.renderQuestions.bind(this)
    this.renderInnerContent = this.renderInnerContent.bind(this)
    this.onClickNextBtn = this.onClickNextBtn.bind(this)
    this.onClickBackBtn = this.onClickBackBtn.bind(this)
    this.renderFinish = this.renderFinish.bind(this)
    this.getTabClassName = this.getTabClassName.bind(this)
    this.onResidenceChange = this.onResidenceChange.bind(this)
    this.onQuestionWhoChange = this.onQuestionWhoChange.bind(this)
    this.questionsValidation = this.questionsValidation.bind(this)
    this.onQuestionAgeChange = this.onQuestionAgeChange.bind(this)
    this.onDestinationsChange = this.onDestinationsChange.bind(this)
    this.onDisabilitiesChange= this.onDisabilitiesChange.bind(this)
    this.onMobileEquipmentChange = this.onMobileEquipmentChange.bind(this)
    this.onMobilityChange = this.onMobilityChange.bind(this)
    this.renderFormHeaderMobile = this.renderFormHeaderMobile.bind(this)
    this.renderMobileFinish = this.renderMobileFinish.bind(this)
    this.postUserDisabilityProfile = this.postUserDisabilityProfile.bind(this)
    this.onAllergiesChange = this.onAllergiesChange.bind(this)
    this.onDestinationFieldChange = this.onDestinationFieldChange.bind(this)
    this.onAddCustomAllergy = this.onAddCustomAllergy.bind(this)
    this.onAddEquipmentExtraInfo = this.onAddEquipmentExtraInfo.bind(this)
    this.renderButtons = this.renderButtons.bind(this)
    this.onClickSkipBtn = this.onClickSkipBtn.bind(this)
  }
  componentDidUpdate(prevProps){
    if(prevProps.open !== this.props.open){
      window.zE('webWidget', 'hide');
      this.setState({modal: this.props.open})
    }
  }
  async postUserDisabilityProfile(){
    window.zE('webWidget', 'show')
    const {
      disabilities,
      mobileEquipment,
      questionWhoValue,
      questionAgeValue,
      residence,
      destinations,
      mobility,
      allergies,
      allergyExtraInfo,
      mobilityEquipmentExtraInfo
    } = this.state
    let params = {
      type_of_disability: disabilities,
      traveling_group: questionWhoValue,
      age: questionAgeValue,
      located_in: residence,
      travel_to: destinations,
      mobility_class: mobility,
    }
    if(mobileEquipment.length){
      params.mobility_equipment = mobileEquipment
    }
    if(allergies.length){
      params.allergy_type = allergies
    }
    if(allergyExtraInfo.length){
      params.allergy_extra_info = allergyExtraInfo
    }
    if(mobilityEquipmentExtraInfo.length){
      params.mobility_equipment_extra_info = mobilityEquipmentExtraInfo
    }
    await axios.post(`${API_URL}/users/disability-profile/`,
      params
      , {headers: { 'Authorization': 'Token ' + this.props.state.accessToken } }
    ).then(()=>{
      this.props.onFetchProfile().then(()=>{
        this.props.closeUserDisabilityForm() 
      })
    }
    )
    window.location.href="https://www.handiscover.com/content/home"
    
  }
  showTabs(){
    this.setState({start: false})
  }
  onAddCustomAllergy(allergyExtraInfo){
    this.setState({allergyExtraInfo})
  }
  onAddEquipmentExtraInfo(mobilityEquipmentExtraInfo){
    this.setState({mobilityEquipmentExtraInfo})
  }
  onMobilityChange(mobility){
    this.setState({mobility})
  }
  onDisabilitiesChange(disabilities){
    this.setState({disabilities})
  }
  onResidenceChange(residence){
    this.setState({residence})
  }
  onDestinationsChange(destinations){
    this.setState({destinations, destinationField: ''})
  }
  onDestinationFieldChange(destinationField){
    this.setState({destinationField})
  }
  onQuestionWhoChange(questionWhoValue){
    this.setState({ questionWhoValue })
  }
  onQuestionAgeChange(questionAgeValue){
    this.setState({ questionAgeValue })
  }
  onMobileEquipmentChange(mobileEquipment){
    this.setState({ mobileEquipment })
  }
  onAllergiesChange(allergies){
    this.setState({ allergies })
  }
  questionsValidation(){
    const {question,
      questionWhoValue,
      questionAgeValue,
      residence,
      destinations,
      disabilities,
      mobileEquipment,
      mobility,
      allergies,
      allergyExtraInfo
    } = this.state
    const questionsData = {
      '1': questionWhoValue,
      '1.1': questionAgeValue,
      '2': residence,
      '3': destinations.length,
      '4': disabilities.length,
      '4.1': mobileEquipment,
      '4.2': allergies.length || allergyExtraInfo,
      '5': mobility
    }
    if(questionsData[question]){
      return false
    }
    return true
  }
  onClickSkipBtn(){
    localStorage.setItem('userDisabilityProfileSkipTime', +new Date())
    window.zE('webWidget', 'show')
    this.setState({modal: false})
  }
  renderButtons(){
    const backButton = (
      <button
        className='user-disability-modal__button user-disability-modal__button_back'
        onClick={this.onClickBackBtn}
      >
        {this.props.t('back')}
      </button>
    ) 
    const nextButton = (
      <button
        className='user-disability-modal__button user-disability-modal__button_next'
        onClick={this.onClickNextBtn}
        disabled={this.questionsValidation()}
      >
        {this.props.t('next')}
      </button>
    )
    const skipButton = (
      <button
        className='user-disability-modal__button user-disability-modal__button_skip'
        onClick={this.onClickSkipBtn}
      >
        skip
      </button>
    )
    if (this.state.question === 1){
      return(
        <div className='user-disability-modal__buttons-wrapper'>
          {skipButton}
          {nextButton}
        </div>
      )
    }
    return(
      <div className='user-disability-modal__buttons-wrapper'>
        {backButton}
        {nextButton}
      </div>
    )
  }
  onClickNextBtn(){
    let {question,
      disabilities
    } = this.state
    if(question === 4 && disabilities.includes('mobility_impairment')){
      question = 4.1
    } else if(question === 4 && disabilities.includes('allergy') ||
    question === 4.1 && disabilities.includes('allergy')){
      question = 4.2
    }
    else{
      switch(question){
      case 1:
        question = 1.1
        break
      case 1.1:
        question = 2
        break
      case 4:
        question = 5
        break
      case 4.1:
        question = 5
        break
      case 4.2:
        question = 5
        break   
      default:
        question = ++question         
      }
    }
    if(question > 5){
      if(this.state.deviceSize !== 'lg'){
        this.setState({finish: true, mobileModal: true})
      }
      this.setState({finish: true})
    }
    else{
      this.setState({question})
    }
  }
  onClickBackBtn(){
    let {question,disabilities} = this.state
    if(question === 5 && disabilities.includes('allergy')){
      question = 4.2
    } else if(question === 5 && disabilities.includes('mobility_impairment') ||
    question === 4.2 && disabilities.includes('mobility_impairment')){
      question = 4.1
    }
    else{
      switch(question){
      case 5:
        question = 4
        break
      case 4.2:
        question = 4
        break  
      case 4.1:
        question = 4
        break
      case 2:
        question = 1.1
        break
      case 1.1:
        question = 1
        break
      default:
        question = --question         
      }
    }
    
    if(question < 1){
      this.setState({start: true})
    }
    else{
      this.setState({question})
    }
  }
  renderMobileFinish(){
    const _t = this.props.t
    return(
      <Modal
        isOpen={this.state.mobileModal}
        contentLabel='mobile-finish'
        onRequestClose={this.props.closeUserDisabilityForm}
        overlayClassName={{
          base: 'user-disability-modal-mobile-finish',
          afterOpen: 'user-disability-modal-mobile-finish__after-open',
          beforeClose: 'user-disability-modal-mobile-finish__before-close'
        }}
        className={{
          base: 'user-disability-modal-mobile-finish__content',
          afterOpen: 'user-disability-modal-mobile-finish__content__after-open',
          beforeClose: 'user-disability-modal-mobile-finish__content__before-close'
        }}
      >
        <div className='user-disability-modal-mobile-finish__content-wrapper'>
          <h3 className='user-disability-modal-mobile-finish__header'>
            {_t('thank_you')}
          </h3>
          <span className='user-disability-modal-mobile-finish__text'>
            {_t('dis_profile_member')}
          </span>
          <button 
            className='user-disability-modal-mobile-finish__button'
            onClick={this.postUserDisabilityProfile}
          >
            {_t('finish')}
          </button>
        </div>
      </Modal>
    )
  }
  renderStart(){
    const _t = this.props.t
    return(
      <div className='user-disability-modal__start-wrapper'>
        <div className='user-disability-modal__start'>
          <img className='user-disability-modal__start-logo'
            src={require('../../images/disability-profile/handiscover_logo.png')} 
            alt="Handiscover logo"
          />
          <h2 className='user-disability-modal__start-header'>{_t('dis_profile_thanks_for_joining')}</h2>
          <p className='user-disability-modal__start-text'>
            {_t('dis_profile_please_answer')}
          </p>
          <button 
            className='user-disability-modal__button user-disability-modal__button_start'
            onClick={this.showTabs}
          >start
          </button>
        </div>
      </div>
    )
  }
  renderFinish(){
    const _t = this.props.t
    return(
      <div className='user-disability-modal__start-wrapper'>
        <div className='user-disability-modal__start'>
          <img className='user-disability-modal__start-logo'
            src={require('../../images/disability-profile/handiscover_logo.png')} 
            alt="Handiscover logo"
          />
          <h2 className='user-disability-modal__start-header'>{_t('thank_you')}</h2>
          <p className='user-disability-modal__start-text user-disability-modal__finish-text'>
            {_t('dis_profile_member')}
            {_t('dis_profile_do_not_forget')}
          </p>
          <button 
            className='user-disability-modal__button user-disability-modal__button_start'
            onClick={this.postUserDisabilityProfile}
          >
            {_t('finish')}
          </button>
        </div>
      </div>
    )
  }
  getTabClassName(index){
    const {question} = this.state
    let ddd = question - index
    let className = 'user-disability-modal__tab'
    if(ddd >= 0){
      className = 'user-disability-modal__tab user-disability-modal__tab_active'
    }
    return className
  }
  renderFormHeader(){
    let tabs = []
    let translateQuestion = this.props.t('question')
    for(let i = 1; i < 6; i++){
      let tab = (
        <li
          key={i} 
          className={this.getTabClassName(i)}>
          <span className='user-disability-modal__tab-text'>
            {`${translateQuestion} ${i}`}
          </span>
        </li>
      )
      tabs.push(tab)
    }
    return (
      <ul className='user-disability-modal__tabs'>
        {tabs}
      </ul>
    )
  }
  renderFormHeaderMobile(){
    return(
      <div className='user-disability-modal__form-header-mobile'>
        <h2 className='user-disability-modal__form-header-mobile-header'>Thanks for joining us!</h2>
        <p className='user-disability-modal__form-header-mobile-text'>
         Please answer this questions so we can help you find the perfect stay
        </p>
        <div className='user-disability-modal__form-header-mobile-counter'>
          {`Question ${this.state.question} out of 5`}
        </div>
      </div>
    )
  }
  renderInnerContent(){
    return(
      <React.Fragment>
        <div className='user-disability-modal__inner-content'>
          {this.state.deviceSize == 'lg'? this.renderFormHeader(): this.renderFormHeaderMobile()}
          {this.renderQuestions()}
        </div>
        {this.renderButtons()}
      </React.Fragment>
    )
  }
  renderQuestions(){
    const {
      question,
      residence,
      destinations,
      destinationField,
      questionWhoValue,
      disabilities,
      mobileEquipment,
      mobility,
      allergies,
      allergyExtraInfo,
      mobilityEquipmentExtraInfo
    } = this.state
    return(
      <div className='user-disability-modal__questions-wrapper'>
        <UserDisabilityQuestions
          t={this.props.t}
          mobilityEquipmentExtraInfo={mobilityEquipmentExtraInfo}
          allergyExtraInfo={allergyExtraInfo}
          mobileEquipment={mobileEquipment}
          question={question}
          residence={residence}
          destinationField={destinationField}
          destinations={destinations}
          disabilities={disabilities}
          questionWhoValue={questionWhoValue}
          mobility={mobility}
          allergies={allergies}
          onAddCustomAllergy={this.onAddCustomAllergy}
          handleAllergyCustomInput={this.handleAllergyCustomInput}
          onMobilityChange={this.onMobilityChange}
          onDisabilitiesChange={this.onDisabilitiesChange}
          onResidenceChange={this.onResidenceChange}
          onDestinationsChange={this.onDestinationsChange}
          onDestinationFieldChange={this.onDestinationFieldChange}
          onQuestionWhoChange={this.onQuestionWhoChange}
          onQuestionAgeChange={this.onQuestionAgeChange}
          onMobileEquipmentChange={this.onMobileEquipmentChange}
          onAllergiesChange={this.onAllergiesChange}
          onAddEquipmentExtraInfo={this.onAddEquipmentExtraInfo}
        />
      </div>
    )
  }
  renderContent(){
    if(this.state.start && this.state.deviceSize === 'lg'){
      return this.renderStart()
    } else if(this.state.finish && this.state.deviceSize === 'lg'){
      return this.renderFinish()
    }
    return this.renderInnerContent()
  }
  render(){
    return(
      <Modal
        isOpen={this.state.modal}
        contentLabel='Modal'
        onRequestClose={this.props.closeUserDisabilityForm}
        overlayClassName={{
          base: 'user-disability-modal',
          afterOpen: 'user-disability-modal__after-open',
          beforeClose: 'user-disability-modal__before-close'
        }}
        className={{
          base: 'user-disability-modal__content',
          afterOpen: 'user-disability-modal__content__after-open',
          beforeClose: 'user-disability-modal__content__before-close'
        }}
      >
        <div className='user-disability-modal__content-wrapper'>
          {this.renderContent()}
          {this.renderMobileFinish()}
        </div>
      </Modal>
    )
    
  }
}
export default UserDisabilityForm