import React from 'react'
import axios from 'axios'
import utils from '../lib/utils'

class PropertiesAutocomplete extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      value: this.props.value,
      search: false
    }
    this.closeList = this.closeList.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.getPutUrl= this.getPutUrl.bind(this)
    this.fetchHotels = this.fetchHotels.bind(this)
    this.renderHotels =this.renderHotels.bind(this)
  }

  closeList(){
    this.setState({search: false})
  }

  componentDidMount(){
    if (!this.props.smallScreen === true){
      document.addEventListener('mousedown', this.handleClick, false)
    } else {
      return
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick(e){
    if (e.target.id !== 'hotelsListWrapper' && e.target.className !== 'properties_autocomplete'){
      this.setState({search: false})
    }
  }

  componentDidUpdate(prevProps){
    if (this.props.value !== prevProps.value && this.props.value !== this.state.propertyName) {
      this.fetchHotels()
    }
  }

  getPutUrl(put, startDate, endDate, nrGuests) {
    let queryObj = {}

    if (startDate && endDate) {
      queryObj.start_date = utils.getDateString(startDate)
      queryObj.end_date = utils.getDateString(endDate)
    }
    if(nrGuests) {
      queryObj.max_nr_guests_min = 1 //this.state.nrGuests
      queryObj.max_nr_guests_max = 10
      queryObj.nr_guests = nrGuests
    }
    let queryString = Object.keys(queryObj).map(key => key + '=' + queryObj[key]).join('&')
    if(queryString) { queryString = '?'+queryString }
    const urlPath = put.url
    if (urlPath) {
      return `${HOST_URL}/${this.props.locale}/${urlPath}${queryString}`
    } else {
      return `${HOST_URL}/${this.props.locale}/properties/${put.property}/puts/${put.id}${queryString}`
    }
  }

  renderHotels(){
    const hotels = this.state.hotels
    let hotelsList = null
    if(hotels){
      hotelsList = hotels.map((hotel) => {
        let url = this.getPutUrl(hotel)
        if(this.props.startDate || this.props.endDate){
          url += '?'
        }
        if(this.props.startDate){
          url += `&start_date=${utils.getDateString(this.props.startDate)}`
        }
        if(this.props.endDate){
          url += `&end_date=${utils.getDateString(this.props.endDate)}`
        }
        return(
          <a 
            onClick={this.closeList}
            href={url}
            className={'properties_autocomplete'}
            key={hotel.id}
          >
            {hotel.name}
          </a>
        )
      })
    }
    return (
      <div id='hotelsListWrapper'>
        {hotelsList.length > 0?<div className={'search-category-title'}>{'Properties'}</div>: null}
        {hotelsList}
        {/* <span className={'properties-list-tooltip'}>{'......more results, keep typing to refine search'}
        </span> */}
      </div>
    )
  }

  fetchHotels(){
    let searchValue = this.props.value
    if(searchValue && searchValue.length > 2){
      axios.get(`${API_URL}/properties/search/?limit=4&search=${this.props.value}`)
        .then(res => {
          this.setState({
            hotels: res.data.results,
            search: true
          })
        })
    }
    return
  }

  render(){
    return(
      <div>
        {this.state.search && this.props.value && this.props.value.length > 2? this.renderHotels(): null}
      </div>
    )
  }
}

export default PropertiesAutocomplete