import React from 'react'
import PropTypes from 'prop-types'
import { is } from 'immutable';

class Spinner extends React.PureComponent {
  render(){
    if (!this.props.visible) {
      return null
    }
    let spinnerClasses = 'spinner-overlay'
    if (this.props.isInline) {
      spinnerClasses += ' is-inline'
    }

    return (
      <div className={spinnerClasses}>
        <div className='spinner'>
          <div className='preloader-wrapper small active'>
            <div className='spinner-layer'>
              <div className='circle-clipper spinner-left'>
                <div className='circle'></div>
              </div>
              <div className='gap-patch'>
                <div className='circle'></div>
              </div>
              <div className='circle-clipper spinner-right'>
                <div className='circle'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Spinner.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  isInline: PropTypes.bool
}

export default Spinner
