import {featureCollection, point} from '@turf/helpers'

/**
 * Parses Address Component into a single layer Object
 */
function parseAddressComponents(components) {
  const results = {}
  components.map(component => {
    results[component.types[0]] = component
  })
  return results
}

/**
 * Converts GoogleResult Bounds to BBox
 */
function parseBBox(result) {
  if (result.geometry) {
    if (result.geometry.viewport) {
      const viewport = result.geometry.viewport
      return [
        viewport.getSouthWest().lng(),
        viewport.getSouthWest().lat(),
        viewport.getNorthEast().lng(),
        viewport.getNorthEast().lat()]
    }
  }
}

/**
 * Converts GoogleResult to GeoJSON Point
 */
function parsePoint(result) {
  if (result.geometry) {
    if (result.geometry.location) {
      const {lng, lat} = result.geometry.location
      return point([lng(), lat()])
    }
  }
}

/**
 * Convert Google results into GeoJSON
 */
function toGeoJson(results) {
  const collection = featureCollection([])
  results.map(result => {
    // Get Geometries
    const point = parsePoint(result)
    const bbox = parseBBox(result)

    // Calculate Confidence score
    const location_type = result.geometry.location_type

    // GeoJSON Point properties
    const properties = {
      location_type,
      formatted_address: result.formatted_address,
      place_id: result.place_id,
      types: result.types,
    }

    // Google Specific Properties
    const components = parseAddressComponents(result.address_components)
    Object.keys(components).forEach(key => properties[key] = components[key])

    // Store Point to GeoJSON feature collection
    if (point) {
      point.bbox = bbox
      point.properties = properties
      collection.features.push(point)
    }
  })
  return collection
}

export {toGeoJson, parseBBox}
