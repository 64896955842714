import React from 'react'
import InputField from '../components/input-field.jsx'
import FormValidator from './form-validator.js'
import clamp from '../vendor/clamp.js'

function generate(inputElements, val, bText, hideButton, disableButton, parent) {
  return class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        validator: val,
        submitDisabled: disableButton
      }
      this.parent = parent
      this.requests = 0;
    }

    getValue(name) {
      return this.state.validator[name].value
    }

    setSubmitDisabled(valu) {
      this.setState({ submitDisabled: valu })
    }

    onSubmit() {
      this.state.validator.setEnabled(true)
      if (this.state.validator.validate(true)) {
        if (this.parent.requests < 3) {
          this.props.onSubmit(this.state.validator)
        }
        else {
          const confirmation = window.confirm("Prevented excessive request. Press OK to continue.");
          if (confirmation) {
            this.parent.requests = 0;
          }
        }
        this.parent.requests++;
      }
      else {
        if (this.props.onSubmitFailed) {
          this.props.onSubmitFailed()
        }
        this.forceUpdate()
      }
    }

    onChange(field, e) {
      if (this.props.onChange) {
        this.props.onChange(field, e)
      }
    }

    render() {
      const inputFields = inputElements.map(field => {
        const func = e => {
          this.state.validator[field.name].update(e)
          this.state.validator.validate(false)
          this.forceUpdate()
          this.onChange(field, e)
        }
        if (field.type === 'field') {
          return (
            <InputField
              key={field.name}
              autoFocus={field.autoFocus}
              className={field.name}
              onChange={func.bind(this)}
              onEnter={this.onSubmit.bind(this)}
              password={field.isPassword}
              preText={field.preText}
              placeholder={field.placeholder}
              infoBubbleText={field.helpText}
              errorBubbleText={field.errorBubbleText}
              showError={this.state.validator[field.name].shouldHighlight}
              value={this.state.validator[field.name].value}
            />
          )
        }
        else if (field.type === 'select') {
          var opts = field.options.map(opt => {
            return <option key={opt} value={opt}>{opt}</option>
          })
          return (
            <div key={field.name} className={'select-wrapper ' + field.customWrapperClass}>
              <select>
                {opts}
              </select>
            </div>
          )
        }
      })
      var errMsg = this.state.validator.getErrorMessage()
      if (this.props.extErrMsg) {
        if (errMsg) {
          errMsg = this.props.extErrMsg + ", " + errMsg
        }
        else {
          errMsg = this.props.extErrMsg
        }
      }

      var btn = null
      if (!hideButton) {
        btn = <button disabled={this.state.submitDisabled} className='action-button' onClick={this.onSubmit.bind(this)}>{bText}</button>
      }

      return (
        <div className='form-wrapper'>
          <div className='input-wrapper'>
            {inputFields}
            <div className='err-msg' ref={e => { if (e) { clamp(e, { clamp: 2 }) } }}>{errMsg}</div>
          </div>
          {btn}
        </div>
      )
    }

  }
}

class FormGenerator {
  constructor(buttonText, hideButton = false, disableButton = false) {
    this.hideButton = hideButton
    this.bText = buttonText
    this.disableButton = disableButton
    this.val = new FormValidator(false)
    this.inputElements = []
    this.requests = 0
  }

  setButtonText(text) {
    this.bText = text
  }

  addField(args) {
    this.inputElements.push({
      name: args.name,
      placeholder: args.placeholder,
      autoFocus: args.autoFocus,
      helpText: args.infoBubbleText,
      isPassword: args.isPassword,
      preText: args.preText,
      errorBubbleText: args.errorBubbleText,
      type: 'field'
    })
    this.val.addField(args.name, args.defaultValue || '')
  }

  addSelector(args) {
    this.inputElements.push({
      name: args.name,
      placeholder: args.placeholder,
      options: args.options,
      customWrapperClass: args.customWrapperClass,
      type: 'select'
    })
    this.val.addField(args.name, null)
  }

  addValidator() {
    this.val.addValidator.apply(this.val, arguments)
  }

  generate() {
    return generate(this.inputElements, this.val, this.bText, this.hideButton, this.disableButton, this)
  }
}

export default FormGenerator
