import React from 'react'
import { List } from 'immutable'

const defaultOptions = new List()

class SelectDropdown extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hasEmptyOption: false,
      previousValue: 0,
      selectedValue: 0
    }

    this.onChange = this.onChange.bind(this)
  }

  componentWillMount() {
    this.setState({ hasEmptyOption: this.props.keepEmptyOption || this.props.value === undefined })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ hasEmptyOption: this.props.keepEmptyOption || nextProps.value === undefined })
  }

  onChange(e, selectNr) {
    const { previousValue } = this.state
    var newVal = e.target.value
    this.setState({ 
      hasEmptyOption: this.props.keepEmptyOption || !newVal, previousValue: newVal,
      selectedValue: Number(newVal),
    },
      () => {
        if (this.props.onChange) {
          this.props.onChange(newVal, previousValue)
        }
      })
  }

  render() {
    let opts = null
    let optionData = this.props.options || defaultOptions

    if (this.state.hasEmptyOption) {
      opts = this.optsWithEmpty
      optionData = [{ value: '', label: this.props.placeholder || '' }, ...optionData]
    }

    opts = optionData.map(opt => {
      return (
        <option
          key={opt.key || opt.value}
          value={opt.value}
          label={this.props.t ? this.props.t(opt.label) : opt.label}
          disabled={opt.disabled ? true : null}>
          {this.props.t ? this.props.t(opt.label) : opt.label}
        </option>
      )
    })

    let className = 'select-dropdown'
    if (this.props.showError) {
      className += ' err'
    }
    if (this.props.wrapperClassName) {
      className += ` ${this.props.wrapperClassName}`
    }

    const disabled = ((this.props.disabled || (this.props?.maxLimit)) && 
       ((this.props.qvalue !== undefined && this.props.qvalue === 0) || this.state.selectedValue === 0) ? true : false)
    return (
      <div
        className={className + `${this.props.footerLabel ?
          this.props.footerLabel === 'currency' ?
            " currency-dropdown-wrapper" : " language-dropdown-wrapper" : ''}`}>
        {this.props.footerLabel ?
          <label htmlFor={this.props.footerLabel === 'currency' ?
            "currency-dropdown" : "language-dropdown"}>
            {this.props.footerLabel === 'currency' ? "Select currency:" : "Select language:"}
          </label> : ''}
        <select
          id={this.props.footerLabel ?
            this.props.footerLabel === 'currency' ?
              "currency-dropdown" : "language-dropdown" : ''}
          className={this.props.className + `${disabled !== undefined && disabled > 0 ? ' disabled' : ''}`}
          onChange={e => this.onChange(e, this.props.selectNr)}
          value={this.props.qvalue !== undefined ? this.props.qvalue : this.state.selectedValue}
          disabled={disabled !== undefined ? disabled : null}>
          {opts}
        </select>
      </div>
    )
  }
}

export default SelectDropdown
