export function appendFormData(data: FormData, name: string, file: File) {
    data.append(name,file)
}

export function addQueryParameter(url: URL, name: string, value: null | undefined | string | number | boolean | number[] | string[] | boolean[]) {
    if(value == null) return;
    if(Array.isArray(value)) {
        for (let o of value) {
            url.searchParams.append(name, o?.toString());
        }
    }else {
        url.searchParams.append(name, value.toString());
    }
}

export async function delay(ms: number) {
    return new Promise<void>((r) => setTimeout(r, ms));
}


export enum SignInType {
    Regular,
    Facebook,
    Google
}

export enum EvaluationStatus {
    STARTED,
    FINALIZED
}

export enum AssessmentStatus {
    STARTED,
    SUCCESS,
    FAIL
}




export function isFetchError(o: unknown): o is { status: number, statusText: string, json: unknown } {
    return !!(typeof o === "object" && o && 'status' in o && 'statusText' in o && 'json' in o);
}

export async function fetchAndParse<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    const response = await fetch(input, init)
    if (response.status !== 200) {
        // eslint-disable-next-line no-throw-literal
        throw {
            status: response.status,
            statusText: response.statusText,
            response,
            json: await response.json(),
            toString() {
                return `${this.status} - ${this.json.message || this.statusText}`;
            }
        };
    }
    const data = await response.json();
    return data as T;
}
export const tablePages = {
    userPage: {
        page: 0,
        pageSize: 20,
    },
    assessmentPage: {
        page: 0,
        pageSize: 20,
    },
    equipmentPage: {
        page: 0,
        pageSize: 20,
    },
    jointsPage: {
        page: 0,
        pageSize: 20,
    },
    singleExercisePage: {
        page: 0,
        pageSize: 20,
    },
    exerciseTypePage: {
        page: 0,
        pageSize: 20,
    },
    routinesPage: {
        page: 0,
        pageSize: 20,
    },
    routineTypesPage: {
        page: 0,
        pageSize: 20,
    },
}

type roomType = {
  room: number,
  nr_adults: number,
  children: Array<{ child: number, age: number }>
}

type childrenDataObj = { child: number, age: number }

type groupFitType = Array<{
  nr_adults: number,
  children: number[],
}>

export const convertDataToRoomQuery = (roomsArr: roomType[]) => {
  let query = "";  
  roomsArr.forEach((room: roomType) => {
    const r = `&room__${room.room}__`;
    const a = `nr_adults=${room.nr_adults}`
    query += (r + a)
    if (room.children.length > 0) {
      let c = "children=";
      room.children.forEach((child) => {
        c += `${child.age},`
      })
      const rmLastComma = c.slice(0, -1);
      query += (r + rmLastComma)
    }
  })
  return query;
}

export const convertRoomQueryToGuestPickerData = (queryParams: any) => {  
  let pickerData: roomType[] = [];
  const noQueryFound = (
    Object.keys(queryParams).length === 0 ||
    !queryParams.hasOwnProperty("room__1__nr_adults")
  )
  if (noQueryFound) {
    return undefined
  }
  let obj = queryParams;
  for (const key in obj) {
    if (obj.hasOwnProperty.call(obj, key)) {
      if (key.includes("room__")) {
        const paramVal = obj[key];
        const roomNr = parseInt(key.slice(6, 7));
        const roomFound = pickerData[roomNr - 1];
        if (!roomFound) {
          pickerData.push({
            room: roomNr,
            nr_adults: 0,
            children: []
          })
        }        
        if (pickerData[roomNr - 1] && key.includes("adults")) {
          pickerData[roomNr - 1].nr_adults = parseInt(paramVal);
        } else if (pickerData[roomNr - 1] && key.includes("children")) {          
          pickerData[roomNr - 1].children.push(
            ...paramVal.split(",").map((childAge: string, idx: number) => ({
              child: idx + 1,
              age: parseInt(childAge)
            })         
          ))
        }
      }
    }
  }
  return pickerData;
}

export const convertDataToGroupFit = (roomsArr: roomType[]) => {
  let groupFitArr: groupFitType = [];  
  roomsArr.forEach((room: roomType) => {    
    groupFitArr.push({
      nr_adults: room.nr_adults,
      children: (room.children.length > 0) ? room.children.map((child: childrenDataObj) => child.age) : []
    })
  })  
  return groupFitArr;
}

export const convertQueryParamsToRoomPath = (queryParams: any) => {
  let roomsPath = "&";
  let roomsPathSliced;
  let obj = queryParams;
  for (const key in obj) {
    if (obj.hasOwnProperty.call(obj, key)) {
      if (key.includes("room__")) {
        const paramVal = obj[key];
        roomsPath += `${key}=${paramVal}&`;
      }
    }
  }
  return roomsPathSliced = roomsPath.slice(0, -1)
}

export const convertRoomQueryToData = (queryParams: any) => {
  let roomsPath = "&";
  let roomsPathSliced;
  let obj = queryParams;
  for (const key in obj) {
    if (obj.hasOwnProperty.call(obj, key)) {
      if (key.includes("room__") && key.includes("adults")) {
        const paramVal = obj[key];
        roomsPath += `${key}=${paramVal}&`;
      } else if (key.includes("room__") && key.includes("children")) {
        let childsParamVal = obj[key];
        const childsArr = childsParamVal.split(",");
        childsArr.forEach((child: string) => {
          roomsPath += `${key}=${child}&`;
        })
      }
    }
  }
  return roomsPathSliced = roomsPath.slice(0, -1)
}

export const convertRoomQueryToGroupFit = (queryParams: any) => {
  const noQueryFound = (
    Object.keys(queryParams).length === 0 ||
    !queryParams.hasOwnProperty("room__1__nr_adults") 
  )
  if (noQueryFound) {
    return undefined
  }
  let groupFitArr: groupFitType = [];
  let obj = queryParams;
  for (const key in obj) {
    if (obj.hasOwnProperty.call(obj, key)) {
      if (key.includes("room__")) {
        const paramVal = obj[key];
        const roomNr = parseInt(key.slice(6, 7));
        const roomFound = groupFitArr[roomNr - 1];
        if (!roomFound) {
          groupFitArr.push({
            nr_adults: 0,
            children: []
          })
        }        
        if (groupFitArr[roomNr - 1] && key.includes("adults")) {          
          groupFitArr[roomNr - 1].nr_adults = parseInt(paramVal);
        } else if (groupFitArr[roomNr - 1] && key.includes("children")) {
          groupFitArr[roomNr - 1].children.push(...paramVal.split(",").map((childAge: string) => parseInt(childAge)))
        }
      }
    }
  }
  return groupFitArr;
}