import dateFormat from 'dateformat'
import moment from 'moment'
import axios from 'axios'
import {
  putFacilities,
  propertyFacilities,
  propertyTypeMap,
  propertyTypeMapSearch,
  defaultModalStyle,
  MIN_PASSWORD_LENGTH,
  propertyImageMandatoryOptions,
  propertyImageOptionalOptions,
  putImageMandatoryOptions,
  putImageOptionalOptions,
  mrpHotelImageMandatoryOptions,
  mrpHotelImageOptionalOptions,
  mrpRoomImageMandatoryOptions,
  mrpRoomImageOptionalOptions,
  cancellationPolicyMap,
  supportedLanguages
} from './constants.js'

const getIp = () => {
  return axios.get('https://jsonip.com').then(res => res.data.ip).then(res => res)
}

function getDictKeys(d) {
  var keys = []
  for (var prop in d) {
    if (d.hasOwnProperty(prop)) {
      keys.push(prop)
    }
  }
  return keys
}

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  var mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

function getSeasonToUse(seasons, startDate, endDate) {
  if (!startDate) {
    startDate = moment()
    endDate = moment().add(1, 'days')
  }
  if (!endDate) {
    endDate = startDate.clone()
    endDate.add(1, 'days')
  }
  const defaultSeason = seasons.find(s => s.is_default)
  let fallbackSeason = false;
  const currentSeason = seasons.find(s => {
    if (s.is_default) {
      return false
    }
    if(!fallbackSeason) {
      fallbackSeason = s
    }
    const start = moment(s.start_date)
    const end = moment(s.end_date)
    return start.isSameOrBefore(endDate) && end.isSameOrAfter(startDate)
  })
  return currentSeason || defaultSeason || fallbackSeason;
}

function round(rate, nrDecimals=0) {
  return Number(rate).toFixed(nrDecimals)
}

function getPriceInCurrency(
  rate,
  fromCurrency,
  toCurrency,
  fx,
  nrDecimals = 0
) {
  let price = null
  if (rate !== undefined) {
    let p = 0
    if (fx) {
      try {
        p = fx(rate)
          .from(fromCurrency.toUpperCase())
          .to(toCurrency.toUpperCase())
      } catch (e) {}
    }
    price = round(p, nrDecimals)
  } else {
    price = 0
  }
  return price
}

function getNrDays(startDate, endDate) {
  if (!startDate || !endDate) {
    return -1
  }
  return endDate.startOf('day').diff(startDate.startOf('day'), 'days')
}

function calculateComplexPrice(seasons, startDate, endDate) {
  const nrNights = getNrDays(startDate, endDate)
  if (nrNights < 1) return
  // get intervals in seasons
  let daysCalculated = 0
  let intervals = []
  seasons.forEach(s => {
    // get time window in this non-default season for booking
    const start =
      moment(s.start_date) > startDate ? moment(s.start_date) : startDate
    let end =
      moment(s.end_date) < endDate ? moment(s.end_date).add(1, 'days') : endDate
    // if start > end - this season not in time window
    if (start < end && s.start_date && s.end_date && !s.is_default) {
      const daysInSeason = getNrDays(start, end)
      intervals.push({
        rate: s.rate,
        daysInSeason,
        start,
        end
      })
      daysCalculated += daysInSeason
    }
  })
  // if days calculated == nrNights - we don't need additional calculation
  const defaultSeason = seasons.find(s => s.is_default)
  if (daysCalculated !== nrNights && intervals.length) {
    intervals.sort((a, b) => {
      return a.start > b.start
    })
    const addIfLower = (lowerDate, equalDate) => {
      if (equalDate > lowerDate) {
        intervals.push({
          rate: defaultSeason.rate,
          daysInSeason: getNrDays(lowerDate, equalDate),
          start: lowerDate,
          end: equalDate
        })
      }
    }
    // check after added intervals
    addIfLower(intervals[intervals.length - 1].end, endDate)
    // check before added intervals
    addIfLower(startDate, intervals[0].start)
    intervals.sort((a, b) => {
      return a.start > b.start
    })
    // check inside intervals
    intervals.slice(0).forEach((elem, ind) => {
      if (intervals[ind + 1] !== undefined) {
        addIfLower(elem.end, intervals[ind + 1].start)
      }
    })
  }
  // get overall price
  let price = 0
  intervals.forEach(i => {
    price += i.daysInSeason * i.rate
  })

  return price
}

function calculateComplexRate(seasons, startDate, endDate) {
  const nrNights = getNrDays(startDate, endDate)
  if (nrNights < 1) return
  const price = calculateComplexPrice(seasons, startDate, endDate)
  let fallbackSeason = false;
    // get rate
  if (price === 0) {
    const defaultSeason = seasons.find(s => s.is_default)
    if(typeof(defaultSeason) === 'undefined') {
      fallbackSeason = getSeasonToUse(seasons, startDate, endDate)
      return fallbackSeason.rate
    }
    else {
      return defaultSeason.rate
    }
  }
  return price / nrNights
}

function getBookingStatus(booking, bookingUnits) {
  let status = ''
  if (booking.type === 'enquiry') {
    if (booking.status === 'open') {
      // hmm can we see this from booking unit? would be better
      status = 'owner_approval'
    } else {
      // status closed
      let bookingUnit = bookingUnits[0]
      status = bookingUnit.status.trim()
    }
  } else {
    // Direct
    let bookingUnit = bookingUnits[0]
    status = bookingUnit.status.trim()
  }

  return status.trim()
}

function hasTrailingSlash(path) {
  return path.slice(-1) === '/'
}

function getLocaleLang(locale) {
  if (!locale) {
    return null
  }
  let lang = locale
  if (locale.includes('-')) {
    // Support for locale. Just assumes the first few characters include
    // a language code
    lang = locale.split('-')[0]
  }
  return lang
}

function getChangeoverString(arr, _t) {
  arr = arr.filter(day => {
    return day ? true : false
  })
  let lastDay = arr.pop()
  if (arr.length) {
    return arr.join(', ') + ' ' + _t('general_and') + ' ' + lastDay
  } else {
    return lastDay
  }
}

function isEmptyString(str) {
  return !str || str.length <= 0
}

const isIdenticalRatePack = (rPack1, rPack2) => {
  const props = ['property_unit_type', 'season']
  if (props.some(prop => rPack1[prop] !== rPack2[prop])) {
    return false
  }
  if (rPack1.included_services.length != rPack2.included_services.length) {
    return false
  }
  if (rPack1.included_services.toLowerCase().search('breakfast') !== rPack2.included_services.toLowerCase().search('breakfast')) {
    return false
  }
  if (Math.abs(rPack1.rate - rPack2.rate) > 0.01) {
    return false
  }
  return true
}

module.exports = {
  round: round,
  getIp: () => getIp(),
  filterOutIdenticalRatePacks: (ratePacks) => {
    const result = []
    ratePacks.forEach((ratePack) => {
      const index = result.findIndex((elem) => {
        return isIdenticalRatePack(elem, ratePack)
      })
      if (index === -1) {
        result.push(ratePack)
      } else {
        if (ratePack.nr_guests > result[index].nr_guests) {
          result.splice(index, 1, ratePack)
        }
      }
    })
    return result
  },
  filterDuplicateObjects: (objArray, fieldNames) => {
    const result = []
    objArray.forEach(item => {
      if (!result.some(elem => fieldNames.every(field => item[field] === elem[field]))) {
        result.push(item)
      }
    })

    return result
  },
  getRate: (put, putAvailability, date, use_default_currency=false, fx=null) => {
    // NOTE: Always rate in USD from exchange_rate
    let rate = put.exchange_rate
    if (use_default_currency && fx) {
      try {
        rate = fx(rate)
          .from('USD')
          .to(put.property_obj.default_currency.toUpperCase())
      } catch (e) {}
    }

    if (putAvailability && putAvailability.get(put.id) && date) {
      let availability = putAvailability
        .get(put.id)
        .find(a => moment(a.date).isSame(date, 'day'))
      if (availability) {
        rate = use_default_currency ? availability.rate : availability.exchange_rate
      }
    }
    return rate
  },
  getNrAvailableUnits: (put, startDate, endDate, availability) => {
    if (!put || !availability || !startDate || !endDate) {
      throw 'Programming error'
    }

    const avDates = availability.filter(a => {
      return moment(a.date).isSameOrAfter(startDate) && moment(a.date).isSameOrBefore(endDate)
    })

    // Figure out the least number of available units over all availabilities
    if (avDates) {
      let high = 0
      for(let i = 0; i<avDates.length; i++){
        let avail = avDates[i]
        let c = avail.nr_units_booked_by_cm + avail.nr_units_booked_by_owner + avail.nr_units_booked_by_system
        high = Math.max(high, c)
      }
      return put.nr_units - high
    }

    return 0
  },
  isSmallDevice: deviceSize => deviceSize === 'xs' || deviceSize === 'sm',
  sortByNumeric: (arr, prop, desc = false) => {
    arr.sort((a, b) => {
      if (desc) {
        return parseFloat(b[prop]) - parseFloat(a[prop])
      }
      return parseFloat(a[prop]) - parseFloat(b[prop])
    })
  },
  getAccessibilityIconInfo: (accessibilityItem, _t) => {
    const it = accessibilityItem
    const key = it.accessibility_type
    const val = it.value_int === null ? it.value_string : it.value_int
    let label = ''
    let sublabel = ''
    let image = null
    let order = 80
    switch (key) {
      case 'parking':
        order = 8
        label = _t('list_property_images_parking')
        switch (val) {
          case 'private':
            sublabel = _t('amenities_private_parking')
            image = require('../images/object-page/accessibility/Parking.png')

            break
          case 'private_accessible':
            sublabel = _t('list_property_parking_private_accessible')
            image = require('../images/object-page/accessibility/Accessible-Parking.png')
            // image = require('../../images/object-page/accessibility/Accessible-Parking.png')
            break
          case 'accessible_in_area':
            sublabel = _t('list_property_parking_accessible_in_area')
            image = require('../images/object-page/accessibility/Accessible-Parking-in-area.png')
            // image = require('../../images/object-page/accessibility/Accessible-Parking-in-area.png')
            break
        }
        break
      case 'parking_distance':
        label = _t('list_property_images_parking')
        sublabel = _t('single_property_parking_distance') + ' ' + val + 'm'
        image = require('../images/object-page/accessibility/Parking.png')
        break
      case 'outside_steps':
        label = _t('single_property_access_outside')
        order = 16
        if (val === 'max_15') {
          sublabel = _t('property_access_outside_stairs_max_15')
          image = require('../images/object-page/accessibility/max-15-steps.png')
        } else if (val === 'max_1') {
          sublabel = _t('property_access_outside_stairs_max_1')
          image = require('../images/object-page/accessibility/one-step.png')
        } else {
          sublabel = _t('property_access_outside_stairs_lift_or_ramp')
          image = require('../images/object-page/accessibility/ramp.png')
        }
        break
      case 'inside_steps':
        label = _t('single_property_inside_steps')
        order = 24
        if (val === 'max_15') {
          sublabel = _t('property_access_inside_stairs_max_15')
          image = require('../images/object-page/accessibility/max-15-steps.png')
        } else if (val === 'max_1') {
          sublabel = _t('property_access_inside_stairs_max_1')
          image = require('../images/object-page/accessibility/one-step.png')
        } else {
          sublabel = _t('property_access_inside_stairs_lift_or_ramp')
          image = require('../images/object-page/accessibility/ramp.png')
        }
        break
      case 'inside_lift_width':
        label = _t('single_property_lift_width')
        sublabel = val + 'cm'
        image = require('../images/object-page/accessibility/elevator.png')
        break
      case 'inside_lift_height':
        label = _t('single_property_lift_height')
        sublabel = val + 'cm'
        image = require('../images/object-page/accessibility/elevator.png')
        break
      case 'inside_braille_in_lift':
        label = _t('single_property_lift')
        sublabel = _t('list_property_lift_braille')
        image = require('../images/object-page/accessibility/elevator.png')
        break
      case 'kitchen':
        label = _t('amenities_kitchen')
        switch (val) {
          case 'regular':
            sublabel = _t('list_property_kitchen_regular')
            // image = require('../../images/object-page/accessibility/blank.png')
            break
          case 'adjustable':
            sublabel = _t('list_property_kitchen_adjustable')
            image = require('../images/object-page/accessibility/adjustable-kitchen.png')
            break
          default:
            break
        }
        break
      case 'bathroom':
        label = _t('general_bathroom')
        order = 40
        switch (val) {
          case 'regular':
            sublabel = _t('list_property_put_bathroom_regular')
            // image = require('../../images/object-page/accessibility/blank.png')
            break
          case 'wide_with_bath':
            sublabel = _t('list_property_put_bathroom_wide_with_bath')
            image = require('../images/object-page/accessibility/bath-tube.png')
            break
          case 'roll_in_shower_with_chair':
            sublabel = _t(
              'list_property_put_bathroom_roll_in_shower_with_chair'
            )
            image = require('../images/object-page/accessibility/Roll-in-shower.png')
            break
          default:
            break
        }
        break
      case 'toilet':
        label = _t('list_property_images_toilet')
        order = 48
        switch (val) {
          case 'regular':
            sublabel = _t('list_property_put_toilet_regular')
            // image = require('../../images/object-page/accessibility/blank.png')
            break
          case 'with_support':
            sublabel = _t('list_property_toilet_with_support')
            image = require('../images/object-page/accessibility/toilet-with-grab-rail.png')
            break
          case 'with_grab_rails':
            sublabel = _t('list_property_toilet_with_grab_rails')
            image = require('../images/object-page/accessibility/toilet-with-two-grab-rail.png')
            break
          default:
            break
        }
        break
      case 'toilet_comment':
        label = _t('list_property_images_toilet')
        order = 49
        sublabel = val
        image = require('../images/object-page/accessibility/toilet-with-grab-rail.png')
        break
      case 'shower_chair_fixed':
        label = _t('amenities_shower')
        order = 40
        sublabel = 'Fixed shower chair'
        // image = require('../../images/object-page/accessibility/blank.png')
        break
      case 'shower_chair_wheels':
        label = _t('amenities_shower')
        order = 40
        sublabel = 'Shower chair with wheels'
        image = require('../images/object-page/accessibility/Shower-Chair-with-wheels.png')
        break
      case 'bathroom_with_turning_circle':
        label = _t('general_bathroom')
        sublabel = _t('list_property_bathroom_with_turning_circle')
        image = require('../images/object-page/accessibility/bathroom-with-1.png')
        break
      case 'bathroom_additional_info':
        label = _t('general_bathroom')
        order = 32
        sublabel = val
        image = require('../images/object-page/accessibility/bath-tube.png')
        break
      case 'bed':
        label = _t('general_bed_singular')
        order = 32
        switch (val) {
          case 'regular':
            sublabel = _t('list_property_bed_regular')
            // image = require('../../images/object-page/accessibility/blank.png')
            break
          case 'regular_with_space_for_hoist':
            sublabel = _t('list_property_bed_regular_with_space_for_hoist')
            image = require('../images/object-page/accessibility/ceiling-hoist-for-bed.png')
            break
          case 'adjustable':
            sublabel = _t('list_property_put_bed_adjustable')
            image = require('../images/object-page/accessibility/ceiling-hoist-for-bed.png')
            break
          default:
            break
        }
        break
      case 'number_of_adjustable_beds':
        if(val) {
          label = _t('single_property_adjustable_beds_label')
          sublabel = _t('single_property_adjustable_beds_sublabel') + ' ' + val
          order = 72
          image = require('../images/object-page/accessibility/ceiling-hoist-for-bed.png')
        }
        break
      case 'adjustable_bed_extra_info':
        label = _t('single_property_adjustable_beds_label')
        order = 74
        sublabel = val
        image = require('../images/object-page/accessibility/ceiling-hoist-for-bed.png')
        break
      case 'minimum_door_width':
        label = _t('single_property_door_width')
        order = 76
        switch (val) {
          case 'lt_75_cm':
            sublabel = _t('list_property_put_door_lt_75_cm')
            image = require('../images/object-page/accessibility/door.png')
            break
          case 'lt_90_cm':
            sublabel = _t('list_property_door_lt_90_cm')
            image = require('../images/object-page/accessibility/door.png')
            break
          case 'gte_90_cm':
            sublabel = _t('list_property_door_gte_90_cm')
            image = require('../images/object-page/accessibility/door.png')
            break
          default:
            break
        }
        break
      case 'concierge':
        order = 64
        label = _t('list_property_concierge')
        sublabel = _t('general_yes')
        break
      case 'mobile_hoist':
        label = _t('single_property_hoist')
        sublabel = _t('list_property_hoists_mobile')
        image = require('../images/object-page/accessibility/mobile-hoist.png')
        break
      case 'pool_hoist':
        label = _t('single_property_hoist')
        sublabel = _t('list_property_hoists_pool')
        image = require('../images/object-page/accessibility/pool-hoist.png')
        break
      case 'bed_ceiling_hoist':
        label = _t('general_bed_singular')
        sublabel = _t('list_property_hoists_bed')
        image = require('../images/object-page/accessibility/ceiling-hoist-for-bed.png')
        break
      case 'wheelchair_hire':
        label = _t('general_wheelchair_singular')
        sublabel = _t('list_property_rentals_wheelchair')
        image = require('../images/object-page/accessibility/wheelchair-hire.png')
        break
      case 'mobility_scooter_hire':
        label = _t('general_scooter_singular')
        sublabel = _t('list_property_rentals_mobility_scooter')
        image = require('../images/object-page/accessibility/scooter-hire.png')
        break
      case 'accessible_car_hire':
        label = _t('general_car_singular')
        sublabel = _t('list_property_rentals_accessible_car')
        image = require('../images/object-page/accessibility/car-hire.png')
        break
      case 'hearing_aid_assistance':
        label = _t('single_property_assistance')
        sublabel = _t('list_property_assistances_hearing')
        image = require('../images/object-page/accessibility/Hearing-Aid.png')
        break
      case 'visual_aid_assistance':
        label = _t('single_property_assistance')
        sublabel = _t('list_property_assistances_visual')
        image = require('../images/object-page/accessibility/Visual-Aid.png')
        break
      case 'allergy_certificate_name':
        label = _t('single_property_allergy_cert')
        sublabel = val
        // image = require('../../images/object-page/accessibility/blank.png')
        break
      case 'allergy_certificate_fur':
        label = _t('single_property_allergy_cert')
        sublabel = _t('single_property_allergy_cert_fur')
        // image = require('../../images/object-page/accessibility/blank.png')
        break
      case 'allergy_certificate_dust':
        label = _t('single_property_allergy_cert')
        sublabel = _t('single_property_allergy_cert_dust')
        // image = require('../../images/object-page/accessibility/blank.png')
        break
      case 'allergy_certificate_asthma':
        label = _t('single_property_allergy_cert')
        sublabel = _t('single_property_allergy_cert_asthma')
        // image = require('../../images/object-page/accessibility/blank.png')
        break
      case 'allergy_certificate_mold':
        label = _t('single_property_allergy_cert')
        sublabel = _t('single_property_allergy_cert_mold')
        // image = require('../../images/object-page/accessibility/blank.png')
        break
      case 'allergy_certificate_food':
        label: _t('single_property_allergy_cert')
        sublabel: val
        // image = require('../../images/object-page/accessibility/blank.png')
        break
      default:
        break
    }
    return { label, sublabel, image, order }
  },
  getChangeoverInfo: (season, translator) => {
    const _t = translator
    let cid = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    let cod = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    let cin = season.check_in_never_on
    let con = season.check_out_never_on
    let cl = 0
    let ciall = true
    if (cin.length) {
      cl = cin.length
      for (let i = 0; i < cl; i++) {
        if (cid.includes(cin[i])) {
          ciall = false
          cid[cid.indexOf(cin[i])] = false
        }
      }
    }
    let coall = true
    if (con.length) {
      cl = con.length
      for (let i = 0; i < cl; i++) {
        if (cod.includes(con[i])) {
          coall = false
          cod[cod.indexOf(con[i])] = false
        }
      }
    }
    if (ciall && coall) {
      return _t('general_everyday')
    } else {
      let citext = ciall
        ? _t('general_everyday').toLowerCase()
        : getChangeoverString(cid, _t)
      let cotext = coall
        ? _t('general_everyday').toLowerCase()
        : getChangeoverString(cod, _t)
      return (
        _t('season_changeover_checkin_on') +
        ' ' +
        citext +
        ', ' +
        _t('season_changeover_checkout_on') +
        ' ' +
        cotext
      )
    }
  },
  first: array => {
    // Taken from https://stackoverflow.com/questions/4090491/first-element-in-array-jquery
    for (let i in array) return array[i]
  },
  isOverlappingDateRanges: (startDate0, endDate0, startDate1, endDate1) => {
    // Assume all moment objects
    if (!(startDate0 && endDate0 && startDate1 && endDate1)) {
      return false
    }

    return (
      startDate0.isSameOrBefore(endDate1) && startDate1.isSameOrBefore(endDate0)
    )
  },
  isNumeric: n => {
    return !isNaN(parseFloat(n)) && isFinite(n)
  },
  hasAllMandatoryImages: (property, put, ignoreList) => {
    // Note: should probably add option to ignore certain image types
    // or add put accessibility as a param (in case for instance kitchen is
    // not present and we should ignore the kitchen image type in particular)
    if (typeof ignoreList === 'undefined') {
      ignoreList = []
    }
    let propertyImages = null
    if (property) {
      propertyImages = property.images
      if (!propertyImages || propertyImages.length === 0) {
        return false
      }
    } else {
      return false
    }

    let putImages = null
    if (put) {
      putImages = put.images
      if (!putImages || putImages.length === 0) {
        return false
      }
    } else {
      return false
    }

    let types = propertyImages
      .map(p => p.type)
      .concat(putImages.map(p => p.type))
    for (let i = 0; i < propertyImageMandatoryOptions.length; i++) {
      let option = propertyImageMandatoryOptions[i]
      if (!types.includes(option.value) && !ignoreList.includes(option.value)) {
        return false
      }
    }
    for (let i = 0; i < putImageMandatoryOptions.length; i++) {
      let option = putImageMandatoryOptions[i]
      if (!types.includes(option.value) && !ignoreList.includes(option.value)) {
        return false
      }
    }

    return true
  },
  getLocaleLang: getLocaleLang,
  hasTrailingSlash: hasTrailingSlash,
  forceTrailingSlash: path => {
    if (!hasTrailingSlash(path)) {
      path += '/'
    }
    return path
  },
  getSupportedLanguages: () => supportedLanguages,
  decodeHtml: html => {
    if (!html) {
      return ''
    }
    let txt = document.createElement('textarea')
    txt.innerHTML = html
    return txt.value || html
  },
  isLanguageSupported: langCode => {
    if (!langCode) {
      return false
    }
    let lang = getLocaleLang(langCode)
    return supportedLanguages.find(l => l.code === lang)
  },
  sortOnField: (arr, desc = false, fieldName='created_date') => {
    const compareFunc = (a, b) => {
      let mul = desc ? 1 : -1
      if (moment(a[fieldName]).isAfter(moment(b[fieldName]))) {
        return -1 * mul
      } else {
        return 1 * mul
      }
    }
    arr.sort(compareFunc)
  },
  sortOnCreated: (arr, desc = false) => {
    const compareFunc = (a, b) => {
      let mul = desc ? 1 : -1
      if (moment(a.created_date).isAfter(moment(b.created_date))) {
        return -1 * mul
      } else {
        return 1 * mul
      }
    }
    arr.sort(compareFunc)
  },
  sortOnDate: (arr, prop, desc = false) => {
    const compareFunc = (a, b) => {
      let mul = desc ? 1 : -1
      if (moment(a[prop]).isAfter(moment(b[prop]))) {
        return -1 * mul
      } else {
        return 1 * mul
      }
    }
    arr.sort(compareFunc)
  },
  isValidBooking: booking => {
    return (
      booking &&
      booking.booking_units &&
      booking.booking_units.length > 0 &&
      getBookingStatus(booking, booking.booking_units)
    )
  },
  getPutName: put => {
    if (!put || (!put.name && typeof put.property_obj === 'undefined')) {
      return null
    }
    return put.name || put.property_obj.name
  },
  getPutDescription: put => {
    let ret = '';
    if(put.description) {
      ret = put.description;
    }
    else if(typeof(put.property_obj) !== 'undefined') {
      ret = put.property_obj.description;
    }
    return ret;
  },
  getAllImages: put => {
    if (!put) {
      return []
    }
    return put.images.concat(put.property_obj.images)
  },
  round: round,
  getPriceInCurrency: getPriceInCurrency,
  getPriceString: (price, fromCurrency, toCurrency, fx, nrDecimals = 0) => {
    let priceString = ''
    if (price !== undefined) {
      const p = getPriceInCurrency(
        price,
        fromCurrency,
        toCurrency,
        fx,
        nrDecimals
      )
      priceString = `${p} ${toCurrency}`
    } else {
      priceString = ''
    }
    return priceString
  },
  getBookingStatus: getBookingStatus,
  getOwnerBookingStatusString: bookingStatus => {
    switch (bookingStatus) {
      case 'owner_approval':
        return 'booking_owner_approval'
      case 'payment_required':
        return 'booking_payment_required'
      case 'rejected':
        return 'general_declined'
      case 'canceled':
        return 'booking_status_cancelled'
      case 'expired':
        return 'booking_status_expired'
      case 'finished':
        return 'booking_status_accepted'
      case 'required_second_part_of_payment':
        return 'booking_status_accepted'
      default:
        return ''
    }
  },
  getCustomerBookingStatusString: bookingStatus => {
    switch (bookingStatus) {
      case 'owner_approval':
        return 'booking_status_owner_approval'
      case 'payment_required':
        return 'booking_status_payment_required'
      case 'rejected':
        return 'general_declined'
      case 'canceled':
        return 'booking_status_cancelled'
      case 'expired':
        return 'booking_status_expired'
      case 'finished':
        return 'booking_status_accepted'
      case 'cancelling':
        return 'booking_status_cancelling'
      case 'required_second_part_of_payment':
        return 'booking_status_accepted'
      default:
        return ''
    }
  },
  getAvatarUrl: user => {
    let url = ''
    if(user && user.avatar) {
      url = user.avatar
    }
    return url.trim()
  },
  getImageUrl: (image,size) => {
    if (!image) {
      return '';
    }
    let url = '';
    let urls = []
    if (image.type === 'file') {
      url = image.image;
    } else {
      if(typeof(size) === 'undefined') {
        size = 'small';
      }
      switch(size) {
        case 'small':
          urls = [image.thumbnail_small, image.thumbnail_medium, image.thumbnail_large, image.url];
          break;
        case 'medium':
          urls = [image.thumbnail_medium, image.thumbnail_small, image.thumbnail_large, image.url];
          break;
        case 'large':
          urls = [image.thumbnail_large, image.thumbnail_medium, image.thumbnail_small, image.url];
          break;
        case 'full':
          urls = [image.url, image.thumbnail_large, image.thumbnail_medium, image.thumbnail_small];
          break;
        default:
          url = image.url;
      }
      var i = 0, solved = false, img;
      if(typeof(window.urlMap) === 'undefined') {
        window.urlMap = {}
      }
      while(!solved && i < urls.length) {
        if(urls[i]) {
          if(typeof(window.urlMap[urls[i]]) === 'number') {
            if(window.urlMap[urls[i]] === 200) {
              solved = true;
              url = urls[i];
            }
            else if(window.urlMap[urls[i]] === 404){
              solved = false;
            }
          }
          else {
            window.urlMap[urls[i]] = 99;
            img = new Image();
            img.onload = function(l) {
              window.urlMap[l.target.currentSrc] = 200;
            }
            img.onerror = function(l) {
              window.urlMap[l.target.currentSrc] = 404;
            }
            img.src = urls[i];
            if(img.height !== 0) {
              solved = true;
              url = urls[i];
            }
          }
        }
        i += 1;
      }
    }
    return url;
  },
  getImageUrlPromisified: (image, size = 'medium', defaultUrl = '') => {
    const loadImage = url => () => {
      return new Promise((res, rej) => {
        const img = new Image()
        img.onload = function () {
          return rej({image, url, width: this.width, height: this.height})
        }
        img.onerror = () => {
          return res({image, url: defaultUrl, width: null, height: null})
        }
        img.src = url
      })
    }

    return new Promise((resolve) => {
      if (!image) {
        return resolve({image, url: defaultUrl, width: null, height: null})
      }

      if (image.file_type === 'file' && size === undefined) {
        return resolve({ image, url: image.image, width: null, height: null })
      }

      let urls = []
      switch (size) {
        case 'small':
          urls = [image.thumbnail_small, image.thumbnail_medium, image.thumbnail_large, image.url]
          break;
        case 'medium':
          urls = [image.thumbnail_medium, image.thumbnail_small, image.thumbnail_large, image.url]
          break;
        case 'large':
          urls = [image.thumbnail_large, image.thumbnail_medium, image.thumbnail_small, image.url]
          break;
        case 'full':
          urls = [image.url, image.thumbnail_large, image.thumbnail_medium, image.thumbnail_small]
          break;
        default:
          url = image.url
      }

      urls.map(url => loadImage(url)).reduce((prom, func) => prom.then(func), Promise.resolve())
        .then(() => resolve({ image, url: defaultUrl, width: null, height: null }))
        .catch(img => resolve({ image, url: img.url, width: img.width, height: img.height }))
    })
  },
  getCoverImage: (put, accessibilityOnly = false) => {
    let image = put.images.find(img => img.type === 'cover')
    if (!image && !accessibilityOnly && typeof put.property_obj !== 'undefined') {
      image = put.property_obj.images.find(img => img.type === 'cover')
    }
    if (!image) {
      if (put.images.length > 0) {
        if (put.images[0].image !== null && put.images[0].image !== undefined) {
          image = put.images[0] 
        }
      } else if (put.property_obj && !accessibilityOnly && put.property_obj.images.length > 0) {
        image = put.property_obj.images[0]
      }
    }
    return image
  },
  getAccessibilityImages: (put) => {
    const { images } = put
    const imgs = [...images.filter(img => img.type === 'cover'), ...images.filter(img => img.type !== 'cover')]
    return imgs
  },
  cancellationPolicyMap: cancellationPolicyMap,
  getNrDays,
  getSeasonToUse,
  calculateComplexRate,
  calculateRate: (put, seasons, startDate, endDate) => {
    // We'll assume the seasons are for the put
    if (!put || !seasons || seasons.length === 0) {
      return 0
    }

    if (!startDate) {
      startDate = moment()
      endDate = moment().add(1, 'days')
    }

    let season = getSeasonToUse(seasons, startDate, endDate)
    if (season) {
      return calculateComplexRate(seasons, startDate, endDate)
    } else {
      // Shouldn't be possible to book this...
      return 0
    }
  },
  getBlobFromImageUrl: url => {
    return new Promise(resolve => {
      var img = new Image()

      img.setAttribute('crossOrigin', 'anonymous')

      var load = () => {
        var canvas = document.createElement('canvas')
        var scaling = 1
        var maxSize = 333000
        if(img.width * img.height > maxSize) {
          scaling = Math.sqrt(maxSize / img.width / img.height)
        }
        canvas.width = Math.floor(img.width * scaling)
        canvas.height = Math.floor(img.height * scaling)
        var ctx = canvas.getContext('2d')
        if(scaling < 1) {
          ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
        }
        else {
          ctx.drawImage(img, 0, 0)
        }

        var dataUri = canvas.toDataURL('image/png')
        var blob = dataURItoBlob(dataUri)
        resolve(blob)
      }

      img.onload = load.bind(img)

      img.src = url
    })
  },
  getImageTypeMap: () => {
    var typeMap = {}
    propertyImageMandatoryOptions.forEach(p => {
      typeMap[p.value] = p.label
    })
    propertyImageOptionalOptions.forEach(p => {
      typeMap[p.value] = p.label
    })
    putImageMandatoryOptions.forEach(p => {
      typeMap[p.value] = p.label
    })
    putImageOptionalOptions.forEach(p => {
      typeMap[p.value] = p.label
    })
    mrpHotelImageMandatoryOptions.forEach(p => {
      typeMap[p.value] = p.label
    })
    mrpHotelImageOptionalOptions.forEach(p => {
      typeMap[p.value] = p.label
    })
    mrpRoomImageMandatoryOptions.forEach(p => {
      typeMap[p.value] = p.label
    })
    mrpRoomImageOptionalOptions.forEach(p => {
      typeMap[p.value] = p.label
    })

    return typeMap
  },
  propertyImageMandatoryOptions: propertyImageMandatoryOptions,
  propertyImageOptionalOptions: propertyImageOptionalOptions,
  putImageMandatoryOptions: putImageMandatoryOptions,
  putImageOptionalOptions: putImageOptionalOptions,
  mrpHotelImageMandatoryOptions: mrpHotelImageMandatoryOptions,
  mrpHotelImageOptionalOptions: mrpHotelImageOptionalOptions,
  mrpRoomImageMandatoryOptions: mrpRoomImageMandatoryOptions,
  mrpRoomImageOptionalOptions: mrpRoomImageOptionalOptions,
  propertyFacilities: propertyFacilities,
  putFacilities: putFacilities,
  addSpaceAtIntervals: (str, interval) => {
    var nrChunks = Math.ceil(str.length / interval)
    var chunks = []
    for (var i = 0; i < nrChunks; i++) {
      chunks.push(str.substring(i * interval, (i + 1) * interval))
    }
    return chunks.join(' ')
  },

  isMultiUnitSrp: type => {
    var typeDict = propertyTypeMap.find(t => t.value === type)
    if (typeDict) {
      return typeDict.type === 'multiSrp'
    }
    return false
  },

  isMrp: type => {
    var typeDict = propertyTypeMap.find(t => t.value === type)
    if (typeDict) {
      return typeDict.type === 'mrp'
    }
    return false
  },

  isSrp: type => {
    var typeDict = propertyTypeMap.find(t => t.value === type)
    if (typeDict) {
      return typeDict.type === 'srp'
    }
    return false
  },

  propertyTypeMap: propertyTypeMap,
  propertyTypeMapSearch: propertyTypeMapSearch,

  // TODO: update. Use object.assign
  deepCopy: d => JSON.parse(JSON.stringify(d)),

  toIsoShortDateString: d => dateFormat(d, 'yyyy-mm-dd'),

  getDefaultModalStyle: () => {
    return defaultModalStyle
  },

  anyKeys: d => {
    return getDictKeys(d).length > 0
  },

  getDictKeys: d => {
    return getDictKeys(d)
  },

  isValidCardNumber: cardNumber => {
    const regexp = /^\d{16}$/
    //TODO: Luhn-10 verification..
    return cardNumber.match(regexp)
  },

  isValidCreditCardMonth: month => {
    const regexp = /^\d{2}$/
    if (month.match(regexp)) {
      const m = parseInt(month)
      return m > 0 && m < 13
    }
    return false
  },

  isValidCreditCardYear: year => {
    const regexp = /^\d{2}$/
    if (year.match(regexp)) {
      const y = parseInt(year)
      return y >= 0 && y < 100
    }
    return false
  },

  isValidCvc: cvc => {
    const regexp = /^\d{3}$/
    return cvc.match(regexp)
  },

  isValidEmail: email => {
    const regex = /^[\w-]+.*@[\w-]+\.\w+.*$/
    return email.match(regex)
  },

  isEmptyString: isEmptyString,

  isNotEmptyString: str => !isEmptyString(str),

  isValidPassword: pw => pw.length >= MIN_PASSWORD_LENGTH,

  isEqualStrings: (str1, str2) => {
    if (!str1 || !str2) {
      return false
    }
    return str1.localeCompare(str2) === 0
  },
//
  MIN_PASSWORD_LENGTH: MIN_PASSWORD_LENGTH,

  nrToMonthString: nr => {
    switch (parseInt(nr)) {
      case 0:
        return 'JANUARY'
      case 1:
        return 'FEBRUARY'
      case 2:
        return 'MARCH'
      case 3:
        return 'APRIL'
      case 4:
        return 'MAY'
      case 5:
        return 'JUNE'
      case 6:
        return 'JULY'
      case 7:
        return 'AUGUST'
      case 8:
        return 'SEPTEMBER'
      case 9:
        return 'OCTOBER'
      case 10:
        return 'NOVEMBER'
      case 11:
        return 'DECEMBER'
      default:
        return 'Invalid month'
    }
  },

  monthInfos: [
    {
      name: 'January',
      index: 1
    },
    {
      name: 'February',
      index: 2
    },
    {
      name: 'March',
      index: 3
    },
    {
      name: 'April',
      index: 4
    },
    {
      name: 'May',
      index: 5
    },
    {
      name: 'June',
      index: 6
    },
    {
      name: 'July',
      index: 7
    },
    {
      name: 'August',
      index: 8
    },
    {
      name: 'September',
      index: 9
    },
    {
      name: 'October',
      index: 10
    },
    {
      name: 'November',
      index: 11
    },
    {
      name: 'December',
      index: 12
    }
  ],

  range: (start, end, interval) => {
    if (interval === undefined) {
      interval = 1
    }
    var l = []
    for (var i = start; interval > 0 ? i <= end : i >= end; i += interval) {
      l.push(i)
    }
    return l
  },

  // Disabled this prop because error is already displayed by doing an api request
  // passwordDescription:
  //   'Your password must be at least ' +
  //   MIN_PASSWORD_LENGTH +
  //   ' characters long',

  removeWhitespace: str => str.replace(/\s+/g, ''),

  scrollToY: (el, scrollTargetY, speed, easing) => {
    // http://stackoverflow.com/questions/8917921/cross-browser-javascript-not-jquery-scroll-to-top-animation

    // first add raf shim
    // http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
    const requestAnimFrame = (() => {
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        (callback => {
          window.setTimeout(callback, 1000 / 60)
        })
      )
    })()

    // scrollTargetY: the target scrollY property of the window
    // speed: time in pixels per second
    // easing: easing equation to use
    const scrollY = el.scrollTop
    scrollTargetY = scrollTargetY || 0
    speed = speed || 2000
    easing = easing || 'easeOutSine'
    var currentTime = 0

    // min time .1, max time .8 seconds
    const time = Math.max(
      0.1,
      Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8)
    )
    // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
    const easingEquations = {
      easeOutSine: pos => Math.sin(pos * (Math.PI / 2)),
      easeInOutSine: pos => -0.5 * (Math.cos(Math.PI * pos) - 1),
      easeInOutQuint: pos => {
        if ((pos /= 0.5) < 1) {
          return 0.5 * Math.pow(pos, 5)
        }
        return 0.5 * (Math.pow(pos - 2, 5) + 2)
      }
    }

    // add animation loop
    const tick = () => {
      currentTime += 1 / 60

      const p = currentTime / time
      const t = easingEquations[easing](p)

      if (p < 1) {
        requestAnimFrame(tick)
        el.scrollTop = scrollY + (scrollTargetY - scrollY) * t
      } else {
        el.scrollTop = scrollTargetY
      }
    }

    // call it once to get started
    tick()
  },

  copyToClipboard: text => {
    // http://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a flash,
    // so some of these are just precautions. However in IE the element
    // is visible whilst the popup box asking the user for permission for
    // the web page to copy to the clipboard.
    //

    var textArea = document.createElement('textarea')

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed'
    textArea.style.top = 0
    textArea.style.left = 0

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em'
    textArea.style.height = '2em'

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0

    // Clean up any borders.
    textArea.style.border = 'none'
    textArea.style.outline = 'none'
    textArea.style.boxShadow = 'none'

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent'

    var textKey = 'textContent' in document.body ? 'textContent' : 'innerText'
    textArea.value = document.querySelector('.perk-modal .code-area h2')[
      textKey
    ]

    document.body.appendChild(textArea)

    textArea.select()

    var successful = false
    try {
      successful = document.execCommand('copy')
    } catch (err) {}

    document.body.removeChild(textArea)

    return successful
  },

  getDateString: moment => {
    if (!moment) {
      return ''
    }
    return moment.startOf('day').format('YYYY-MM-DD')
  },

  getMonthString: moment => moment.format('YYYY-MM'),

  capitalizeFirstLetter: string => {
    if(string && string.length > 1) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    }
    return ''
  },
  isDefined: val => {
    return (val !== undefined) && (val !== null)
  }
}
