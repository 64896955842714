import { makeRequest } from '../components/container.jsx'

export const requestContentApi = (reqInFlightKey, method, endpoint, params, headers, authenticate=true, preventRedirect=false, ip) => {
  const apiUrl = CONTENT_API_URL
  return makeRequest(reqInFlightKey, method, apiUrl, endpoint, params, headers, authenticate, preventRedirect, ip)
}

export const requestHdApi = (reqInFlightKey, method, endpoint, params, headers, authenticate=true, preventRedirect=false, ip, accFilter=false) => {
  //console.log(method, endpoint, params);
  const apiUrl = API_URL
  return makeRequest(reqInFlightKey, method, apiUrl, endpoint, params, headers, authenticate, preventRedirect, ip, accFilter)
}