// utils file

// function to detect operating system for mobile devices
export function getMobileOperatingSystem() {
    const navigatorAgent = navigator.userAgent
    if (navigatorAgent.indexOf("iPhone") > -1 || navigatorAgent.indexOf("iPod") > -1 || navigatorAgent.indexOf("iPad") > -1) {
        return "iOS"
    } else if (/Android/.test(navigatorAgent)) {
        return "Android"
    } else if (/Windows Phone/.test(navigatorAgent)) {
        return "Windows Phone"
    }
    return ''
}

export const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
        element!.scrollIntoView({
            behavior: "smooth",
        });
    }
}

