
let data: Array<{ value: unknown, init: () => unknown }> = []
export function makeGlobalState<T>(init: () => T) {
    const slot = data.length
    data.push({
        value: init(),
        init
    })
    return () => data[slot].value;
}
export function clearGlobals() {
    data.forEach(v => v.value = v.init());
}