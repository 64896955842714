import React, { useContext, useMemo, useRef, useState } from "react";


export const ServerSideRenderingData = React.createContext<{
    isServerSide: false,
    wasRendered: boolean,
    getData<T>(ssrDataFactory: () => Promise<T>): { wasLoaded: false } | { wasLoaded: true, data: T }
} | {
    isServerSide: true,
    getData<T>(ssrDataFactory: () => Promise<T>): { wasLoaded: false } | { wasLoaded: true, data: T }
}>({
    isServerSide: false,
    wasRendered: true,
    getData() {
        return { wasLoaded: false }
    }
})

declare global {
    interface Window {
        hdSSRState?: {
            modules: Record<string, true>;
            states: Array<{ data: unknown, wasLoaded: boolean}>;
            containerState: unknown
        }
    }
}

export function HydratedSSRStateProvider(p: React.PropsWithChildren<{}>) {
    const service = useRef<React.ContextType<typeof ServerSideRenderingData>>();

    if (!service.current) {
        let index = -1
        const dataSlots = window.hdSSRState ?? {states:[]};
        service.current = {
            isServerSide: false,
            wasRendered: window.hdSSRState != null,
            getData<T>(ssrDataFactory: () => Promise<T>) {
                index++;
                if (dataSlots?.states.length > index) {
                    const slot = dataSlots.states[index];
                    return { data: slot.data as T, wasLoaded: slot.wasLoaded };
                }
                return { wasLoaded: false }
            }
        }
    }
    return <ServerSideRenderingData.Provider value={service.current!}>
        {p.children}
    </ServerSideRenderingData.Provider>
}

export function useSSRState<T>(defaultValue: T, ssrDataFactory: () => Promise<T>) {
    const ssr = useContext(ServerSideRenderingData);
    let hasServerSideValue = false

    const ssrData = ssr.getData(ssrDataFactory);
    if (ssrData.wasLoaded) {
        defaultValue = ssrData.data;
        hasServerSideValue = true
    }
    const state = useState(defaultValue);
    if (ssr.isServerSide) {
        return [...state, false] as const;
    } else {
        return [...state, hasServerSideValue] as const;
    }
}
