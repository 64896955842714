import { useState, useEffect } from "react";
import { useSSRState } from "./ssr-hooks";

export function usePagedData<T>(loadData: (p: { skip: number, limit: number }) => Promise<T[]>, perPage: number, deps: any[]) {

    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData, hasServerData] = useSSRState<Array<T>>([], () => loadData({ skip: 0, limit: perPage }));
    const [error, setError] = useState(false);

    useEffectAsync(async () => {
        if (!hasServerData) loadMoreData(true)
    }, deps);
    async function loadMoreData(clear?: boolean) {
        setLoading(true);
        setError(false);
        if (clear) {
            setHasMore(true);
            setData([]);
        }
        try {
            const prevData = clear ? [] : data;
            const newData = await loadData({ skip: prevData.length, limit: perPage });
            if (newData.length < perPage) {
                setHasMore(false);
            }
            setData(data => [...data, ...newData]);
        }
        catch (e) {
            setError(true);
            setHasMore(false);
            setLoading(false);
        }
        finally {
            setLoading(false);
        }
    }
    return {
        loading,
        hasMore,
        data,
        error,
        loadMoreData,
        setData
    }
}

export function useData<T>(loadData: () => Promise<T[]>, deps: any[] = []) {

    const [data, setData, hasData] = useSSRState([], loadData);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffectAsync(async () => {
        if (!hasData) load();
    }, deps)

    async function load() {
        setLoading(true);
        try {
            const newData = await loadData();
            setData(data => [...data, ...newData])
        }
        catch (e) {
            setError(true);
            setLoading(false);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        data,
        loading,
        error,
        setData
    }
}

export function useDataUnique<T>(loadData: () => Promise<T[]>, deps: any[] = []) {

    const [data, setData, hasData] = useSSRState([], loadData);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffectAsync(async () => {
        if (!hasData) load();
    }, deps)

    async function load() {
        setLoading(true);
        try {
            const newData = await loadData();
            setData(newData)
        }
        catch (e) {
            setError(true);
            setLoading(false);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        data,
        loading,
        error,
        setData
    }
}

export function useEffectAsync(fn: () => Promise<void>, deps: any[]) {
    useEffect(() => {
        fn()
    }, deps);
}