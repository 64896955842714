// window.onerror = function (message, url, lineNo, colNo, error) {
//
//    console.log(arguments);
//
//    let container = document.createElement('div');
//
//    container.style.color = 'red';
//    container.style.position = 'fixed';
//    container.style.background = '#eee';
//    container.style.padding = '2em';
//    container.style.top = '1em';
//    container.style.left = '1em';
//
//    let msg = document.createElement('pre');
//    msg.innerText = [
//       'Message: ' + message,
//       'URL: ' + url,
//       'Line: ' + lineNo,
//       'Column: ' + colNo,
//       'Stack: ' + (error && error.stack)
//    ].join('\n');
//
//    container.appendChild(msg);
//
//    document.body.appendChild(container);
// };

import React from 'react'
import ReactDOM from 'react-dom'
import App, { routerConf } from './components/app'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'slick-carousel/slick/slick.css'
import 'react-dates/lib/css/_datepicker.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './css/app.css'
import 'react-dates/initialize'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18n from './lib/i18n.js'
import { collectModules } from './components/routing-helpers/custom-routes'
import { fastLoadCache } from './components/routing-helpers/custom-routes'
import { addRehydrateListener, rehydrate } from './components/container'
import { getFullState } from './components/container'
import  { HydratedSSRStateProvider } from './hooks/ssr-hooks'

(async function() {
    if(window.hdSSRState) {
        console.log(window.hdSSRState);
        const lang = location.pathname.split('/')[1];
        const rehydrateTask = new Promise((r) =>{
            rehydrate(lang);
            addRehydrateListener(r);
        });
        await rehydrateTask;
        await Promise.all([rehydrateTask, ...collectModules(routerConf)]
            .filter(p => window.hdSSRState?.modules[p.path])
            .map(async p => {
                console.log("Loaded:" + p.path)
                const module = await p.getComponent()
                fastLoadCache[p.path] = () => module;
            })
        );
    }
    ReactDOM.hydrate((
        <HydratedSSRStateProvider>
            <BrowserRouter>
                <I18nextProvider i18n={i18n}>
                    <App />
                </I18nextProvider>
            </BrowserRouter>
        </HydratedSSRStateProvider>
    ), document.getElementById('content'))
})();