import React from "react"
import axios from "axios"
import { useState, useEffect } from "react"

const iosLink = 'https://apps.apple.com/us/app/handiscover/id1560980147'

export function MobileRedirectionBanner() {
    const [isOpen, setIsOpen] = useState<boolean>(JSON.parse(sessionStorage.getItem('mobileRedirectioNBanner') ?? 'true'))
    const [applicationRating, setApplicationRating] = useState('')
    useEffect(() => {
        sessionStorage.setItem('mobileRedirectioNBanner', JSON.stringify(isOpen))
    }, [isOpen])
    useEffect(() => {
        (async () => {
            try {
                const fetchAppleDescriptionFile = (await axios.get('https://itunes.apple.com/lookup?id=1560980147')).data
                const extractRating = fetchAppleDescriptionFile.results[0].averageUserRating
                setApplicationRating(extractRating)
            }
            catch (e) {
                console.log(e)
            }
        })()
    }, [])
    return isOpen ? <div className="mobile-redirect-banner-root">
        <div className="banner-wrapper">
            <div className="close-button-wrapper">
                <button onClick={() => setIsOpen(false)}>X</button>
            </div>
            <div className="message-rating-wrapper">
                <img src={require('../images/favicons/apple-touch-icon.png')} alt="handiscover-logo" />
                <div>
                    <p >Handiscover</p>
                    <p >Disabled-Friendly Guides</p>
                    <div><img src={require('../images/misc/app-rating.png')} alt="application-rating" /><span>{applicationRating}</span></div>
                </div>
                <a href={iosLink} target="_blank">Get app</a>
            </div>
        </div>
    </div> : null
}