import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import Spinner from './spinner.jsx'

const NO_OVERFLOW_CLASS = 'no-overflow'

class SpinnerModal extends React.Component {
  constructor(props) {
    super(props)

    this.onAfterOpen = this.onAfterOpen.bind(this)
    this.onRequestClose = this.onRequestClose.bind(this)
  }

  componentWillReceiveProps(newProps) {
    if (this.props.isOpen && !newProps.isOpen) {
      document.documentElement.classList.remove(NO_OVERFLOW_CLASS)
    }
  }

  componentWillUnmount() {
    document.documentElement.classList.remove(NO_OVERFLOW_CLASS)
  }

  onAfterOpen() {
    document.documentElement.classList.add(NO_OVERFLOW_CLASS)
  }

  onRequestClose() {
    document.documentElement.classList.remove(NO_OVERFLOW_CLASS)
    this.props.onRequestClose()
  }

  render() {
    return (
      <Modal
        contentLabel="Modal"
        onAfterOpen={this.onAfterOpen}
        isOpen={this.props.isOpen}
        onRequestClose={this.onRequestClose}
        style={this.props.style}
      >
        <Spinner visible={this.props.isLoading} />
        {this.props.children}
      </Modal>
    )
  }

  renderSpinnerOverlay(visible) {
    if (!visible) {
      return null
    }
    return (
      <div className="spinner-overlay">
        <div className="spinner">
          <div className="preloader-wrapper small active">
            <div className="spinner-layer">
              <div className="circle-clipper left">
                <div className="circle" />
              </div>
              <div className="gap-patch">
                <div className="circle" />
              </div>
              <div className="circle-clipper right">
                <div className="circle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SpinnerModal.propTypes = {
  style: PropTypes.object,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool
}

export default SpinnerModal
