import React, { useState, useEffect } from 'react'
import { useHistory, useTranslation, useParameters, delay } from '../../../utils';
import { getPostsHeaders, getCategories, getAll } from '../../../services/api';
import { useEffectAsync } from '../../../hooks/async-hooks';
import { BlogPostHeader } from '../../../models/BlogPostHeader';
import { NamedObject } from '../../../models/NamedObject';
import { blogUrl, blogCategoryUrl } from '../utils';
import { AllPostHeader } from '../../../models/AllPostHeader';
import { Link } from 'react-router-dom';

export const AUTOSEARCH_DELAY = 100;
export function SearchBar() {
    const lang = "en";// hardocded language for now, move this to the desturecture below when content is ready.
    const { } = useParameters<{
        lang?: string,
    }>();
    const history = useHistory();
    const { t } = useTranslation();

    const [filteredArticles, setFilteredArticles] = useState<Array<AllPostHeader>>([]);
    const [filteredCategories, setFilteredCategories] = useState<Array<NamedObject>>([]);
    const [showSugestions, setShowSugestions] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [abortionController, setAbortionController] = useState<AbortController>(new AbortController());
    const routeParams = useParameters<{
        searchType?: string,
        searchText?: string,
        lang: string,
    }>()

    // useEffect(() => {
    //     setAbortionController(a => {
    //         a.abort();
    //         return new AbortController();
    //     });
    // }, [searchParam.length <= 3 ? null : searchParam]);

    // useEffectAsync(async () => {
    //     if(searchParam.length <= 3) return;
    //     await delay(AUTOSEARCH_DELAY);
    //     if(abortionController.signal.aborted) return;
        
    //     const result = await getAll({
    //         search: searchParam,
    //         orderby: "relevance",
    //         per_page: 5,
    //         lang
    //     }, abortionController.signal)
    //     if(abortionController.signal.aborted) return;
    //     setFilteredArticles(result);
    //     setShowSugestions(true);
    // }, [abortionController]);

    // useEffectAsync(async () => {
    //     if(searchParam.length <= 3) return;
        
    //     await delay(AUTOSEARCH_DELAY);
    //     if(abortionController.signal.aborted) return;
    //     const result = await getCategories({
    //         search: searchParam,
    //         per_page: 3,
    //         lang
    //     }, abortionController.signal)
    //     if(abortionController.signal.aborted) return;
    //     setFilteredCategories(result);
    //     setShowSugestions(true);
    // }, [abortionController]);


    function keyPress(e: number) {
        if (e === 13) {
            history.push(`/${routeParams.lang}/content/results/all/text/${encodeURIComponent(searchParam)}`);
            setShowSugestions(false);
        }
    }
    return <div>
        <div className="search-box">
            <input type="text" autoFocus placeholder={t("search-bar-text")} style={{ padding: 0, margin: 0 }}
                value={searchParam}
                onChange={(event) => { setSearchParam(event.target.value) }}
                onKeyUp={(e) => {
                    keyPress(e.keyCode);
                }} />
            {filteredArticles.length && showSugestions && searchParam.length > 2 ?
                <div className='suggestions-list'>
                    <div className='search-category-title articles-modif'>Articles</div>
                    <ul className='list-style' style={{ borderBottom: '1px solid #DBDBDB' }}>
                        {filteredArticles.map((suggestion) => {
                            return <li
                                className={'suggestion'}
                                key={suggestion.id}>
                                <Link to={blogUrl(lang, suggestion)} 
                                    onClick={() => setShowSugestions(false)}
                                    dangerouslySetInnerHTML={{ __html: suggestion.title?.rendered! }}></Link>
                            </li>
                        }
                        )}
                    </ul>
                    <div className='search-category-title type-modif'>Type</div>
                    <ul className='list-style'>
                        {filteredCategories.map((suggestion) => {
                            return <li
                                className={'suggestion'}
                                key={suggestion.id}>
                                <Link to={blogCategoryUrl(lang, suggestion.slug, "all")}
                                    onClick={() => setShowSugestions(false)}
                                    dangerouslySetInnerHTML={{ __html: suggestion.name }}></Link>
                            </li>
                        }
                        )}
                    </ul>
                </div>
                : <div></div>}
        </div>
    </div>
}