import React from 'react'

class UserDisabilityCheckboxes extends React.Component {
  constructor(props){
    super(props)
    this.renderContent = this.renderContent.bind(this)
    this.toggleCheckbox = this.toggleCheckbox.bind(this)
  }
  toggleCheckbox(changeEvent) {
    const selectedOption = changeEvent.target.value
    let {values} = this.props
    const index = values.indexOf(selectedOption)
    if (index >= 0) {
      values.splice(index, 1)
    } else {
      values.push(selectedOption)
    }
    this.props.onChange(values)
  }
  renderContent(){
    const {options, values} = this.props
    const inputs = options.map(item => {
      const isChecked = values.includes(item.value)
      let labelClassName = `user-disability-modal__checkbox-label user-disability-modal__checkbox-label_${item.value}`
      if(isChecked){
        labelClassName += ' user-disability-modal__checkbox-label_checked' 
      }
      return(
        <label
          className={labelClassName}
          key={item.value}
        >
          <input
            className='user-disability-modal__checkbox-input' 
            type="checkbox" 
            name={name} 
            checked={isChecked}
            onChange={this.toggleCheckbox}
            disabled={this.props.disabled}
            value={item.value}/>
          <span
            className='user-disability-modal__checkbox-input-custom'
          ></span>
          {this.props.t(item.label)}  
        </label>
      )
    })
    return inputs
  }
  render(){
    return(
      <div className='user-disability-modal__checkboxes-wrapper'>
        {this.renderContent()}
      </div>
    )
  }
}
export default UserDisabilityCheckboxes