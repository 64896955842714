import React from 'react'
import localForage from 'localforage'
import uuid from 'uuid'
import utils from '../lib/utils.js'
import LocaleCurrency from 'locale-currency'
import { DEFAULT_LOCALE, DEFAULT_CURRENCY } from '../lib/constants.js'
import { withTranslation as translate } from 'react-i18next'
import { toGeoJson } from '../lib/google-helpers'
import { requestHdApi, requestContentApi } from '../lib/requests'
import { makeGlobalState } from '../global-state'


let LOCAL_STORAGE_KEY = '_handiscover_web_state'
if (DEBUG) {
  LOCAL_STORAGE_KEY = '_handiscover_web_state_local'
}
else if (IS_DEV) {
  LOCAL_STORAGE_KEY = '_handiscover_web_state_dev'
}
else if (STAGING) {
  LOCAL_STORAGE_KEY = '_handiscover_web_state_staging'
}

const COOKIE_TOKEN_KEY = '_tkl'

const PREVENT_REHYDRATE = false

const defaultState = {
  locale: DEFAULT_LOCALE,
  default_currency: DEFAULT_CURRENCY,
  noStore: {}
}
const setCookie = (cname, cvalue, exdays, path) => {
  const domain = HOST_URL.replace(/^(https?:|)\/\//, '')
  console.log('domain: ', domain)

  var d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  var expires = 'expires=' + d.toUTCString()
  let cookieString = `${cname}=${cvalue};${expires};path=${path};domain=${domain}`
  if (!IS_LOCAL) {
    cookieString += ';secure'
  }
  document.cookie = cookieString;
}

const getCookie = (cname) => {
  var name = cname + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const storeToken = ({ token, user }) => {
  setCookie(COOKIE_TOKEN_KEY, JSON.stringify({ token, userId: user }), 30, '/')
}

const getToken = () => {
  try {
    return JSON.parse(getCookie(COOKIE_TOKEN_KEY))
  } catch (e) {
    return {}
  }
}

const removeToken = () => {
  setCookie(COOKIE_TOKEN_KEY, '', 0, '/')
}


// Helpers
const updateAuthHeader = (authenticate, ip, headers) => {
  if (authenticate && isSignedIn()) {
    headers['Authorization'] = 'Token ' + state.accessToken
  }
  if (ip) {
    headers['X-Real-IP'] = ip
  }
}

// Create query string from params
const createQueryString = (params, endpoint) => {
  endpoint = endpoint.split('?')[0]
  const keys = Object.keys(params)
  const parts = keys.map(k => `${k}=${params[k]}`)
  endpoint = `${endpoint}?${parts.join('&')}`
  return endpoint
}

// Add params to body as json
const setFetchOptions = (params, fetchOptions) => {
  if (!(params instanceof FormData)) {
    fetchOptions.body = JSON.stringify(params)
    fetchOptions.headers['Content-Type'] = 'application/json; charset=utf-8'
  }
  else {
    fetchOptions.body = params
  }
}

export function makeRequest(reqInFlightKey, method, apiUrl, endpoint, params, headers, authenticate = true, preventRedirect = false, accFilter = false, ip) {
  var _endpoint = endpoint
  var _headers = headers ? headers : { Accept: 'application/json' }
  updateAuthHeader(authenticate, ip, _headers)
  const fetchOptions = {
    method: method,
    headers: _headers
  }
  if (method === 'GET' && endpoint === '/properties/search/' && accFilter) {
    if (params) {
      // Create query string from params
      _endpoint = _endpoint.split('?')[0]
      const keys = Object.keys(params)
      const parts = keys.map(k => `${k}=${params[k]}`)
      _endpoint = `${_endpoint}?${parts.join('&')}&${accFilter.join('&')}`
    }
  }
  else if (method === 'GET') {
    if (params) {
      // Create query string from params
      _endpoint = _endpoint.split('?')[0]
      const keys = Object.keys(params)
      const parts = keys.map(k => `${k}=${params[k]}`)
      _endpoint = `${_endpoint}?${parts.join('&')}`
    }
  }
  else {
    if (!(params instanceof FormData)) {
      // Add params to body as json
      fetchOptions.body = JSON.stringify(params)
      fetchOptions.headers['Content-Type'] = 'application/json; charset=utf-8'
    }
    else {
      fetchOptions.body = params
    }
  }
  return updateState(s => {
    s.noStore[reqInFlightKey] = true
  }, preventRedirect)
    .then(() => {
      return fetch(apiUrl + _endpoint, fetchOptions)
    })
    .then(res => {
      // NOTE: This is the only chance we have to figure out the response code
      if (res.ok) {
        if (res.status === 204) {
          // 204 == No content
          return {}
        }
        return res.json()
      }
      else {
        return res.json().then(r => {
          r.status = res.status
          throw r
        })
      }
    })
    .then(retData => {
      return updateState(s => {
        s.noStore[reqInFlightKey] = false
      }, preventRedirect)
        .then(() => {
          return retData
        })
    })
    .catch(e => {
      if (DEBUG) { console.log(method, _endpoint, e) }
      return updateState(s => {
        s.noStore[reqInFlightKey] = false
      }, preventRedirect)
        .then(() => { throw e })
    })
}

function autoDetermineLocale() {
  let locale = navigator.languages
    ? navigator.languages[0]
    : (navigator.language || navigator.userLanguage)
  if (!utils.isLanguageSupported(locale)) {
    locale = DEFAULT_LOCALE
  }
  return locale
}

function autoDetermineCurrency() {
  let browser_currency = DEFAULT_CURRENCY
  if (navigator.languages) {
    for (let i = 0; i < navigator.languages.length; i++) {
      if (navigator.languages[i].split('-').length == 2) {
        let tmp_currency = LocaleCurrency.getCurrency(navigator.languages[i])
        if (tmp_currency && tmp_currency.length > 0) {
          browser_currency = tmp_currency
          break
        }
      }
    }
  }
  return browser_currency
}

function notifyListeners(newState, preventRedirect = false) {
  const localListeners = listeners();
  const listenerKeys = Object.keys(localListeners)

  for (var i = 0; i < listenerKeys.length; i++) {
    const comp = localListeners[listenerKeys[i]]
    if (!comp) {
      delete localListeners[listenerKeys[i]]
    }
    else {
      comp.onStateUpdate(newState, preventRedirect)
    }

  }
}

function storeState(preventRedirect = false) {
  if (!PREVENT_REHYDRATE && !state.noStore.rehydrated) {
    return notifyListeners(state, preventRedirect)
  }
  const stateCopy = utils.deepCopy(state)
  delete stateCopy.noStore
  //delete stateCopy.accessToken
  const signedIn = isSignedIn();
  if (!signedIn && stateCopy && 
    (stateCopy.hasOwnProperty("user") || stateCopy.hasOwnProperty("userId"))
  ) {
    delete stateCopy.user;
    delete stateCopy.userId;
  }
  return localForage.setItem(LOCAL_STORAGE_KEY, stateCopy)
    .catch(e => console.log('storage failed', e))
    .then(() => notifyListeners(state, preventRedirect))
}

function updateState(changeFunc, preventRedirect = false) {
  return new Promise((resolve, reject) => {
    changeFunc(state)
    resolve()
  })
    .then(() => storeState(preventRedirect))
}

function resetDefaultState(retainOptions = {}) {
  return new Promise(res => {
    state = Object.assign({}, defaultState, retainOptions);
    res()
  })
    .then(storeState)
    .then(() => {
      if (!(retainOptions && retainOptions.noStore)) {
        state.noStore = {}
      }
    })
}

// Business logic helpers
function fetchUserProfile(preventRedirect = false) {
  return requestHdApi(
    'fetchProfileRequestInFlight',
    'GET',
    '/users/personal-profile/'
  )
    .then(res => {
      return updateState(s => {
        s.user = res
      }, preventRedirect)
        .then(() => res)
    })
}
function fetchUserProfileAfterHostUpdate(preventRedirect = false) {
  return requestHdApi(
    'fetchProfileRequestInFlight',
    'GET',
    '/users/personal-profile/'
  )
    .then(res => {
      return updateState(s => {
        s.user = res
      }, preventRedirect)
        .then(() => res)
    })
}
//TODO: Get properties from USERID. THE API DOES NOT ALLOW THIS...
function fetchUserPropertiesFromId(userId) {
  return requestHdApi(
    'fetchPropertiesFromIdRequestInFlight',
    'GET',
    '/properties/',
    {}
  )
    .then(res => {
      return updateState(s => {
        s.propertiesFromUser = res
      })
    })
}

function isSignedIn() {
  let signedIn = state.accessToken && state.accessToken.length > 0;
  if (!signedIn) {
    const { token, userId } = getToken()
    if (token && token.length > 0) {
      signedIn = true
      updateState(s => {
        s.accessToken = token
        s.userId = userId
      }, false)
    }
  }
  // NOTE: This is only based on what we can understand form the client.
  // The backend is the authority. The token could for instance have been
  // expired/deleted
  return signedIn
}

function filtersToParams(filters) {
  var params = {}
  if (!filters) {
    return params
  }
  const keys = Object.keys(filters)
  for (var i = 0; i < keys.length; i++) {
    var key = keys[i]
    if (filters[key] === undefined) {
      continue
    }
    params[key] = filters[key]
  }
  return params
}

function filtersToParamsMulti(filters, key, paramName) {
  // Bad name of func, but hey
  let values = filters[key]
  delete filters[key]
  let params = filtersToParams(filters)
  if (values && values.length > 0) {
    let queryStr = values[0]
    for (let i = 1; i < values.length; i++) {
      let val = values[i]
      queryStr += `&${paramName}=${val}`
    }
    params[paramName] = queryStr
  }
  return params
}

var state = utils.deepCopy(defaultState)

var listeners = makeGlobalState(() => ({}));
function container(Component, requireAuthentication) {
  const props = {
    clearNoStore: keys => {
      return updateState(s => {
        keys.forEach(k => {
          s.noStore[k] = undefined
        })
      })
    },

    closeLoginSignupModal: () => {
      return updateState(s => {
        s.signupLoginModalOpen = false
      })
    },

    isSignedIn: isSignedIn,

    fetchBlogBySlug(blogPostSlug) {
      return requestContentApi(
        'fetchBlogBySlugRequestInFlight',
        'GET',
        '/postBySlug/' + blogPostSlug,
        undefined,
        undefined,
        false,
        false
      )
    },
    // https://content-api.handiscover.com/getPrioritizedPostIds/?category=Main page prioritized

    fetchPrioritizedPostIds(ip, is_main_page = false) {
      return requestContentApi(
        'fetchPrioritizedPostIdsRequestInFlight',
        'GET',
        '/getPrioritizedPostIds/' + '?category=' + 'Main page prioritized',
        undefined,
        null,
        isSignedIn(),
        false,
        ip
      )
    },

    fetchPostsByIds(ids) {
      let endpoint = '/posts?id='
      ids.forEach((id, index) => {
        endpoint += id
        if (index < ids.length - 1) endpoint += '&id='
      })
      return requestContentApi(
        'fetchPostsByIdsRequestInFlight',
        'GET',
        endpoint,
        undefined,
        undefined,
        false,
        false
      )
    },

    fetchPostById(id) {
      return requestContentApi(
        'fetchPostByIdRequestInFlight',
        'GET',
        '/posts/' + id,
        undefined,
        undefined,
        false,
        false
      )
    },

    fetchSimilarBlogPosts(id, limit) {
      return requestContentApi(
        'fetchSimilarBlogPostsRequestInFlight',
        'GET',
        '/getSimilarPosts?postId=' + id + '&limit=' + limit,
        undefined,
        undefined,
        false,
        false,
        false
      )
    },

    onHostAdjustPrice: (bookingUnitId, final_amount) => {
      return requestHdApi(
        'hostAdjustPriceRequestInFlight',
        'PATCH',
        `/booking-units/${bookingUnitId}/`,
        { final_amount }
      )
    },
    onApproveEnquiry: bookingUnitId => {
      return requestHdApi(
        'approveEnquiryRequestInFlight',
        'GET',
        `/booking-units/${bookingUnitId}/approve/`
      )
    },

    onDenyEnquiry: bookingUnitId => {
      return requestHdApi(
        'approveEnquiryRequestInFlight',
        'GET',
        `/booking-units/${bookingUnitId}/reject/`
      )
    },
    onCancelBooking: bookingId => {
      return requestHdApi(
        'cancelBookingUnitRequestInFlight',
        'GET',
        `/bookings/${bookingId}/cancel/`
      )
        .then(responseData => {
          console.log(responseData)
        })
    },
    onCancelBookingUnit: bookingUnitId => {
      return requestHdApi(
        'cancelBookingUnitRequestInFlight',
        'GET',
        `/booking-units/${bookingUnitId}/cancel/`
      )
        .then(responseData => {
          console.log(responseData)
        })
    },

    onClearCoupon() {
      return updateState(s => {
        s.noStore.coupon = undefined
      })
    },

    onConfirmAccount: key => {
      return requestHdApi(
        'confirmRequestInFlight',
        'POST',
        '/accounts/confirmations/',
        {
          key: key
        },
        null,
        false
      )
    },

    onConfirmBooking: bookingId => {
      return requestHdApi(
        'confirmBookingRequestInFlight',
        'GET',
        `/bookings/${bookingId}/book-it/`
      )
        .then(responseData => {
          console.log(responseData)
        })
    },

    onCreatePrivateChat: receiver => {
      return requestHdApi(
        'createChatRequestInFlight',
        'POST',
        '/chat/',
        {
          type: 'private',
          chat_users: [
            {
              user: receiver
            }
          ]
        }
      )
        .then(responseData => {
          return updateState(s => {
            // For now we assume that you can only create one company per user
            s.lastChat = responseData
          })
            .then(() => responseData)
        })
    },

    onCreateChatMessage: (chatId, message, meta) => {
      let data = { message }
      if (typeof (meta) !== 'undefined') {
        data.meta = meta
      }
      return requestHdApi(
        'createChatMessageRequestInFlight',
        'POST',
        `/chat/${chatId}/messages/`,
        data
      )
    },

    onCreateAddress: (addressLine1,
      addressLine2,
      postcode,
      city,
      region,
      country,
      type) => {
      return requestHdApi(
        'createAddressRequestInFlight',
        'POST',
        '/addresses/',
        {
          address_line_1: addressLine1,
          address_line_2: addressLine2,
          postcode: postcode,
          city: city,
          region_code: region,
          country_code: country,
          type: type
        }
      )
        .then(responseData => {
          var address = responseData
          return updateState(s => {
            // For now we assume that addresses are
            // only created if you're updating a user
            // or creating a property in 'list your property'
            if (utils.isEqualStrings(address.type, 'user')) {
              s.address = address
            }
            else if (utils.isEqualStrings(address.type, 'property')) {
              s.noStore.lypAddress = address
            }
            else {
              console.log('not storing address...')
            }
          })
            .then(() => {
              return address
            })
        })
    },

    onCreateAdvancedExtraGuestFee: ({
      season,
      nr_extra_guests,
      fee
    }) => {
      return requestHdApi(
        'createAdvancedExtraGuestFeeRequestInFlight',
        'POST',
        '/advanced-extra-guest-fee/',
        {
          season,
          nr_extra_guests,
          fee
        }
      )
        .then(responseData => {
          return updateState(s => {
            // For now we assume PUTs are only
            // created on 'list your property'
            if (!s.noStore.lypAdvancedExtraGuestFees) {
              s.noStore.lypAdvancedExtraGuestFees = []
            }
            s.noStore.lypAdvancedExtraGuestFees.push(responseData)
          })
            .then(() => responseData)
        })
    },

    onCreateAdvancedRate: ({
      season,
      nr_nights,
      nr_guests,
      rate
    }) => {
      return requestHdApi(
        'createAdvancedRateRequestInFlight',
        'POST',
        '/advanced-rates/',
        {
          season,
          nr_nights,
          nr_guests,
          rate
        }
      )
        .then(responseData => {
          return updateState(s => {
            // For now we assume PUTs are only
            // created on 'list your property'
            if (!s.noStore.lypAdvancedRates) {
              s.noStore.lypAdvancedRates = []
            }
            s.noStore.lypAdvancedRates.push(responseData)
          })
            .then(() => responseData)
        })
    },
    onPriceCalculation: (params) => {
      return requestHdApi(
        'createPriceCalculationRequestInFlight',
        'POST',
        '/bookings/price-calculation/',
        params
      ).then(responseData => {
        return responseData
      })
    },
    onCreateBooking: ({ type, property, rate_type }) => {
      let params = { type, property }
      if (rate_type === 'rate_pack') {
        params['rate_type'] = rate_type
      }
      console.log('params', params);
      return requestHdApi(
        'createBookingRequestInFlight',
        'POST',
        '/bookings/',
        params
      )
        .then(responseData => {
          return updateState(s => {
            // For now we assume that you can only create one company per user
            s.lastBooking = responseData
          })
            .then(() => responseData)
        })
    },

    onCreateBookingUnit: ({
      booking,
      property_unit_type,
      start_date,
      end_date,
      nr_adults,
      children,
      coupon_code,
      rate_pack_id,
      guests
    }) => {
      let params = {
        booking,
        property_unit_type,
        start_date,
        end_date,
        nr_adults,
        children,
        rate_pack: rate_pack_id,
        guests
      }
      if (coupon_code) {
        params['coupon_code'] = coupon_code
      }
      return requestHdApi(
        'createBookingUnitRequestInFlight',
        'POST',
        '/booking-units/',
        params
      )
        .then(responseData => {
          return updateState(s => {
            // For now we assume that you can only create one company per user
            s.lastBookingUnit = responseData
          })
            .then(() => responseData)
        })
    },

    onCreateCompany: (name,
      organization_number,
      vat,
      attorney,
      extra_info) => {
      return requestHdApi(
        'createCompanyRequestInFlight',
        'POST',
        '/companies/',
        {
          name: name,
          organization_number: organization_number,
          vat: vat,
          attorney: attorney,
          extra_info: extra_info
        }
      )
        .then(responseData => {
          return updateState(s => {
            // For now we assume that you can only create one company per user
            s.company = responseData
          })
        })
    },

    onCreateChannelManager: ({
      channel_manager,
      channel_manager_user,
      cancellation_policy,
    }) => {
      return requestHdApi(
        'createChannelManagerRequestInFlight',
        'POST',
        '/channel-managers/',
        {
          channel_manager,
          channel_manager_user,
          cancellation_policy
        }
      )
        .then(responseData => {
          return updateState(s => {
            if (!s.noStore.channelManagersObj) {
              s.noStore.channelManagersObj = { results: [] }
            }
            s.noStore.channelManagersObj.results.push(responseData)
          })
            .then(() => responseData)
        })
    },

    onCreatePayoutMethod: data => {
      if (!window.Stripe) {
        throw Error('Attempting to create stripe token before stripe has loaded')
      }

      const stripe = window.Stripe(STRIPE_PUBLISHABLE_KEY)
      const reqObj = {
        country: data.countryCode,
        currency: data.currencyCode,
        account_number: data.accountNumber,
        account_holder_name: data.accountHolder,
        account_holder_type: data.accountHolderType,
      }
      if (data.routingNumber) {
        reqObj.routing_number = data.routingNumber
      }
      return stripe.createToken('bank_account', reqObj).then(result => {
        // handle result.error or result.token
        if (result.error) {
          console.log(result.error)
          throw result.error.message
        }
        else {
          return requestHdApi(
            'createPayoutMethodRequestInFlight',
            'POST',
            '/bank-accounts/',
            {
              stripe_token: result.token,
              routing_number: data.routingNumber || undefined,
              account_number: data.accountNumber,
              country_code: data.countryCode,
              currency_code: data.currencyCode,
              is_default: data.isDefault
            }
          )
            .then(res => res)
            .catch(res => {
              console.log(res)
              throw 'Error when contacting our servers, please try again later.'
            })
        }
      })
    },

    onCreateProperty: ({
      name,
      type,
      address,
      latitude,
      longitude,
      default_currency,
      allow_direct_bookings,
      allow_enquiries,
      description }) => {
      return requestHdApi(
        'createPropertyRequestInFlight',
        'POST',
        '/properties/',
        {
          name,
          type,
          address,
          latitude,
          longitude,
          allow_direct_bookings,
          allow_enquiries,
          description,
          default_currency: default_currency.toUpperCase()
        }
      )
        .then(responseData => {
          return updateState(s => {
            // For now we assume properties are only
            // created on 'list your property'
            s.noStore.lypProperty = responseData
          })
            .then(() => responseData)
        })
    },

    onCreatePropertyAccessibility: ({
      accessibility_type,
      property,
      value_string,
      value_int,
      extra_info
    }) => {
      return requestHdApi(
        'createPropertyAccessibilityRequestInFlight',
        'POST',
        '/property-accessibility/',
        {
          accessibility_type,
          property,
          value_string,
          value_int,
          extra_info
        }
      )
        .then(responseData => {
          return updateState(s => {
            s.noStore.lypPropertyAccessibilities.push(responseData)
          })
            .then(() => responseData)
        })
    },

    onCreatePropertyFacility: ({ propertyId, type }) => {
      return requestHdApi(
        'createPropertyFacilityRequestInFlight',
        'POST',
        '/property-amenities/',
        {
          property: propertyId,
          type: type
        }
      )
        .then(responseData => {
          return updateState(s => {
            if (!s.noStore.lypPropertyFacilities) {
              s.noStore.lypPropertyFacilities = []
            }
            s.noStore.lypPropertyFacilities.push(responseData)
          })
            .then(() => responseData)
        })
    },

    onCreatePropertyImage: ({
      property,
      type,
      url
    }) => {
      return requestHdApi(
        'createPropertyImageRequestInFlight',
        'POST',
        '/property-images/',
        {
          property,
          type,
          url
        }
      )
        .then(responseData => {
          return updateState(s => {
            if (!s.noStore.lypPropertyImages) {
              s.noStore.lypPropertyImages = []
            }
            s.noStore.lypPropertyImages.push(responseData)
          })
            .then(() => responseData)
        })
    },

    onCreatePropertyUnitType: ({
      activated_by_user,
      property,
      name,
      nr_units,
      cancellation_policy,
      max_nr_guests,
      nr_bedrooms,
      nr_beds,
      nr_bathrooms,
      use_availability
    }) => {
      return requestHdApi(
        'createPropertyUnitTypeRequestInFlight',
        'POST',
        '/property-unit-types/',
        {
          activated_by_user,
          property,
          name,
          nr_units,
          cancellation_policy,
          max_nr_guests,
          nr_bedrooms,
          nr_beds,
          nr_bathrooms,
          use_availability
        }
      )
        .then(responseData => {
          return updateState(s => {
            // For now we assume PUTs are only
            // created on 'list your property'
            if (!s.noStore.lypPropertyUnitTypes) {
              s.noStore.lypPropertyUnitTypes = []
            }
            s.noStore.lypPropertyUnitTypes.push(responseData)
          })
            .then(() => responseData)
        })
    },

    onClonePropertyUnitType: (data) => {
      return requestHdApi(
        'createPropertyUnitTypeRequestInFlight',
        'POST',
        '/property-unit-types/',
        data
      )
        .then(responseData => {
          return updateState(s => {
            // For now we assume PUTs are only
            // created on 'list your property'
            if (!s.noStore.lypPropertyUnitTypes) {
              s.noStore.lypPropertyUnitTypes = []
            }
            s.noStore.lypPropertyUnitTypes.push(responseData)
          })
            .then(() => responseData)
        })
    },

    onCreatePutAccessibility: ({
      property_unit_type,
      accessibility_type,
      property,
      value_string,
      value_int,
      extra_info
    }) => {
      return requestHdApi(
        'createPutAccessibilityRequestInFlight',
        'POST',
        '/put-accessibility/',
        {
          property_unit_type,
          accessibility_type,
          property,
          value_string,
          value_int,
          extra_info
        }
      )
        .then(responseData => {
          return updateState(s => {
            s.noStore.lypPutAccessibilities.push(responseData)
          })
            .then(() => responseData)
        })
    },
    onDeletePutAccessibility: ({
      id,
    }) => {
      return requestHdApi(
        'deletePutAccessibilityRequestInFlight',
        'DELETE',
        `/put-accessibility/${id}/`
      )
    },

    onCreatePutFacility: ({ putId, type }) => {
      return requestHdApi(
        'createPutFacilityRequestInFlight',
        'POST',
        '/put-amenities/',
        {
          property_unit_type: putId,
          type: type
        }
      )
        .then(responseData => {
          return updateState(s => {
            if (!s.noStore.lypPutFacilities) {
              s.noStore.lypPutFacilities = []
            }
            s.noStore.lypPutFacilities.push(responseData)
          })
            .then(() => responseData)
        })
    },

    onCreatePutImage: ({
      property_unit_type,
      type,
      url
    }) => {
      return requestHdApi(
        'createPutImageRequestInFlight',
        'POST',
        '/put-images/',
        {
          property_unit_type,
          type,
          url
        }
      )
        .then(responseData => {
          return updateState(s => {
            if (!s.noStore.lypPutImages) {
              s.noStore.lypPutImages = []
            }
            s.noStore.lypPutImages.push(responseData)
          })
            .then(() => responseData)
        })
    },

    onCreateSeason: ({
      property_unit_type,
      name,
      start_date,
      end_date,
      weekend_rate_days,
      check_in_never_on,
      check_out_never_on,
      minimum_stay,
      rate,
      nr_guests_in_rate,
      extra_guest_fee,
      cleaning_fee,
      deposit,
      weekend_rate,
      is_default
    }) => {
      return requestHdApi(
        'createSeasonRequestInFlight',
        'POST',
        '/seasons/',
        {
          property_unit_type,
          name,
          start_date,
          end_date,
          weekend_rate_days,
          check_in_never_on,
          check_out_never_on,
          minimum_stay,
          rate,
          nr_guests_in_rate,
          extra_guest_fee,
          cleaning_fee,
          deposit,
          weekend_rate,
          is_default
        }
      )
        .then(responseData => {
          return updateState(s => {
            // For now we assume PUTs are only
            // created on 'list your property'
            if (!s.noStore.lypSeasons) {
              s.noStore.lypSeasons = []
            }
            s.noStore.lypSeasons.push(responseData)
          })
            .then(() => responseData)
        })
    },

    onCreateSeasonDiscount: ({
      season,
      start_date,
      end_date,
      percent,
      type
    }) => {
      return requestHdApi(
        'createSeasonDiscountRequestInFlight',
        'POST',
        '/season-discounts/',
        {
          season,
          start_date: start_date,
          end_date: end_date,
          percent,
          type
        }
      )
        .then(responseData => {
          return updateState(s => {
            // For now we assume PUTs are only
            // created on 'list your property'
            if (!s.noStore.lypSeasonDiscounts) {
              s.noStore.lypSeasonDiscounts = []
            }
            s.noStore.lypSeasonDiscounts.push(responseData)
          })
            .then(() => responseData)
        })
    },

    onCreateTransaction: ({
      token,
      type,
      booking,
      paymentSystem }) => {
      return requestHdApi(
        'createTransactionRequestInFlight',
        'POST',
        '/transactions/',
        {
          payment_system: paymentSystem,
          source: token,
          type: type,
          booking,
        }
      )
        .then(responseData => {
          return updateState(s => {
            // For now we assume properties are only
            // created on 'list your property'
            s.noStore.transaction = responseData
          })
            .then(() => responseData)
        })
    },

    onAddCreditCard: (token) => {
      return requestHdApi(
        'addCreditCardRequestInFlight',
        'POST',
        '/payment-cards/',
        {
          source: token,
          payment_system: 'stripe'
        }
      )
        .then(responseData => {
          return updateState(s => {
            // For now we assume properties are only
            // created on 'list your property'
            s.noStore.transaction = responseData
          })
            .then(() => responseData)
        })
    },

    onDebug: (key, err) => {
      return fetch(HOST_URL + '/debug', {
        method: 'POST',
        body: JSON.stringify({ key, err }),
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        }
      })
    },

    onDeleteAdvancedExtraGuestFee: id => {
      return requestHdApi(
        'deleteAdvancedExtraGuestFeeRequestInFlight',
        'DELETE',
        `/advanced-extra-guest-fee/${id}/`
      )
        .then(responseData => {
          return updateState(s => {
            if (s.noStore.lypAdvancedExtraGuestFees) {
              let newList = s.noStore.lypAdvancedExtraGuestFees.filter(f => f.id !== id)
              s.noStore.lypAdvancedExtraGuestFees = newList
            }
          })
            .then(() => responseData)
        })
    },

    onDeleteAdvancedRate: id => {
      return requestHdApi(
        'deleteAdvancedRateRequestInFlight',
        'DELETE',
        `/advanced-rates/${id}/`
      )
        .then(responseData => {
          return updateState(s => {
            if (s.noStore.lypAdvancedRates) {
              var newList = s.noStore.lypAdvancedRates.filter(f => f.id !== id)
              s.noStore.lypAdvancedRates = newList
            }
          })
            .then(() => responseData)
        })
    },

    onDeletePropertyFacility: id => {
      return requestHdApi(
        'deletePropertyFacilityRequestInFlight',
        'DELETE',
        `/property-amenities/${id}/`
      )
        .then(responseData => {
          return updateState(s => {
            if (s.noStore.lypPropertyFacilities) {
              var newList = s.noStore.lypPropertyFacilities.filter(f => f.id !== id)
              s.noStore.lypPropertyFacilities = newList
            }
          })
            .then(() => responseData)
        })
    },

    onDeletePropertyImage: id => {
      return requestHdApi(
        'deletePropertyImageRequestInFlight',
        'DELETE',
        `/property-images/${id}/`
      )
        .then(responseData => {
          return updateState(s => {
            if (s.noStore.lypPropertyImages) {
              if (s.noStore.lypPropertyImages) {
                let newList = s.noStore.lypPropertyImages.filter(f => f.id !== id)
                s.noStore.lypPropertyImages = newList
              }
            }
          })
            .then(() => responseData)
        })
    },

    onDeletePutFacility: id => {
      return requestHdApi(
        'deletePutFacilityRequestInFlight',
        'DELETE',
        `/put-amenities/${id}/`
      )
        .then(responseData => {
          return updateState(s => {
            if (s.noStore.lypPutFacilities) {
              var newList = s.noStore.lypPutFacilities.filter(f => f.id !== id)
              s.noStore.lypPutFacilities = newList
            }
          })
            .then(() => responseData)
        })
    },

    onDeletePutImage: id => {
      return requestHdApi(
        'deletePutImageRequestInFlight',
        'DELETE',
        `/put-images/${id}/`
      )
        .then(responseData => {
          return updateState(s => {
            if (s.noStore.lypPutImages) {
              let newList = s.noStore.lypPutImages.filter(f => f.id !== id)
              s.noStore.lypPutImages = newList
            }
          })
            .then(() => responseData)
        })
    },

    onDeleteSeason: id => {
      return requestHdApi(
        'deleteSeasonRequestInFlight',
        'DELETE',
        `/seasons/${id}/`
      )
        .then(responseData => {
          return updateState(s => {
            if (s.noStore.lypSeasons) {
              var newList = s.noStore.lypSeasons.filter(f => f.id !== id)
              s.noStore.lypSeasons = newList
            }
          })
            .then(() => responseData)
        })
    },

    onDeleteSeasonDiscount: id => {
      return requestHdApi(
        'deleteSeasonDiscountRequestInFlight',
        'DELETE',
        `/season-discounts/${id}/`
      )
        .then(responseData => {
          return updateState(s => {
            if (s.noStore.lypSeasonDiscounts) {
              var newList = s.noStore.lypSeasonDiscounts.filter(f => f.id !== id)
              s.noStore.lypSeasonDiscounts = newList
            }
          })
            .then(() => responseData)
        })
    },

    // TODO: proper signout before initiating new signup/login
    onFacebookAuth: (fb_token, email, preventRedirect = false) => {
      return requestHdApi(
        'facebookAuthRequestInFlight',
        'POST',
        '/accounts/sn-auth-token/',
        {
          sn_name: 'facebook',
          sn_token: fb_token,
          email: email,
        },
        null,
        false,
        preventRedirect
      )
        .then(responseData => {
          return updateState(s => {
            storeToken(responseData)
            s.userId = responseData.user
            s.accessToken = responseData.token
          }, preventRedirect)
        })
    },

    onFetchAddress: (id, storeKey = null) => {
      // We just assume right now there is at most one address
      return requestHdApi(
        'fetchAddressRequestInFlight',
        'GET',
        `/addresses/${id}/`
      )
        .then(responseData => {
          var address = responseData
          return updateState(s => {
            if (storeKey) {
              s.noStore[storeKey] = address
            }
            else if (utils.isEqualStrings(address.type, 'user')) {
              s.noStore.personalAddress = address
            }
            else if (utils.isEqualStrings(address.type, 'property')) {
              s.noStore.lypAddress = address
            }
          })
            .then(() => address)
        })
    },

    onFetchAdvancedExtraGuestFees: (seasonId, isLyp = false) => {
      return requestHdApi(
        'fetchAdvancedExtraGuestFeesRequestInFlight',
        'GET',
        '/advanced-extra-guest-fee/',
        {
          season: seasonId
        }
      )
        .then(res => {
          return updateState(s => {
            if (isLyp) {
              s.noStore.lypAdvancedExtraGuestFees = res.results
            }
            else {
              s.lastFetchedAdvancedExtraGuestFees = res.results
            }
          })
            .then(() => res.results)
        })
    },

    onFetchAdvancedRates: (seasonId, isLyp = false) => {
      return requestHdApi(
        'fetchAdvancedRatesRequestInFlight',
        'GET',
        '/advanced-rates/',
        {
          season: seasonId
        }
      )
        .then(res => {
          return updateState(s => {
            if (isLyp) {
              s.noStore.lypAdvancedRates = res.results
            }
            else {
              s.lastFetchedAdvancedRates = res.results
            }
          })
            .then(() => res.results)
        })
    },

    onFetchAvailability: (filters, isLyp = false) => {
      const params = filtersToParamsMulti(filters, 'putIds', 'property_unit_type')
      return requestHdApi(
        'fetchAvailabilityRequestInFlight',
        'GET',
        '/availabilities/',
        params,
        null,
        isLyp
      )
        .then(res => {
          return updateState(s => {
            if (isLyp) {
              s.noStore.lypAvailability = res.results
            }
            else {
              s.noStore.availabilityObject = res
            }
          })
            .then(() => res.results)
        })
    },

    onFetchBankAccounts: (filters, noStore = true, key = 'bankAccounts') => {
      const params = filtersToParams(filters)
      return requestHdApi(
        'fetchBankAccountsRequestInFlight',
        'GET',
        '/bank-accounts/',
        params
      )
        .then(res => {
          return updateState(s => {
            if (noStore) {
              s.noStore[key + 'Object'] = res
              s.noStore[key] = res.results
            }
            else {
              s[key] = res.results
            }
          })
            .then(() => res)
        })
    },

    onFetchBooking: bookingId => {
      return requestHdApi(
        'fetchBookingRequestInFlight',
        'GET',
        `/bookings/${bookingId}/`
      )
        .then(booking => {
          // Update property obj on all puts
          if (booking.booking_units) {
            booking.booking_units.forEach(bu => {
              bu.property_unit_type_obj.property_obj = booking.property_obj
            })
          }
          return booking
        })
    },

    onFetchBookings: (filters, noStore = true, key = 'bookings') => {
      const params = filtersToParams(filters)
      return requestHdApi(
        'fetchBookingsRequestRequestInFlight',
        'GET',
        '/bookings/',
        params
      )
        .then(res => {
          return updateState(s => {
            if (noStore) {
              s.noStore[key + 'Object'] = res
              s.noStore[key] = res.results
            }
            else {
              s[key] = res.results
            }
          })
            .then(() => res)
        })
    },

    onFetchBookingUnits: filters => {
      const params = filtersToParams(filters)
      return requestHdApi(
        'fetchBookingUnitsRequestInFlight',
        'GET',
        '/booking-units/',
        params
      )
        .then(responseData => {
          return responseData
        })
    },

    onFetchChannelManagers: () => {
      return requestHdApi(
        'fetchChannelManagersRequestInFlight',
        'GET',
        '/channel-managers/'
      )
        .then(res => {
          return updateState(s => {
            s.noStore.channelManagersObj = res
          })
            .then(() => res)
        })
    },

    onFetchChatAll: filters => {
      var params = filtersToParams(filters)
      return requestHdApi(
        'fetchChatsRequestInFlight',
        'GET',
        '/chat/',
        params
      )
        .then(res => {
          return updateState(s => {
            s.noStore.chatsObject = res
          })
            .then(() => res.results)
        })
    },

    onFetchChat: (chatId) => {
      return requestHdApi(
        'fetchChatRequestInFlight',
        'GET',
        `/chat/${chatId}/`
      )
        .then(res => {
          return updateState(s => {
            s.noStore.singleChatObject = res
            if (s.noStore.chatsObject && s.noStore.chatsObject.results && s.noStore.chatsObject.results.length > 0) {
              let res = s.noStore.chatsObject.results
              for (let i = 0; i < res.length; i++) {
                let chat = res[i]
                if (chat.id === chatId) {
                  s.noStore.chatsObject.results[i] = s.noStore.singleChatObject
                  break
                }
              }
            }
          })
            .then(() => res)
        })
    },

    onFetchChatMessages: (chatId) => {
      return requestHdApi(
        'fetchChatMessagesRequestInFlight',
        'GET',
        `/chat/${chatId}/messages/`
      )
        .then(res => {
          return updateState(s => {
            s.noStore.messagesObject = res
          })
            .then(() => res.results)
        })
    },

    onFetchCompany: () => {
      return requestHdApi(
        'fetchCompanyRequestInFlight',
        'GET',
        '/companies/'
      )
        .then(responseData => {
          return updateState(s => {
            s.company = utils.first(responseData.results)
          })
            .then(() => utils.first(responseData.results))
        })
    },

    onFetchCoupon: code => {
      const encCode = escape(code)
      return requestHdApi(
        'fetchCouponRequestInFlight',
        'GET',
        `/coupons/code/${code}/`
      )
        .then(responseData => {
          return updateState(s => {
            s.noStore.coupon = responseData
          })
            .then(() => responseData)
        })
    },

    // createCoupon: () => {
    //   return requestHdApi(
    //     'createCouponRequestInFlight',
    //     'POST',
    //     '/coupons/',
    //     {
    //       expires_date: "2022-05-02T00:00:00Z",
    //       code: "FilipTestAmount",
    //       name: "FilipTestAmountName",
    //       amount: 1,
    //       percent: 0,
    //       currency: "GBP",
    //       discount_type: "reusable",
    //       using_type: "booking_unit",
    //       provider_type: "handiscover"
    //     }
    //   )
    // },

    onFetchCouponById: id => {
      return requestHdApi(
        'fetchCouponRequestInFlight',
        'GET',
        `/coupons/${id}/`
      )
        .then(responseData => {
          return updateState(s => {
            s.noStore.coupon = responseData
          })
            .then(() => responseData)
        })
    },

    onFetchCurrentExchangeRates: () => {
      return fetch('https://openexchangerates.org/api/latest.json?app_id=' + OPEN_EXCHANGE_RATES_APP_ID, { method: 'GET' })
        .then(r => r.json())
        .then(exchangeRates => {
          return updateState(s => {
            s.noStore.exchangeRates = exchangeRates
          })
            .then(() => {
              return exchangeRates
            })
        })
        .catch(e => {
          if (DEBUG) {
            console.log(e)
          }
        })
    },

    onFetchGeocodingLabel: (longitude, latitude) => {
      if (window.google) {
        return new Promise((resolve, reject) => {
          const geocoder = new window.google.maps.Geocoder
          const latlng = { lat: latitude, lng: longitude }
          geocoder.geocode({ 'location': latlng }, (results, status) => {
            if (status === 'OK') {
              const geoJson = toGeoJson(results)
              if (geoJson && geoJson.features && geoJson.features.length > 0) {
                const props = geoJson.features[0].properties
                const labelProps = []
                if (props.country) {
                  labelProps.push(props.country.long_name)
                }
                if (props.administrative_area_level_1) {
                  labelProps.push(props.administrative_area_level_1.short_name)
                }
                if (props.administrative_area_level_2) {
                  labelProps.push(props.administrative_area_level_2.short_name)
                }
                if (props.postal_town) {
                  labelProps.push(props.postal_town.short_name)
                }
                resolve(labelProps.join(', '))
              }
              else {
                resolve('')
              }
            }
            else {
              reject('Geocoder failed due to: ' + status)
            }
          })
        })
      }
      else {
        return new Promise((_, rej) => rej('Google not loaded before using reverse geocoding'))
      }
    },

    onFetchHostData: () => {
      return requestHdApi(
        'fetchHostDataRequestInFlight',
        'GET',
        '/users/host_page/'
      )
    },

    onFetchLivePrices: (startDate, endDate, ratePackId, nrGuests) => {
      console.log('Deprecated endpoint, dont use!')
      return requestHdApi(
        'fetchLivePrice',
        'POST',
        '/booking-units/live-price/',
        {
          start_date: startDate,
          end_date: endDate,
          rate_pack_id: ratePackId,
          nr_guests: nrGuests,
          channel_manager: 'amadeus' // Change to rate pack source, but perhaps that should be a backend issue
        }
      )
    },
    onFetchPropropertySeasons: (propertyId) => {
      return requestHdApi(
        'fetchPropertySeasons',
        'GET',
        `/seasons/?property_unit_type__property=${propertyId}`
      )
    },
    onFetchPropertyLivePrices: (startDate, endDate, propertyId, groupFit, manager) => {
      if (typeof (manager) === 'undefined') {
        manager = 'amadeus'
      }
      return requestHdApi(
        'fetchPropertyLivePrice',
        'POST',
        '/properties/live-price-property/',
        // {
        //   start_date: startDate,
        //   end_date: endDate,
        //   property: propertyId,
        //   nr_rooms: nrRooms,
        //   nr_guests: nrGuests,
        // }
        {
          start_date: startDate,
          end_date: endDate,
          property: propertyId,
          group_fit: groupFit
        }
      )
    },
    onFetchPaymentCards: (filters, noStore = true, key = 'paymentCards') => {
      const params = filtersToParams(filters)
      return requestHdApi(
        'fetchPaymentCardsRequestInFlight',
        'GET',
        '/payment-cards/',
        params
      )
        .then(res => {
          return updateState(s => {
            if (noStore) {
              s.noStore[key + 'Object'] = res
              s.noStore[key] = res.results
            }
            else {
              s[key] = res.results
            }
          })
            .then(() => res)
        })
    },

    onFetchPayouts: (filters, noStore = true, key = 'payouts') => {
      const params = filtersToParams(filters)
      return requestHdApi(
        'fetchPayoutsRequestInFlight',
        'GET',
        '/payouts/',
        params
      )
        .then(res => {
          return updateState(s => {
            if (noStore) {
              s.noStore[key + 'Object'] = res
              s.noStore[key] = res.results
            }
            else {
              s[key] = res.results
            }
          })
            .then(() => res)
        })
    },

    onRetryPayout: (id) => {
      return requestHdApi(
        'retryPayoutRequestInFlight',
        'GET',
        `/payouts/${id}/retry/`
      )
        .then(res => {
          return res
        })
    },

    onFetchProfile: (preventRedirect = false) => {
      return fetchUserProfile(preventRedirect)
    },
    onFetchProfileHostUpdate: (preventRedirect = false) => {
      return fetchUserProfileAfterHostUpdate(preventRedirect)
    },

    onFetchProperties: (filters, auth = false, noStore = false, key = 'propertiesFromUser') => {
      // TODO: shouldn't need to be signed in here
      // ugly hack.. but wtf
      let ids = filters.propertyIds
      delete filters.propertyIds
      let params = filtersToParams(filters)
      if (ids && ids.length > 0) {
        let idQuery = ids[0]
        for (let i = 1; i < ids.length; i++) {
          let id = ids[i]
          idQuery += `&id=${id}`
        }
        params.id = idQuery
      }

      if (!utils.anyKeys(params)) {
        // Big no no. Would fetch all properties
        return new Promise(r => r([]))
      }

      return requestHdApi(
        'fetchPropertiesFromIdRequestInFlight',
        'GET',
        '/properties/',
        params,
        null,
        auth
      )
        .then(res => {
          return updateState(s => {
            if (noStore) {
              s.noStore[key] = res.results
            }
            else {
              s[key] = res.results
            }

          })
            .then(() => {
              return res.results
            })
        })
    },

    onFetchProperty: (propertyId, isLyp = false) => {
      return requestHdApi(
        'fetchPropertyRequestInFlight',
        'GET',
        `/properties/${propertyId}/`,
        {}
      )
        .then(res => {
          return updateState(s => {
            if (isLyp) {
              s.noStore.lypProperty = res
            }
            else {
              s.lastFetchedProperty = res
            }
          })
            .then(() => res)
        })
    },

    onFetchPropertyAccessibilities: (filters, locale, isLyp = false) => {
      var params = filtersToParams(filters)
      const headers = { 'Accept-Language': locale }
      return requestHdApi(
        'fetchPropertyAccessibilitiesRequestInFlight',
        'GET',
        '/property-accessibility/',
        params,
        headers
      )
        .then(res => {
          return updateState(s => {
            if (isLyp) {
              s.noStore.lypPropertyAccessibilities = res.results
            }
            else {
              s.lastFetchedPropertyAccessibilities = res.results
            }
          })
            .then(() => res.results)
        })
    },

    onFetchPropertyUnitType: (putId, auth = false) => {
      return requestHdApi(
        'fetchPropertyUnitTypeRequestInFlight',
        'GET',
        `/property-unit-types/${putId}/`,
        {},
        null,
        auth
      )
    },

    onMobileMapToggle() {
      let key = 'showMobileMap'
      return updateState(s => {
        s.noStore[key] = !s.noStore[key]
      })
    },

    onSearchProperties: (filters, auth = true, noStoreKey = 'lastFetchedPropertiesSearch', ip, accFilter, locale) => {
      const params = filtersToParamsMulti(filters, 'putIds', 'id')
      const headers = { 'X-Real-IP': ip, 'Accept-Language': locale }
      return requestHdApi(
        'fetchPropertiesSearch',
        'GET',
        '/properties/search/',
        params,
        headers,
        true,
        false,
        ip,
        accFilter
      )
        .then(res => {
          return updateState(s => {
            s.noStore[noStoreKey + 'Object'] = res
            s.noStore[noStoreKey] = res.results
          })
            .then(() => res)
        })
    },

    onSearchPropertiesForMap: (filters, auth = false) => {
      const params = filtersToParamsMulti(filters, 'putIds', 'id')
      return requestHdApi(
        'fetchPropertiesSearch',
        'GET',
        '/properties/search-map/',
        params,
        null,
        auth
      )
        .then(res => {
          return res.results
        })
    },

    onSearchSimilarProperties: (filters, id, auth = false, noStoreKey = 'lastFetchedSimilarProperties') => {
      const params = filtersToParamsMulti(filters)
      return requestHdApi(
        'fetchSimilarProperties',
        'GET',
        '/properties/' + id + '/search-similar/',
        params,
        null,
        auth
      )
        .then(res => {
          return updateState(s => {
            s.noStore[noStoreKey + 'Object'] = res
            s.noStore[noStoreKey] = res.results
          })
            .then(() => res)
        })
    },

    onFetchPropertyUnitTypes: (filters, auth = false, noStoreKey = 'lastFetchedPropertyUnitTypes') => {
      const params = filtersToParamsMulti(filters, 'putIds', 'id')
      return requestHdApi(
        'fetchPropertyUnitTypesRequestInFlight',
        'GET',
        '/property-unit-types/',
        params,
        null,
        auth
      )
        .then(res => {
          return updateState(s => {
            s.noStore[noStoreKey + 'Object'] = res
            s.noStore[noStoreKey] = res.results
          })
            .then(() => res)
        })

    },

    onFetchPropertiesMRP: (filters, auth = false, noStoreKey = 'lastFetchedPropertiesMRP') => {
      const params = filtersToParamsMulti(filters, 'putIds', 'id')
      return requestHdApi(
        'fetchPropertyUnitTypesRequestInFlight',
        'GET',
        '/properties/',
        Object.assign(params),
        null,
        auth
      )
        .then(res => {
          return updateState(s => {
            s.noStore[noStoreKey + 'Object'] = res
            s.noStore[noStoreKey] = res.results
          })
            .then(() => res)
        })

    },

    onFetchPropertyFacilities: (filters, isLyp = false) => {
      var params = filtersToParams(filters)
      return requestHdApi(
        'fetchPropertyFacilitiesRequestInFlight',
        'GET',
        '/property-amenities/',
        params
      )
        .then(res => {
          return updateState(s => {
            if (isLyp) {
              s.noStore.lypPropertyFacilities = res.results
            }
            else {
              s.lastFetchedPropertyFacilities = res.results
            }
          })
            .then(() => res.results)
        })
    },

    onFetchPropertyImages: (filters, isLyp = false) => {
      var ids = filters.propertyIds
      delete filters.propertyIds
      var params = filtersToParams(filters)
      params.limit = 300
      if (ids && ids.length > 0) {
        var idQuery = ids[0]
        for (var i = 1; i < ids.length; i++) {
          var id = ids[i]
          idQuery += `&property=${id}`
        }
        params.property = idQuery
      }

      return requestHdApi(
        'fetchPropertyImagesRequestInFlight',
        'GET',
        '/property-images/',
        params
      )
        .then(res => {
          return updateState(s => {
            if (isLyp) {
              s.noStore.lypPropertyImages = res.results
            }
            else {
              s.lastFetchedPropertyImages = res.results
            }
          })
            .then(() => res.results)
        })
    },

    onFetchPublicProfile: userId => {
      return requestHdApi(
        'fetchPublicProfileRequestInFlight',
        'GET',
        `/users/${userId}/`,
        {},
        null,
        false
      )
        .then(res => {
          return updateState(s => {
            s.lastFetchedPublicProfile = res
          })
            .then(() => {
              return res
            })
        })
    },

    onFetchPutAccessibilities: (filters, locale, isLyp = false) => {
      var params = filtersToParams(filters)
      const headers = { 'Accept-Language': locale }
      return requestHdApi(
        'fetchPutAccessibilitiesRequestInFlight',
        'GET',
        '/put-accessibility/',
        params,
        headers
      )
        .then(res => {
          return updateState(s => {
            if (isLyp) {
              s.noStore.lypPutAccessibilities = res.results
            }
            else {
              s.lastFetchedPutAccessibilities = res.results
            }
          })
            .then(() => res.results)
        })
    },

    onFetchPutFacilities: (filters, isLyp = false) => {
      var params = filtersToParams(filters)
      return requestHdApi(
        'fetchPutFacilitiesRequestInFlight',
        'GET',
        '/put-amenities/',
        params,
        null,
        isLyp
      )
        .then(res => {
          return updateState(s => {
            if (isLyp) {
              s.noStore.lypPutFacilities = res.results
            }
            else {
              s.lastFetchedPutFacilities = res.results
            }
          })
            .then(() => res.results)
        })
    },

    onFetchPutImages: (filters, isLyp = false) => {
      var ids = filters.putIds
      delete filters.putIds
      var params = filtersToParams(filters)
      params.limit = 300
      if (ids && ids.length > 0) {
        var idQuery = ids[0]
        for (var i = 1; i < ids.length; i++) {
          var id = ids[i]
          idQuery += `&property_unit_type=${id}`
        }
        params.property_unit_type = idQuery
      }

      return requestHdApi(
        'fetchPutImagesRequestInFlight',
        'GET',
        '/put-images/',
        params,
        null,
        isLyp //Only auth if we're in lyp
      )
        .then(res => {
          return updateState(s => {
            if (isLyp) {
              s.noStore.lypPutImages = res.results
            }
            else {
              s.lastFetchedPutImages = res.results
            }
          })
            .then(() => res.results)
        })
    },

    onFetchPutUrls: puts => {
      let path = puts.map(p => p.id).join(',')
      return fetch(`${HOST_URL}/put-url-lookups?putids=${path}`, {
        method: 'GET',
      })
        .then(res => res.json())
        .then(res => {
          if (DEBUG) { console.log('put url lookup:', res) }
          return res
        })
        .catch(err => {
          console.log(err)
        })
    },

    onFetchSeasons: (filters, isLyp = false) => {
      var ids = filters.putIds
      delete filters.putIds
      var params = filtersToParams(filters)
      params.limit = 1000
      if (ids && ids.length > 0) {
        var idQuery = ids[0]
        for (var i = 1; i < ids.length; i++) {
          var id = ids[i]
          idQuery += `&property_unit_type=${id}`
        }
        params.property_unit_type = idQuery
      }
      return requestHdApi(
        'fetchSeasonsRequestInFlight',
        'GET',
        '/seasons/',
        params
      )
        .then(res => {
          return updateState(s => {
            if (isLyp) {
              s.noStore.lypSeasons = res.results
            }
            else {
              s.lastFetchedSeasons = res.results
            }
          })
            .then(() => res.results)
        })
    },

    onFetchSeasonDiscounts: (seasonId, isLyp = false) => {
      return requestHdApi(
        'fetchSeasonDiscountsRequestInFlight',
        'GET',
        '/season-discounts/',
        {
          season: seasonId
        }
      )
        .then(res => {
          return updateState(s => {
            if (isLyp) {
              s.noStore.lypSeasonDiscounts = res.results
            }
            else {
              s.lastFetchedSeasonDiscounts = res.results
            }
          })
            .then(() => res.results)
        })
    },

    onFetchTransactions: (filters, noStore = true, key = 'transactions') => {
      const params = filtersToParams(filters)
      return requestHdApi(
        'fetchTransactionsRequestInFlight',
        'GET',
        '/transactions/',
        params
      )
        .then(res => {
          return updateState(s => {
            if (noStore) {
              s.noStore[key + 'Object'] = res
              s.noStore[key] = res.results
            }
            else {
              s[key] = res.results
            }
          })
            .then(() => res)
        })
    },

    onFetchUser: (userId) => {
      return requestHdApi(
        'fetchProfileFromIdRequestInFlight',
        'GET',
        `/users/${userId}/`,
        {},
        null,
        false
      )
        .then(res => {
          return updateState(s => {
            s.userFromId = res
          })
        })
    },

    onGoogleAuth: (googleToken, email, preventRedirect = false) => {
      return requestHdApi(
        'googleAuthRequestInFlight',
        'POST',
        '/accounts/sn-auth-token/',
        {
          sn_name: 'google',
          sn_token: googleToken,
          email: email,
        },
        null,
        false,
        preventRedirect
      )
        .then(responseData => {
          return updateState(s => {
            storeToken(responseData)
            s.userId = responseData.user
            s.accessToken = responseData.token
          }, preventRedirect)
        })
    },
    onEditModeSave: (modeStr) => {
      return updateState(s => {
        s.editModeStr = modeStr
      })
    },
    onLocationCache: (locationCacheStr) => {
      return updateState(s => {
        s.locationCache = locationCacheStr
      })
    },
    onZendeskSave: (zendeskStr) => {
      return updateState(s => {
        s.zendeskStr = zendeskStr
      })
    },
    onLogin: (email, password, preventRedirect = false) => {
      return requestHdApi(
        'loginRequestInFlight',
        'POST',
        '/accounts/base-auth-token/',
        {
          email: email,
          password: password,
        },
        null,
        false,
        preventRedirect
      )
        .then(responseData => {
          return updateState(s => {
            storeToken(responseData)
            s.accessToken = responseData.token
            s.userId = responseData.user
          }, preventRedirect)
        })
    },

    onPasswordChange: (old_password, new_password) => {
      return requestHdApi(
        'passwordChangeInFlight',
        'POST',
        '/accounts/password-change/',
        {
          old_password: old_password,
          new_password: new_password
        },
        null,
        true
      )
    },

    onPasswordReset: (key, password) => {
      return requestHdApi(
        'pwResetRequestInFlight',
        'POST',
        '/accounts/password-recovery-confirmation/?key=' + key,
        {
          key: key,
          password: password
        },
        null,
        false
      )
    },

    onQuickSignup: (firstName, lastName, email) => {
      return requestHdApi(
        'quickSignupRequestInFlight',
        'POST',
        '/accounts/light-base-sign-up/',
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
        },
        null,
        false
      )
        .then(responseData => {
          return updateState(s => {
            storeToken(responseData)
            s.accessToken = responseData.token
            s.userId = responseData.user
          })
            .then(fetchUserProfile)
        })
    },

    onSendPasswordReset: email => {
      return requestHdApi(
        'sendPwResetRequestInFlight',
        'POST',
        '/accounts/password-recovery/',
        {
          email: email,
        },
        null,
        false
      )
    },

    onSeoLookup: (propertyId, putId = null) => {
      return fetch(`${HOST_URL}/url-lookup?pid=${propertyId}&putid=${putId}`, {
        method: 'GET',
      })
        .then(res => {
          if (res.ok) {
            return res.text()
          }
          else {
            throw res
          }
        })
        .then(res => {
          if (DEBUG) { console.log('reolookup:', res) }
          return res
        })
        .catch(err => {
          console.log(err)
        })
    },

    onReverseSeoLookup: path => {
      return fetch(`${API_URL}/properties/${path}`, {
        method: 'GET',
      })
        .then(res => res.json())
        .then(res => {
          return res
        })
        .catch(err => {
          console.log(err)
        })
    },

    onSetDefaultCurrency: currency => {
      updateState(s => {
        s.default_currency = currency.toUpperCase()
      })
    },

    onSetLocale: locale => {
      return updateState(s => {
        s.locale = locale
      })
        .then(() => locale)
    },

    onSignout: () => {
      // NOTE: We don't care about signing out google, facebook, etc
      // What's important is that the accessToken is removed + any personal data
      // TODO: When endpoint exists for it, call backend to sign out

      removeToken()
      return resetDefaultState({
        locale: state.locale,
        default_currency: state.default_currency,
        noStore: { rehydrated: state.noStore.rehydrated }
      })
    },

    onSignup: (firstName, lastName, email, password, meta) => {
      return requestHdApi(
        'signupRequestInFlight',
        'POST',
        '/accounts/base-sign-up/',
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          meta: meta,
        },
        null,
        false
      )
        .then(responseData => {
        })
    },

    onShowSignupLoginModal: () => {
      return updateState(s => {
        s.signupLoginModalOpen = true
      })
    },
    onTripadvisorFetch: (propertyId) => {
      return requestHdApi(
        'tripadvisorFetch',
        'GET',
        '/reviews/tripadvisor-review-proxy/',
        {
          property: propertyId
        },
        null,
        false
      )
        .then(responseData => {
          return responseData
        }
        )
    },
    onUpdateAddress: (id, changes) => {
      return updateState(s => {
        s.address = Object.assign({}, s.address, changes)
      })
        .then(() => {
          return requestHdApi(
            'updateAddressRequestInFlight',
            'PATCH',
            `/addresses/${id}/`,
            changes
          )
        })
        .then(responseData => {
          var address = responseData
          return updateState(s => {
            if (utils.isEqualStrings(address.type, 'user')) {
              s.address = address
            }
            else if (utils.isEqualStrings(address.type, 'property')) {
              s.noStore.lypAddress = address
            }
          })
            .then(() => address)
        })
    },

    onUpdateAdvancedExtraGuestFee: (id, changes) => {
      return requestHdApi(
        'updateAdvancedExtraGuestFeeRequestInFlight',
        'PATCH',
        `/advanced-extra-guest-fee/${id}/`,
        changes
      )
        .then(responseData => {
          return updateState(s => {
            if (s.noStore.lypAdvancedExtraGuestFees) {
              let fees = s.noStore.lypAdvancedExtraGuestFees.filter(a => a.id !== id)
              fees.push(responseData)
              s.noStore.lypAdvancedExtraGuestFees = fees
            }
          })
            .then(() => responseData)
        })
    },

    onUpdateAdvancedRate: (id, changes) => {
      return requestHdApi(
        'updateAdvancedRateRequestInFlight',
        'PATCH',
        `/advanced-rates/${id}/`,
        changes
      )
        .then(responseData => {
          return updateState(s => {
            var puts = s.noStore.lypAdvancedRates.filter(a => a.id !== id)
            puts.push(responseData)
            s.noStore.lypAdvancedRates = puts
          })
            .then(() => responseData)
        })
    },

    onUpdateAvailability: (id, changes) => {
      return requestHdApi(
        'updateAvailabilityRequestInFlight',
        'PATCH',
        `/availabilities/${id}/`,
        changes
      )
        .then(responseData => {
          return updateState(s => {
            let availability = s.noStore.lypAvailability.filter(a => a.id !== id)
            availability.push(responseData)
            utils.sortOnDate(availability, 'date')
            s.noStore.lypAvailability = availability
          })
        })
    },

    onUpdateBooking: (id, changes, noStoreKey = '') => {
      return requestHdApi(
        'updateBookingRequestInFlight',
        'PATCH',
        `/bookings/${id}/`,
        changes
      )
    },

    onUpdateBookingUnit: (id, changes) => {
      return requestHdApi(
        'updateBookingUnitRequestInFlight',
        'PATCH',
        `/booking-units/${id}/`,
        changes
      )
    },

    onUpdateChatMessage: (chatId, messageId, changes) => {
      return requestHdApi(
        'updateChatMessageRequestInFlight',
        'PATCH',
        `/chat/${chatId}/messages/${messageId}/`,
        changes
      )
    },

    onUpdateCompany: (id, changes) => {
      return updateState(s => {
        s.company = Object.assign({}, s.company, changes)
      })
        .then(() => {
          return requestHdApi(
            'updateCompanyRequestInFlight',
            'PATCH',
            `/companies/${id}/`,
            changes
          )
        })
        .then(responseData => {
          return updateState(s => {
            s.company = responseData
          })
        })
    },

    onUpdateHost: (mainData, additionalData) => {
      return requestHdApi(
        'updateCompanyRequestInFlight',
        'PUT',
        '/users/host_page/',
        {
          main_fields: mainData,
          additional_fields: additionalData
        }
      )
    },

    onUpdateProfile: changes => {
      return updateState(s => {
        s.user = Object.assign({}, s.user, changes)
      })
        .then(() => {
          return requestHdApi(
            'updateProfileRequestInFlight',
            'PATCH',
            '/users/personal-profile/',
            changes
          )
        })
        .then(responseData => {
          return updateState(s => {
            s.user = responseData
          })
        })
    },

    onUpdateProperty: (id, changes, noStoreKey = 'mrpProperties') => {
      return requestHdApi(
        'updatePropertyRequestInFlight',
        'PATCH',
        `/properties/${id}/`,
        changes
      )
        .then(responseData => {
          return updateState(s => {
            if (s.noStore[noStoreKey]) {
              let puts = s.noStore[noStoreKey].filter(a => a.id !== id)
              puts.push(responseData)
              s.noStore[noStoreKey] = puts
            }
          })
            .then(() => responseData)
        })
    },

    onUpdatePropertyAccessibility: (id, changes) => {
      return requestHdApi(
        'updatePropertyAccessibilityRequestInFlight',
        'PATCH',
        `/property-accessibility/${id}/`,
        changes
      )
        .then(responseData => {
          return updateState(s => {
            var accessibilities = s.noStore.lypPropertyAccessibilities.filter(a => a.id !== id)
            accessibilities.push(responseData)
            s.noStore.lypPropertyAccessibilities = accessibilities
          })
        })
    },
    onDeletePropertyAccessibility: (id) => {
      return requestHdApi(
        'deletePropertyAccessibilityRequestInFlight',
        'DELETE',
        `/property-accessibility/${id}/`
      )
        .then(responseData => {
          return updateState(s => {
            var accessibilities = s.noStore.lypPropertyAccessibilities.filter(a => a.id !== id)
            accessibilities.push(responseData)
            s.noStore.lypPropertyAccessibilities = accessibilities
          })
        })
    },

    onUpdatePropertyImage: (id, changes) => {
      return requestHdApi(
        'updatePropertyImageRequestInFlight',
        'PATCH',
        `/property-images/${id}/`,
        changes
      )
        .then(responseData => {
          return updateState(s => {
            if (s.noStore.lypPropertyImages) {
              let images = s.noStore.lypPropertyImages.filter(a => a.id !== id)
              images.push(responseData)
              s.noStore.lypPropertyImages = images
            }
          })
            .then(() => responseData)
        })
    },

    onUpdatePut: (id, changes, noStoreKey = 'lastFetchedPropertyUnitTypes') => {
      return requestHdApi(
        'updatePutRequestInFlight',
        'PATCH',
        `/property-unit-types/${id}/`,
        changes
      )
        .then(responseData => {
          return updateState(s => {
            if (s.noStore[noStoreKey]) {
              let puts = s.noStore[noStoreKey].filter(a => a.id !== id)
              puts.push(responseData)
              s.noStore[noStoreKey] = puts
            }
          })
            .then(() => responseData)
        })
    },

    onUpdatePutAccessibility: (id, changes) => {
      return requestHdApi(
        'updatePutAccessibilityRequestInFlight',
        'PATCH',
        `/put-accessibility/${id}/`,
        changes
      )
        .then(responseData => {
          return updateState(s => {
            var accessibilities = s.noStore.lypPutAccessibilities.filter(a => a.id !== id)
            accessibilities.push(responseData)
            s.noStore.lypPutAccessibilities = accessibilities
          })
        })
    },

    onUpdatePutImage: (id, changes) => {
      return requestHdApi(
        'updatePutImageRequestInFlight',
        'PATCH',
        `/put-images/${id}/`,
        changes
      )
        .then(responseData => {
          return updateState(s => {
            if (s.noStore.lypPutImages) {
              let images = s.noStore.lypPutImages.filter(a => a.id !== id)
              images.push(responseData)
              s.noStore.lypPutImages = images
            }
          })
            .then(() => responseData)
        })
    },

    onUpdateSeason: (id, changes) => {
      return requestHdApi(
        'updateSeasonRequestInFlight',
        'PATCH',
        `/seasons/${id}/`,
        changes
      )
        .then(responseData => {
          return updateState(s => {
            let puts = s.noStore.lypSeasons.filter(a => a.id !== id)
            puts.push(responseData)
            s.noStore.lypSeasons = puts
          })
            .then(() => responseData)
        })
    },

    onUpdateSeasonDiscount: (id, changes) => {
      return requestHdApi(
        'updateSeasonDiscountRequestInFlight',
        'PATCH',
        `/season-discounts/${id}/`,
        changes
      )
        .then(responseData => {
          return updateState(s => {
            var puts = s.noStore.lypSeasonDiscounts.filter(a => a.id !== id)
            puts.push(responseData)
            s.noStore.lypSeasonDiscounts = puts
          })
            .then(() => responseData)
        })
    },

    onUpdateStripeIdentification: tokenId => {
      return requestHdApi(
        'updateStripeIdentificationProfileRequestInFlight',
        'POST',
        '/accounts/stripe-include-document/',
        {
          stripe_doc_token: tokenId
        }
      )
        .then(res => {
          return fetchUserProfile()
            .then(() => {
              return res
            })
        })
    },

    onUploadUserAvatarImage: (userId, file) => {
      const formData = new FormData()
      formData.append('avatar', file)
      formData.append('file_type', 'file')
      return requestHdApi(
        'uploadUserAvatarImageRequestInFlight',
        'PATCH',
        '/users/personal-profile/',
        formData
      )
        .then(res => {
          updateState(s => {
            s.user = res
          })
        })
    },
    onDeleteUserAvatarImage: (userId) => {
      const formData = new FormData()
      formData.append('avatar', ' ')
      formData.append('file_type', 'file')
      return requestHdApi(
        'uploadUserAvatarImageRequestInFlight',
        'PATCH',
        '/users/personal-profile/',
        formData
      )
        .then(res => {
          updateState(s => {
            s.user = res
          })
        })
    },

    onUploadPropertyImage: (propertyId, type, file) => {
      const formData = new FormData()
      // file name required by API, to correctly check and store file,
      // so for Blob's we are setting its manually
      if (file.constructor && file.constructor.name && file.constructor.name === 'Blob') {
        formData.append('image', file, file.type.replace('/', '.'))
      }
      else {
        formData.append('image', file)
      }
      formData.append('type', type)
      formData.append('property', propertyId)
      formData.append('file_type', 'file')
      return requestHdApi(
        'uploadPropertyImageRequestInFlight',
        'POST',
        '/property-images/',
        formData
      )
        .then(responseData => {
          updateState(s => {
            if (!s.noStore.lypPropertyImages) {
              s.noStore.lypPropertyImages = []
            }
            s.noStore.lypPropertyImages.push(responseData)
          })
        })
    },

    onUploadPutImage: (putId, type, file) => {
      const formData = new FormData()
      // file name required by API, to correctly check and store file,
      // so for Blob's we are setting its manually
      if (file.constructor && file.constructor.name && file.constructor.name === 'Blob') {
        formData.append('image', file, file.type.replace('/', '.'))
      }
      else {
        formData.append('image', file)
      }
      formData.append('type', type)
      formData.append('file_type', 'file')
      formData.append('property_unit_type', putId)
      return requestHdApi(
        'uploadPutImageRequestInFlight',
        'POST',
        '/put-images/',
        formData
      )
        .then(responseData => {
          updateState(s => {
            if (!s.noStore.lypPutImages) {
              s.noStore.lypPutImages = []
            }
            s.noStore.lypPutImages.push(responseData)
          })
        })
    },

    onUploadIdImageToStripe: file => {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('purpose', 'identity_document')
      return fetch('https://uploads.stripe.com/v1/files', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + STRIPE_PUBLISHABLE_KEY
        },
        body: formData
      })
        .then(r => r.json())
    },

    setActiveIds: (propertyId, putId) => {
      return updateState(s => {
        if (propertyId) {
          s.noStore.activePropertyId = propertyId
        }
        if (putId) {
          s.noStore.activePutId = putId
        }
      })
    },

    refreshUserData: fetchUserProfile,
  }
  let c = class extends React.Component {
    onStateUpdate(s, preventRedirect = false) {
      if (s.noStore.rehydrated) {
        let localeLang = utils.getLocaleLang(s.locale)
        if (this.props.i18n.language === undefined) {
          this.props.i18n.changeLanguage(s.locale)
        }
        else if (localeLang.toLowerCase() !== utils.getLocaleLang(this.props.i18n.language.toLowerCase())) {
          // Mismatch between stored locale and fetched version. Refetch.
          let path = this.props.location.pathname.split('/').slice(2).join('/')
          this.props.router.push(`/${s.locale}/${path}${this.props.location.search}`)
          this.props.i18n.changeLanguage(s.locale)
        }
      }

      this.gstate = utils.deepCopy(s)
      this.forceUpdate()
      if (requireAuthentication) {
        if (this.gstate.noStore.rehydrated || PREVENT_REHYDRATE) {
          if ((!this.gstate.accessToken || this.gstate.accessToken.length <= 0) && !preventRedirect) {
            this.props.router.push(`/${s.locale}/`)
          }
        }
      }
    }

    componentWillMount() {
      this.listenerId = uuid()
      listeners()[this.listenerId] = this
      this.gstate = utils.deepCopy(state)
    }

    componentWillUnmount() {
      delete listeners()[this.listenerId]
    }

    render() {
      if (this.gstate.noStore.rehydrated) {
        return <Component {...props} {...this.props} state={this.gstate} />
      }
      else {
        //TODO: spinner?
        return <div>Loading Handiscover..</div>
      }
    }
  }
  return translate()(c)
}

function rehydrate(paramLang) {
  if (PREVENT_REHYDRATE) {
    return updateState(s => {
      s = utils.deepCopy(defaultState)
    })
  }
  else if (!state.noStore.rehydrated) {
    return localForage.getItem(LOCAL_STORAGE_KEY)
      .then(val => {
        state = Object.assign({}, defaultState, val),
          state.noStore = defaultState.noStore
        state.noStore.rehydrated = true

        if (paramLang && utils.isLanguageSupported(paramLang)) {
          state.locale = paramLang
        }
        else if (!val || !val.locale) {
          // No stored value, determine locale
          state.locale = autoDetermineLocale()
          state.default_currency = autoDetermineCurrency()
        }
        //No stored currency.
        if (!state.default_currency) {
          state.default_currency = autoDetermineCurrency()
        }

        if (isSignedIn()) {
          return fetchUserProfile()
        }
      })
      .then(responseData => {
        if (responseData === undefined) {
           // i.e. if there were no call to backend
          return
        }
        else {
          return updateState(s => {
            s.user = responseData
          })
        }
      })
      .catch(err => {
        !PRODUCTION ? console.log('rehydrate failed', err) : null
        return resetDefaultState()
          .then(() => {
            state.noStore.rehydrated = true
          })
      })
      .then(() => {
        let f = () => {
          let els = document.getElementsByClassName('device-test')
          let size = 'xs'
          if (els) {
            for (let i = 0; i < els.length; i++) {
              let el = els[i]
              let style = window.getComputedStyle(el)
              if (style.display === 'block') {
                size = el.dataset.size
                break
              }
            }
          }

          return updateState(s => {
            s.noStore.deviceSize = size
          })
        }

        window.onresize = f
        let chain = new Promise(r => r());
        if (!window.isServerSide) {
          chain = chain.then(window.onresize)
        }
        const localRehydrateListeners = rehydrateListeners();
        for (let i = 0; i < localRehydrateListeners.length; i++) {
          chain = chain.then(localRehydrateListeners[i])
        }
        chain = chain.then(() => {
          notifyListeners(state)
        })
      })
  }
}

function getStateParam(key) {
  return state[key]
}

let rehydrateListeners = makeGlobalState(() => []);
function addRehydrateListener(f) {
  rehydrateListeners().push(f)
}

export default container
export { rehydrate, getStateParam, addRehydrateListener, isSignedIn }
