import React from 'react'
import FormGenerator from '../lib/form-generator.jsx'
import utils from '../lib/utils.js'

const resetGen = new FormGenerator('Reset password')
resetGen.addField({name: 'password', placeholder: 'Password', infoBubbleText: utils.passwordDescription, errorBubbleText: utils.passwordDescription, isPassword: true})
resetGen.addField({name: 'confirmPassword', placeholder: 'Confirm password', isPassword: true, infoBubbleText: 'Enter your password again', errorBubbleText: 'Passwords do not match'})
resetGen.addValidator(utils.isValidPassword, null, 'password')
resetGen.addValidator(utils.isEqualStrings, null, 'password', 'confirmPassword')
const ResetForm = resetGen.generate()

class PasswordResetPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showSuccess: false,
      showErrors: []
    }
  }

  onSubmit(formFields){
    const key = this.props.location.query.key
    if (key) {
      this.props.onPasswordReset(key, formFields.password.value)
        .then(() => {
          // Show success info, link to login
          this.setState({
            showSuccess: true,
            showErrors: []
          })
        })
        .catch(e => {
          // Show fail info
          if (e && e.password && Array.isArray(e.password) && e.password.length > 0){
            this.setState({
              showErrors: e.password
            });
          }
        })
    }
  }

  render(){
    var content = null
    if (this.state.showSuccess) {
      content = (
        <div>
          <h1>Your password has been reset!</h1>
          <p>Please proceed by <a onClick={this.props.onShowSignupLoginModal.bind(null)}>logging in.</a></p>
        </div>
      )
    }
    else {
      content = (
        <ResetForm
          onSubmit={this.onSubmit.bind(this)}
        />
      )
    }

    const errors = !this.state.showErrors.length ? null : this.state.showErrors.map((elem, key) => {
      return (
        <div className="error-text" key={key}>
          {elem}
        </div>
      )
    })

    return (
      <div className='confirmations-page'>
        {content}
        {errors}
      </div>
    )
  }
}

export default PasswordResetPage
