import React from 'react'
import PropTypes from 'prop-types'

class InputField extends React.PureComponent {
  constructor(props){
    super(props)
    this.state = {
      showInfoBubble: false,
      showInfoBox: false,
      showErrorBox:true,
      value: '',
    }

    this.onMouseOverInfoBubble = this.onMouseOverInfoBubble.bind(this)
    this.onMouseOutInfoBubble = this.onMouseOutInfoBubble.bind(this)
    this.onMouseOverErrorBubble = this.onMouseOverErrorBubble.bind(this)
    this.onMouseOutErrorBubble = this.onMouseOutErrorBubble.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
    this.createRef = this.createRef.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  blur(){
    if (this.input) {
      this.input.blur()
    }
  }

  getValue(){
    return this.state.value
  }

  clear(){
    this.setState({value: ''})
  }

  onBlur(){
    this.setState({showInfoBubble: false, showInfoBox: false})
    if (this.props.onBlur) {
      this.props.onBlur()
    }
  }

  onChange(e){
    this.setState({value: e.target.value})
    if (this.props.onChange) {
      this.props.onChange(e.target.value)
    }
  }

  onClick(){
    if(this.props.onClick){
      this.props.onClick()
    }
  }

  onFocus(){
    this.setState({showInfoBubble: true})
    if (this.props.onFocus) {
      this.props.onFocus()
    }
  }

  onKeyPress(e){
    if (e.key === 'Enter' && this.props.onEnter) {
      if (this.props.onEnter) {
        this.props.onEnter(e.target.value)
      }
    }
  }

  onMouseOverInfoBubble(){
    this.setState({showInfoBox: true})
  }

  onMouseOutInfoBubble(){
    this.setState({showInfoBox: false})
  }

  onMouseOverErrorBubble(){
    this.setState({showErrorBox: true})
  }

  onMouseOutErrorBubble(){
    this.setState({showErrorBox: false})
  }

  createRef(input){
    if (input) {
      this.input = input
    }
  }

  render(){
    let type = this.props.type? this.props.type: 'text'
    if (this.props.password) {
      type = 'password'
    }

    let inputClassName = this.props.inputClassName || ''
    if (this.props.showError) {
      inputClassName += ' err'
    }

    let info = null
    if (this.props.infoBubbleText && this.state.showInfoBubble) {
      info = (
        <div>
          <div className='info-bubble'
            onMouseOver={this.onMouseOverInfoBubble}
            onMouseOut={this.onMouseOutInfoBubble}></div>
          {
            this.state.showInfoBox ?
              <div className='info-box'>{this.props.infoBubbleText}</div>
              : null
          }
        </div>
      )
    }

    let errorBubble = null
    if (this.props.errorBubbleText && this.props.showError) {
      errorBubble = (
        <div>
          <div className='error-bubble'
            onMouseOver={this.onMouseOverErrorBubble}
            onMouseOut={this.onMouseOutErrorBubble}></div>
          {
            this.state.showErrorBox ?
              <div className='error-box'>{this.props.errorBubbleText}</div>
              : null
          }
        </div>
      )
    }

    let wrapperClass = 'input-field-wrapper'
    if (this.props.wrapperClass) {
      wrapperClass += ' ' + this.props.wrapperClass
    }

    let preText = null
    if (this.props.preText) {
      preText = <div className='pre-text'>{this.props.preText}</div>
    }

    return (
      <div className={wrapperClass} style={this.props.style ? this.props.style : null}>
        <input
          autoComplete={this.props.autoComplete || 'off'}
          className={inputClassName}
          disabled={this.props.disabled}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onClick={this.onClick}
          onFocus={this.onFocus}
          onKeyPress={this.onKeyPress}
          placeholder={this.props.placeholder}
          ref={this.createRef}
          type={type}
          value={this.props.value}
          readOnly={this.props.readOnly}
          style={this.props.inputStyle ? this.props.inputStyle : null}
          />
        {info}
        {errorBubble}
        {preText}
      </div>
    )
  }

  setFocus(){
    this.input.focus()
  }
}

// InputField.propTypes = {
//   showError: React.PropTypes.bool,
//   password: React.PropTypes.bool,
//   className: React.PropTypes.string,
//   onEnter: React.PropTypes.func,
//   infoBubbleText: React.PropTypes.string,
//   preText: React.PropTypes.string,
// }

class PasswordField extends React.Component {
  render(){
    var type = 'text'
    if (this.props.password) {
      type = 'password'
    } else if (this.props.number){
      type = 'number'
    }
    return (
      <InputField
        {...this.props}
        password={true}
        />
    )
  }
}

export default InputField
export {InputField, PasswordField}
