import React from 'react'
import SignupLoginModal from './signup-login-modal.jsx'


/**
 * @template T
 * @extends {React.Component<T>}
 */
export default class ComponentWithLogin extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      signupLoginModalOpen: false,
      showLogin: false,
      needRedirectAfterLogin: false,
    }
  }
  onCloseSignupLoginModal(){
    this.setState({
      signupLoginModalOpen: false
    }, () => {
      this.props.closeLoginSignupModal()
      if (this.props.isSignedIn() && this.state.needRedirectAfterLogin && this.props.router) {
        this.props.router.push(this.state.needRedirectAfterLogin)
      }
      this.setState({
        needRedirectAfterLogin: false
      })
    })
  }

  onOpenSignupModal(modalSource){
    this.setState({
      signupLoginModalOpen: true,
      showLogin: false,
    })
  }

  onOpenLoginModal(modalSource, needRedirectAfterLogin=false){
    this.setState({
      signupLoginModalOpen: true,
      showLogin: true,
      needRedirectAfterLogin
    })
  }

  onSignOut(){
    const { routes = [] } = this.props
    const componentName = (typeof(routes[routes.length - 1]) !== 'undefined') ? routes[routes.length - 1].getComponent.name : ''
    this.props.onSignout()
    const stayList = ['getLandingPageChunk','getLandingHotelsPageChunk','getLandingApartmentsPageChunk','getLandingBBPageChunk']
    if(!stayList.includes(componentName)) {
      this.props.router.push(`/${this.props.state.locale}/`)
    }
  }

  onClickListYourProperty(){
    const url = `/${this.props.state.locale}/list-your-property/`
    if (!this.props.isSignedIn()) {
      this.onOpenLoginModal(null, url)
    }
    else {
      this.props.router.push(url)
    }
  }

  renderLoginModal (){
    return (
      <div>
        <SignupLoginModal
          ref={signup => {
            this.signupModal = signup
          }}
          {...this.props}
          isOpen={this.state.signupLoginModalOpen || this.props.state.signupLoginModalOpen}
          onRequestClose={this.onCloseSignupLoginModal.bind(this)}
          showLogin={this.state.showLogin}
          onToggleLoginClick={() => this.setState({showLogin: !this.state.showLogin})}
        />
      </div>
    )
  }

  render() {
    return(
      this.renderLoginModal()
    )
  }

}
