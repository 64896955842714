import React from 'react'
import SelectDropdown from './select-dropdown.jsx'
import ComponentWithLogin from './component-with-login.jsx'
import currencyFormatter from 'currency-formatter'
import utils from '../lib/utils.js'
import { withTranslation as translate } from 'react-i18next'
import { List } from 'immutable'

const currencyOptions = new List(currencyFormatter.currencies.map(currency => {
  return {
    label: currency.code,
    value: currency.code
  }
}))

const languageOptions = new List(utils.getSupportedLanguages().map(lang => {
  return {
    label: lang.label,
    value: lang.locale
  }
}))

class Footer extends ComponentWithLogin {
  constructor(props) {
    super(props)
    this.state = {
      signupLoginModalOpen: false,
      showLogin: false,
    }

    this.onChangeCurrency = this.onChangeCurrency.bind(this)
    this.onChangeLocale = this.onChangeLocale.bind(this)
    this.onClickInternalLink = this.onClickInternalLink.bind(this)
  }

  onChangeCurrency(currency) {
    this.props.onSetDefaultCurrency(currency)
  }

  onChangeLocale(locale) {
    if (this.props.state.user) {
      const lang = locale.slice(0, 2) === 'nn' ? 'no' : locale.slice(0, 2);
      this.props.onUpdateProfile({ language_code: lang });
    }
    this.props.onSetLocale(locale);
  }

  onClickInternalLink(path) {
    // Very ugly temporary 'fix' to scroll up after
    // going to page. Should do this in some navigation event handler instead
    setTimeout(() => window.scroll(0, 0), 100)
  }

  render() {
    const _t = this.props.t
    return (
      <div className='footer'>
        <div className='footer-second-part'>
          <div className='columns row'>
            <div className='col-md-2 col-sm-4 col-xs-6'>
              <ul className='footer-dropdowns'>
                {/*<li>*/}
                {/*  <SelectDropdown*/}
                {/*    onChange={this.onChangeCurrency}*/}
                {/*    options={currencyOptions}*/}
                {/*    placeholder='Currency'*/}
                {/*    value={this.props.state.default_currency || 'USD'}*/}
                {/*    footerLabel={'currency'}*/}
                {/*  />*/}
                {/*</li>*/}
                <li>
                  <SelectDropdown
                    onChange={this.onChangeLocale}
                    options={languageOptions}
                    placeholder='Language'
                    value={this.props.state.locale}
                    footerLabel={'language'}
                  />
                </li>
              </ul>
            </div>
            <div className='col-md-2 col-sm-4 col-xs-6'>
              <h3>Handiscover</h3>
              <ul>
                <li><a href={`/${this.props.state.locale}/about/`} onClick={this.onClickInternalLink}>{_t('footer_menu_about')}</a></li>
                <li><a href={`/${this.props.state.locale}/content/results/press-releases`}>{_t('footer_menu_press')}</a></li>
                {/*<li><a href='https://careers.handiscover.com/' target='_blank'>{_t('menu_jobs')}</a></li>*/}
                <li><a href='https://accessibility.handiscover.com/' target='_blank'>Accessibility Management</a></li>
              </ul>
            </div>
            <div className='col-md-2 col-sm-4 col-xs-6'>
              <h3>{_t('footer_discover')}</h3>
              <ul>
                <li><a href={`/${this.props.state.locale}/content/home`} target='_blank'>{_t('menu_content')}</a></li>
                <li><a href='mailto:contact@handiscover.com'>{_t('general_email')}</a></li>
                <li><a href={`/${this.props.state.locale}/content/home`} target='_blank'>{_t('footer_our_blog')}</a></li>
                <li><a href={`/${this.props.state.locale}/content/home`} target='_blank'>{_t('footer_destination_guides')}</a></li>
                <li><a href={`/${this.props.state.locale}/content/page/social-media`}>{_t('footer_social_media')}</a></li>
              </ul>
            </div>
            <div className='col-md-2 col-sm-4 col-xs-6'>
              <ul className="footer-without-heading">
                <li><a href={`/${this.props.state.locale}/content/results/travel-info`} target='_blank'>{_t('footer_travel_tips')}</a></li>
                <li><a href={`/${this.props.state.locale}/content/home`} target='_blank'>{_t('footer_e_books')}</a></li>
                <li><a href={`/${this.props.state.locale}/content/results/ambassadors/`} target='_blank'>{_t('footer_ambassadors')}</a></li>
              </ul>
            </div>
            <div className='col-md-2 col-sm-4 col-xs-6'>
              <h3>{_t('footer_help')}</h3>
              <ul>
                <li><a href={'https://handiscover.zendesk.com/hc/en-us/categories/200849545-FAQ-for-Guests'} target='_blank'>{_t('footer_FAQs')}</a></li>
                <li><a href={`/${this.props.state.locale}/howItWorks`} target='blank'>{_t('landing_page_how_header')}</a></li>
                <li><a href={`/${this.props.state.locale}/finding_booking_accessible_accommodation`} target='blank'>How to book with Handiscover</a></li>
                <li><a href={`/${this.props.state.locale}/best-price-guarantee`} target='blank'>Best price guarantee</a></li>
                <li><a href={`/${this.props.state.locale}/terms/`} onClick={this.onClickInternalLink}>{_t('menu_terms')}</a></li>
                <li><a href={`/${this.props.state.locale}/privacy-policy/`} onClick={this.onClickInternalLink}>{_t('menu_policies')}</a></li>
                <li><a href='mailto:contact@handiscover.com'>{_t('footer_contact_us')}</a></li>
                {this.props.state.user ? <li><a href={`/${this.props.state.locale}/gdpr/`} onClick={this.onClickInternalLink}>GDPR Report</a></li> : null}
              </ul>
            </div>
            <div className='col-md-2 col-sm-4 col-xs-6'>
              <h3>{_t('footer_partner_with_us')}</h3>
              <ul>
                <li><a onClick={this.onClickListYourProperty.bind(this)} id="become-host-anchors">{_t('footer_become_a_host')}</a></li>
                <li><a href={`/${this.props.state.locale}/content/affiliate/`}>{_t('footer_affiliate')}</a></li>
                <li><a href={`/${this.props.state.locale}/content/become-a-handiscover-travel-blogger/`}>{_t('footer_become_a_travel_blogger')}</a></li>
                <li><a href={`/${this.props.state.locale}/content/widgets-and-downloads/`} target='_blank'>{_t('footer_widgets_and_downloads')}</a></li>
              </ul>
            </div>
          </div>

          <div className='footer-bottom'>
            <div className="footer-social-links">
              <a href='https://www.facebook.com/handiscover' target='_blank' className="social-link fb" aria-label="facebook"></a>
              <a href='https://twitter.com/Handiscovercom' target='_blank' className="social-link tw" aria-label="twitter"></a>
              <a href='https://www.instagram.com/handiscoverworld/' target='_blank' className="social-link in" aria-label="instagram"></a>
              <a href='https://www.linkedin.com/company/handiscover-ltd/' target='_blank' className="social-link li" aria-label="linkedin"></a>
              <a href='https://www.pinterest.se/handiscover/pins/' target='_blank' className="social-link pin" aria-label="pinterest"></a>
              <a href='https://vk.com/handiscover' target='_blank' className="social-link vk" aria-label="vk"></a>
            </div>
            <div className='copyright'>
              © Handiscover Sweden AB. 2021
            </div>
          </div>
        </div>
        {this.renderLoginModal()}
      </div>
    )
  }
}

export default translate()(Footer)
