import React from "react";
import { LazyRoute } from "./custom-routes";

const home = [
    "united-kingdom/england/london/",
    "france/liddle-franke/paris/",
    "france/alpes-maritimes/nice/",
    "france/liddle-franke/paris/",
    "france/paris/",
    "germany/berlin/",
    "ireland/dublin",
    "italy/rome",
    "italy/tuscany/florence",
    "italy/veneto/venice",
    "netherlands/amsterdam/",
    "portugal/lisbon",
    "portugal/porto",
    "spain/barcelona/",
    "spain/canary-islands/tenerife/",
    "spain/madrid/",
    "spain/Majorca",
    "south-africa/cape-town/",
    "united-kingdom/england/blackpool/",
    "united-kingdom/england/ceredigion/",
    "united-kingdom/england/cornwall/",
    "united-kingdom/england/devon/",
    "united-kingdom/england/dorset/",
    "united-kingdom/england/london/",
    "united-kingdom/england/norfolk",
    "united-kingdom/england/yorkshire",
].map(path => ({ path, getComponent: () => import('../landings/landing')}));

const apartments = [
    "france/alpes-maritimes/nice/apartments",
    "france/paris/apartments",
    "germany/berlin/apartments",
    "ireland/dublin/apartments",
    "italy/rome/apartments",
    "italy/tuscany/florence/apartments",
    "italy/veneto/venice/apartments",
    "netherlands/amsterdam/apartments",
    "portugal/lisbon/apartments",
    "portugal/porto/apartments",
    "spain/barcelona/apartments",
    "spain/canary-islands/tenerife/apartments",
    "spain/majorca/apartments",
    "spain/madrid/apartments",
    "south-africa/cape-town/apartments",
    "united-kingdom/england/blackpool/apartments/",
    "united-kingdom/england/ceredigion/apartments/",
    "united-kingdom/england/cornwall/apartments/",
    "united-kingdom/england/devon/apartments/",
    "united-kingdom/england/dorset/apartments/",
    "united-kingdom/england/london/apartments/",
    "united-kingdom/england/norfolk/apartments/",
    "united-kingdom/england/yorkshire/apartments/",
].map(path => ({ path, getComponent: () => import('../landings/apartments-landing')}));

{/*Routes For The B&B Landings*/}
const bedAndBreakfast = [
    "france/alpes-maritimes/nice/bed-and-breakfast",
    "france/paris/bed-and-breakfast",
    "germany/berlin/bed-and-breakfast",
    "ireland/dublin/bed-and-breakfast",
    "italy/rome/bed-and-breakfast",
    "italy/tuscany/florence/bed-and-breakfast",
    "italy/veneto/venice/bed-and-breakfast",
    "netherlands/amsterdam/bed-and-breakfast",
    "portugal/lisbon/bed-and-breakfast",
    "portugal/porto/bed-and-breakfast",
    "south-africa/cape-town/bed-and-breakfast",
    "spain/barcelona/bed-and-breakfast",
    "spain/canary-islands/tenerife/bed-and-breakfast",
    "spain/majorca/bed-and-breakfast",
    "spain/madrid/bed-and-breakfast",
    "united-kingdom/england/blackpool/bed-and-breakfast/",
    "united-kingdom/england/ceredigion/bed-and-breakfast/",
    "united-kingdom/england/cornwall/bed-and-breakfast/",
    "united-kingdom/england/devon/bed-and-breakfast/",
    "united-kingdom/england/dorset/bed-and-breakfast/",
    "united-kingdom/england/london/bed-and-breakfast/",
    "united-kingdom/england/norfolk/bed-and-breakfast/",
    "united-kingdom/england/yorkshire/bed-and-breakfast/",
].map(path => ({ path, getComponent: () => import('../landings/bb-landing')}));

{/*Routes For The Hotels Landings*/}
const hotels = [
    "france/alpes-maritimes/nice/hotels",
    "france/paris/hotels",
    "germany/berlin/hotels",
    "ireland/dublin/hotels",
    "italy/rome/hotels",
    "italy/tuscany/florence/hotels",
    "italy/veneto/venice/hotels",
    "netherlands/amsterdam/hotels",
    "portugal/lisbon/hotels",
    "portugal/porto/hotels",
    "spain/barcelona/hotels",
    "spain/canary-islands/tenerife/hotels",
    "spain/madrid/hotels",
    "spain/majorca/hotels",
    "south-africa/cape-town/hotels",
    "united-kingdom/england/blackpool/hotels",
    "united-kingdom/england/ceredigion/hotels",
    "united-kingdom/england/cornwall/hotels",
    "united-kingdom/england/devon/hotels",
    "united-kingdom/england/dorset/hotels",
    "united-kingdom/england/london/hotels",
    "united-kingdom/england/norfolk/hotels",
    "united-kingdom/england/yorkshire/hotels",
].map(path => ({ path, getComponent: () => import('../landings/hotels-landing')}));

export const locationRoutes = [...apartments, ...bedAndBreakfast, ...hotels, ...home]
    .map(({path, getComponent}) => <LazyRoute path={`/:lang/${path}`} exact legacy getComponent={getComponent} key={path} />)