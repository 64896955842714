import React, { PropsWithChildren, useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { MobileMenuContext, useMediaSize } from './menu';
export function MenuButton(props: React.ComponentProps<typeof Link> & {
    showDropDownArrow: boolean;
    name: string
}) {
    const { isOpen, setIsOpen } = useContext(MobileMenuContext)
    const { name, children, showDropDownArrow, key, ...rest } = props;
    const [buttonArrowState, setButtonArrowState] = useState(false);
    const handleArrowClick = () => setButtonArrowState(!buttonArrowState);
    const { mobileHeaderMenu } = useMediaSize()
    useEffect(() => {
        if (!isOpen && mobileHeaderMenu) {
            setButtonArrowState(false)
        }
    })
    return <div className={`menu-checkbox-wrapper id-${rest.id}`} onClick={handleArrowClick} >
        <Link id={rest.id} className={rest.className} {...rest} style={{ outline: "none" }} >
            <div className={'button-container'} >
                {name}
            </div>
            <div className={`drop-down-arrow ${buttonArrowState ? "menu-show" : "menu-hide"}`}>
                {showDropDownArrow ? <img id="travelGuidesArrow" src={require('../../../../client-src/images/form-elements/arrow.png')}
                    alt="dropdown arrow" width="10px" height="auto" /> : <div></div>}
            </div>
        </Link>
        <div className={`menu-bar-dropdown-container ${buttonArrowState ? "menu-show" : "menu-hide"}`} >
            {children}
        </div>
    </div >
}