import React, { useState, useEffect, useContext} from 'react'
import { useParams, useLocation as useLocationRouter, useHistory as useHistoryRouter } from 'react-router-dom';

export async function delay(ms: number) {
    return new Promise<void>((r) => setTimeout(r, ms));
}

export function useEffectAsync(fn: () => Promise<void>, deps: any[]) {
    useEffect(() => {
        fn()
    }, deps);
}

export function useHistory() {
    return useHistoryRouter();
}
export function useParameters<T>():T {
    return useParams<T>()
}

export function useLocation() {
    return useLocationRouter()
}

export interface InjectedContext {
    state: AppState
    t: (v: string) => string
    location: ReturnType<typeof useLocation>
}

export interface AppState {
    userId?: string,
    isLoggedIn?: boolean,
    user?: {
        last_search?: {
            location?: string,
            mobility_class_old_min?: string,
            start_date?: string,
            end_date?: string
        }
    }
}

export interface AppServices {
    onSetLocale(locale: string): void;
}

export const  AppServicesContext  = React.createContext<AppServices>(null!);
export function useAppServices() {
    return useContext(AppServicesContext);
}

export const  AppStateContext  = React.createContext<AppState>({ });
export function useAppState() {
    return useContext(AppStateContext);
}

export const TranslationContext = React.createContext<{ t: (v: string) => string }>({ t: v=> v });

export function useTranslation() {
    return useContext(TranslationContext);
}