/** NOTE: This component requires google places api to have been loaded in window.google */
import React from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import InputField from './input-field.jsx'
import PropTypes from 'prop-types'
import { toGeoJson } from '../lib/google-helpers'
import PropertiesAutocomplete from './properties-autocomplete.jsx'

class GoogleInputField extends React.PureComponent {
  constructor(props) {
    super(props)

    this.onEnter = this.onEnter.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  onChange(newVal) {
    if (this.props.onChange) {
      this.props.onChange(newVal)
    }
  }

  onEnter(term) {
    let val = this.props.value
    if (term !== undefined) {
      val = term
      this.onChange(term)
    }
    
    geocodeByAddress(val)
      .then(results => {
        const geoJson = toGeoJson(results)
        if (this.props.onReceivedSearchResults) {
          this.props.onReceivedSearchResults(val, geoJson.features)
        }
      })
      .catch(error => console.error('Error', error))
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  handleFocus(e){
    e.target.select()
  }
  render() {
    if (window.google) {
      if (this.props.readOnly) {
        return (
          <InputField
            onClick={this.onClick}
            placeholder={this.props.placeholder}
            value={this.props.value}
            readOnly={this.props.readOnly}
          />
        )
      }

      const classNames = {
        root: 'google-input-field',
        input: 'g-input',
        autocompleteContainer: 'autocomplete-container'
      }
      if (this.props.showError) {
        classNames.input += ' err'
      }

      const options = {}
      if (this.props.addressOnly) {
        options.types = ['address']
      }

      const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
        <div
          onClick={this.onClick}
          className="google-input-field"
          onFocus={this.props.hideInBlog}
          >
          <input className={'g-input'}{...getInputProps()}
            onFocus={this.handleFocus}
            placeholder={this.props.placeholder}/>
          <div
            className={(suggestions && suggestions.length > 0) &&
             (this.props.value && this.props.value.length > 2)?'autocomplete-container': 'autocomplete-container'}>
            {this.props.addressOnly? null:
              <PropertiesAutocomplete
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                value={this.props.value}
                locale={this.props.locale}
              />
            }
            {this.props.value && this.props.value.length > 2?
              <div className={'google-items-list-wrapper'}>
                {suggestions.length > 0 && suggestions[0].types[0] !== 'lodging'
             && suggestions[0].types[0] !=='geocode'?<div className={'search-category-title city-modif'}>{'City/Area'}</div>: null}
                {suggestions.map((suggestion, idx) => (
                  <div {...getSuggestionItemProps(suggestion)}
                    id={suggestion.id}
                    key={idx}
                     className={suggestion.types[0] === 'locality'
                     || suggestion.types[0] ==='country'
                     || suggestion.types[0] ==='administrative_area_level_1'
                     || suggestion.types[0] ==='administrative_area_level_2'
                     || suggestion.types[0] ==='administrative_area_level_3'
                     || suggestion.types[0] ==='administrative_area_level_4'
                     || suggestion.types[0] ==='administrative_area_level_5'
                     || suggestion.types[0] ==='neighborhood'
                     || suggestion.types[0] ==='sublocality_level_1'
                     || suggestion.types[0] ==='sublocality_level_2'
                     || suggestion.types[0] ==='sublocality_level_3'
                     || suggestion.types[0] ==='sublocality_level_4'
                     || suggestion.types[0] ==='sublocality_level_5'
                     || suggestion.types[0] ==='street_address'
                     || suggestion.types[0] ==='route'
                     || suggestion.types[0] ==='street_number'
                     || suggestion.types[0] ==='route'
                     || suggestion.types[0] ==='route'
                     || suggestion.types[1] ==='establishment'
                     || suggestion.types[0] ==='premise'
                     || suggestion.types[0] ==='neighborhood' ? 'google-item-list':  'hide'}
                   >
                    <span className={'properties_autocomplete'}>{suggestion.description}</span>
                  </div>
                ))}
              </div>: null
            }

          </div>
        </div>
      )

      return (
        <PlacesAutocomplete
          value={this.props.value ? this.props.value : ""}
          onChange={this.onChange}
          onEnterKeyDown={this.onEnter}
          onSelect={this.onEnter}
          onError={console.log}
        >
          {renderFunc}
        </PlacesAutocomplete>
      )
    } else {
      // console.log('Google not loaded')
      return null
    }
  }
}

GoogleInputField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  onReceivedSearchResults: PropTypes.func,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  addressOnly: PropTypes.bool,
  showError: PropTypes.bool
}

export default GoogleInputField
