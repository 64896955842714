import React, { useState, useEffect, useContext, useMemo } from 'react'
import { MenuButton } from './menu-button'
import { useHistory, useParameters, useLocation, useAppServices, useTranslation } from '../../../utils';
import { useData, useDataUnique } from '../../../hooks/async-hooks'
import { getCategories, getPagesHeaders } from '../../../services/api'
import { Link } from 'react-router-dom'
import utils, { SysLanguage } from '../../../lib/utils'
import { blogAllResults, blogCategoryUrl, blogUrl, PostCollections } from '../utils'
import { func } from 'prop-types'
import { LanguageEntry } from '../../../models/LanguageEntry';
import { SearchBar } from './auto-complete';

type MenuLanguageEntry = SysLanguage & {
    url: string
}
export type MobileMenuContext = {
    menuList: React.ReactNode[],
    isOpen: boolean,
    setMenuList: (value: React.ReactNode[]) => void,
    setIsOpen: (value: boolean) => void
    supportedLanguages: MenuLanguageEntry[] | null,
    setSupportedLanguages(value: MenuLanguageEntry[] | null): void;
}
export const MobileMenuContext = React.createContext<MobileMenuContext>({
    menuList: [], isOpen: false, setMenuList: () => { }, setIsOpen: () => { },
    supportedLanguages: null,
    setSupportedLanguages: () => { }
})

export function useSupportedLanguage(collection: PostCollections, post: { slug?: string, id: number, languages?: LanguageEntry[] }) {
    const { setSupportedLanguages } = useContext(MobileMenuContext);
    useEffect(() => {
        const systemLanguages = utils.getSupportedLanguages();
        setSupportedLanguages(post.languages?.map(le => {
            const sysLang = systemLanguages.find(sysl => le.languageCode === sysl.code)!
            return {
                url: blogUrl(sysLang.locale, post, collection),
                ...sysLang
            };
        }) ?? null);
        return () => setSupportedLanguages(null);
    }, [post]);
}

export function MobileMenuContextProvider(p: { children: React.ReactNode }) {
    const [menuList, setMenuList] = useState<React.ReactNode[]>([])
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [supportedLanguages, setSupportedLanguages] = useState<MenuLanguageEntry[] | null>(null)
    return <MobileMenuContext.Provider value={{ menuList, setMenuList, isOpen, setIsOpen, setSupportedLanguages, supportedLanguages }}>
        {p.children}
    </MobileMenuContext.Provider>
}

export function useMediaSize() {
    type Sizes = { web: boolean, smallView: boolean, mobile: boolean, mobileHeaderMenu: boolean }
    const [windowSizes, setWindowSizes] = useState<Sizes>({ web: window.innerWidth > 1215, smallView: window.innerWidth < 255, mobile: window.innerWidth < 970, mobileHeaderMenu: window.innerWidth < 775 })
    useEffect(() => {
        setWindowSizes({ web: window.innerWidth > 1215, smallView: window.innerWidth < 255, mobile: window.innerWidth < 970, mobileHeaderMenu: window.innerWidth < 1025 });
    }, [window.innerWidth]);
    return windowSizes
}
export function MenuBar() {
    const [margin, setMargin] = useState(0)
    const { t } = useTranslation();
    const { isOpen, setIsOpen, supportedLanguages } = useContext(MobileMenuContext)
    const { web, smallView, mobile, mobileHeaderMenu } = useMediaSize()
    const [displayChange, setDisplayChanged] = useState(false)
    const [searchBox, setSearchBox] = useState(false)
    const history = useHistory()
    const routeParams = useParameters<{
        searchType?: string,
        searchText?: string,
        lang: string,
    }>()
    let location = useLocation();
    const { onSetLocale } = useAppServices();
    const handleButtonClick = () => {
        if (mobileHeaderMenu && isOpen) {
            setIsOpen(false)
        }
    }
    useEffect(() => {
        if (routeParams.searchType == "text" && routeParams.searchText) {
            setSearchParam(routeParams.searchText);
        }
    }, [routeParams.searchText, routeParams.searchType])
    const { data: travelGuides } = useDataUnique(() => getCategories({ parent: "travel-guides", lang: 'en' }), [routeParams.lang]);
    const [searchParam, setSearchParam] = useState("")
    const language = useMemo(() => {
        const systemLanguages = utils.getSupportedLanguages();
        return systemLanguages.map(l => ({ ...l, url: replaceLang(l.locale) }));
    }, [supportedLanguages]);
    const router = useHistory();
    function replaceLang(newLang: string) {
        let tokens = location.pathname.split('/');
        tokens[1] = newLang;
        return tokens.join('/');
    }
    const { data: moreInfo } = useData(() => getPagesHeaders({ include: [13788, 13403, 13479, 12832] }));
    const { setMenuList } = useContext(MobileMenuContext)

    useEffect(() => {
        displayChange ? setMargin(251) : setMargin(0)
    }, [displayChange, mobile, web])
    useEffect(() => {
        if (location.pathname === `/${routeParams.lang}/content/`) {
            history.push(`/${routeParams.lang}/content/home`)
        }
    }, [location.pathname])
    useEffect(() => {
        setMenuList(mobileHeaderMenu ? [
            <div className="mobile inline">
                {makeButtons({ buttonClass: "button-inline", dropdownClass: "menu-bar-dropdown-inline", dropdownButtonClass: "menu-bar-dropdown-button-inline" })}
            </div>
        ] : []);
    }, [mobileHeaderMenu, travelGuides, searchParam, isOpen]);
    function makeButtons({
        buttonClass = "button",
        dropdownClass = "menu-bar-dropdown",
        dropdownButtonClass = "menu-bar-dropdown-button" }: {
            buttonClass?: string;
            dropdownClass?: string
            dropdownButtonClass?: string
        }) {

        return [
            mobileHeaderMenu ? <div className={buttonClass} style={{ paddingLeft: 0 }} key="search-bar-text">
                <input type="text" autoFocus placeholder={t("search-bar-text")} style={{ padding: 0, margin: 0, height: 35 }}
                    value={searchParam}
                    onChange={(event) => { setSearchParam(event.target.value) }}
                    onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                            history.push(`/${routeParams.lang}/content/results/all/text/${encodeURIComponent(searchParam)}`);
                            e.currentTarget.blur()
                            if (mobileHeaderMenu && isOpen) {
                                setIsOpen(false)
                            }
                        }
                    }} />

            </div> : <div key="search-bar-text"></div>,
            <MenuButton name={t("menu_blog")} key="menu_blog" className={buttonClass} showDropDownArrow={false}
                to={blogCategoryUrl(routeParams.lang, 'blog')} onClick={handleButtonClick}>
            </MenuButton>,
            <MenuButton name={t("menu_travel_guides")} key="menu_travel_guides" id="menuTravelGuides"
                className={buttonClass} showDropDownArrow={true} to="#">
                <div className={dropdownClass} >
                    {travelGuides.map(e =>
                        <Link className={`${dropdownButtonClass} short`} dangerouslySetInnerHTML={{ __html: e.name }}
                            to={blogCategoryUrl(routeParams.lang, e.slug, 'destination')}
                            style={{ outline: "none" }} key={e.name}
                            onClick={handleButtonClick}></Link>)}
                </div>
            </MenuButton>,
            <MenuButton name={t("menu_podcast")} key="menu_podcast" className={buttonClass} showDropDownArrow={false}
                to={blogCategoryUrl(routeParams.lang, 'podcast')} onClick={handleButtonClick}>
            </MenuButton>,
            <MenuButton name={t("menu_travel_info")} key="menu_travel_info" id="menuTravelInfo" className={buttonClass} showDropDownArrow={false}
                to={blogAllResults(routeParams.lang, 'travel-info')} onClick={handleButtonClick}>
            </MenuButton >,
            <MenuButton name={t("menu_ebooks")} key="menu_ebooks" className={buttonClass} showDropDownArrow={false}
                to={blogAllResults(routeParams.lang, 'ebook')} onClick={handleButtonClick}>
            </MenuButton>,
            <MenuButton name={t("menu_more_info")} key="menu_more_info" id="menuMoreInfo" className={buttonClass} showDropDownArrow={true} to="#">
                <div className={dropdownClass} >
                    {moreInfo.map(e =>
                        <Link className={`${dropdownButtonClass} short`} style={{ outline: "none" }} key={e.id}
                            to={`/${routeParams.lang}/content/page/${e.slug}`} onClick={handleButtonClick}>
                            <span dangerouslySetInnerHTML={{ __html: e.title?.rendered ?? "" }}></span>
                        </Link>)}
                    <Link className={`${dropdownButtonClass} short`} style={{ outline: "none" }} key="footer_ambassadors"
                        to={`/${routeParams.lang}/content/results/ambassadors`} onClick={handleButtonClick}>
                        {t('footer_ambassadors')}
                    </Link>
                    <a className={`${dropdownButtonClass} short`} style={{ outline: "none" }} key="menu_menu_policies"
                        target="_blank"
                        href={`${HOST_URL}/${routeParams.lang}/privacy-policy`} onClick={handleButtonClick}>
                        {t('menu_policies')}
                    </a>
                    <a className={`${dropdownButtonClass} short`} style={{ outline: "none" }} key="menu_terms"
                        target="_blank"
                        href={`${HOST_URL}/${routeParams.lang}/terms`} onClick={handleButtonClick}>
                        {t('menu_terms')}
                    </a>
                    <a className={`${dropdownButtonClass} short`} style={{ outline: "none" }} key="landing_page_how_header"
                        target="_blank"
                        href={`${HOST_URL}/${routeParams.lang}/howItWorks`} onClick={handleButtonClick}>
                        {t('landing_page_how_header')}
                    </a>
                    <a className={`${dropdownButtonClass} short`} style={{ outline: "none" }} key="footer_FAQs"
                        target="_blank"
                        href={`https://handiscover.zendesk.com/hc/${routeParams.lang}`} onClick={handleButtonClick}>
                        {t('footer_FAQs')}
                    </a>

                </div>
            </MenuButton>,
            <MenuButton name={t("menu_language")} key="menu_language" className={buttonClass} showDropDownArrow={true} to="#">
                <div className={`${dropdownClass}`} >
                    {language.map(e => <Link
                        className={`${dropdownButtonClass} short`} key={e.code}
                        onClick={(p) => {
                            p.preventDefault();
                            onSetLocale(e.locale);
                            handleButtonClick();
                            router.push({
                                pathname: e.url,
                                state: {
                                    noResetScroll: true
                                }
                            });;
                        }}
                        style={{ outline: "none" }} to={e.url}>{e.label}</Link>)}
                </div>
            </MenuButton>
        ]
    }
    function desktopVersion() {
        return <div className="menu-wrapper web">
            <div className="menu-bar">
                <Link to={`/${routeParams.lang}/content/home`}>
                    <img src={require('../../../images/menu-bar/logoHandiscover.png')}
                        alt="handiscover logo" className="menu-image" />
                </Link>
                <div className="button-bar">
                    {searchBox ? <div>
                        <SearchBar />
                    </div> : ""}
                    <img src={require('../../../images/menu-bar/search_right_white.png')}
                        alt="search icon" onClick={() => setSearchBox(!searchBox)}
                        className={`menu-image-search${searchBox ? "-active" : ""}`} />
                    {makeButtons({}).reverse()}
                </div>
            </div ></div >
    }
    function mobileVersion() {
        return <>
            <div className="mobile menu-wrapper">
                <div className="menu-bar" style={{ marginTop: "71px", marginLeft: `${margin}px` }}>

                    <img src={require('../../../images/menu-bar/menu_icon.png')}
                        alt="menu icon" className="menu-image-drawer"
                        onClick={event => { setDisplayChanged(!displayChange) }
                        } />
                    <img src={require('../../../images/menu-bar/search_left_blue.png')}
                        alt="search icon" onClick={() => setSearchBox(!searchBox)} className="menu-image-search" />
                    {smallView ? "" : <Link to={`/${routeParams.lang}/content/home`}><img src={require('../../../images/menu-bar/logoHandiscover.png')}
                        alt="handiscover logo" className="menu-image" />  </Link>}
                    {searchBox ? <div className='search-mobile'>
                        <SearchBar />
                    </div> : ""}
                </div>
            </div>
            <div id="menuDrawerWrapper" style={{ display: displayChange ? "block" : "none", width: "251px", height: "100vh", backgroundColor: "#2a88ce", position: "fixed", zIndex: 9, }}>
                <div id="menuDrawer" className="menu-drawer mobile" style={{ display: displayChange ? "block" : "none", marginTop: mobile ? "10px" : 0, height: "calc(100vh  - 226px)" }}>
                    {makeButtons({})}
                </div>
            </div>
        </ >
    }
    return web ? desktopVersion() : mobileHeaderMenu ? null : mobileVersion()
}
