import { AllPostHeader } from "../models/AllPostHeader";
import { BlogPost } from "../models/BlogPost";
import { NamedObject } from "../models/NamedObject";
import { BlogPage } from "../models/BlogPage";
import { BlogPdf } from "../models/BlogPdf";
import { SiteMapEntry } from "../models/SiteMapEntry";
import { BlogPostHeader } from "../models/BlogPostHeader";
import { WpBlogPostHeader } from "../models/WpBlogPostHeader";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
export function getAll({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
}, signal?: AbortSignal): Promise<AllPostHeader[]> {
    const url = new URL(`/all`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<AllPostHeader[]>(url.toString(), { method: "GET", headers, signal });
}
export function getAmbassadors({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang, id }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
    id?: number | number[];
}, signal?: AbortSignal): Promise<BlogPost[]> {
    const url = new URL(`/ambassadors`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    addQueryParameter(url, "id", id);
    return fetchAndParse<BlogPost[]>(url.toString(), { method: "GET", headers, signal });
}
export function getCategories({ page, per_page, offset, include, slug, lang, parent, search }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    lang?: string;
    parent?: string | number;
    search?: string;
}, signal?: AbortSignal): Promise<NamedObject[]> {
    const url = new URL(`/categories`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "lang", lang);
    addQueryParameter(url, "parent", parent);
    addQueryParameter(url, "search", search);
    return fetchAndParse<NamedObject[]>(url.toString(), { method: "GET", headers, signal });
}
export function getDestinations({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang, id }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
    id?: number | number[];
}, signal?: AbortSignal): Promise<BlogPost[]> {
    const url = new URL(`/destinations`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    addQueryParameter(url, "id", id);
    return fetchAndParse<BlogPost[]>(url.toString(), { method: "GET", headers, signal });
}
export function getGetprioritizedpostids({ category, limit, lang }: {
    category?: string | string[];
    limit?: number;
    lang?: string;
}, signal?: AbortSignal): Promise<number[]> {
    const url = new URL(`/getPrioritizedPostIds`, baseUrl);
    addQueryParameter(url, "category", category);
    addQueryParameter(url, "limit", limit);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<number[]>(url.toString(), { method: "GET", headers, signal });
}
export function getGetsimilarposts({ postId, limit, lang }: {
    postId?: number;
    limit?: number;
    lang?: string;
}, signal?: AbortSignal): Promise<number[]> {
    const url = new URL(`/getSimilarPosts`, baseUrl);
    addQueryParameter(url, "postId", postId);
    addQueryParameter(url, "limit", limit);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<number[]>(url.toString(), { method: "GET", headers, signal });
}
export function getHealth({ page, per_page, offset, include, slug, lang }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    lang?: string;
}, signal?: AbortSignal): Promise<string> {
    const url = new URL(`/health`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<string>(url.toString(), { method: "GET", headers, signal });
}
export function getHome_sections({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang, id }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
    id?: number | number[];
}, signal?: AbortSignal): Promise<BlogPost[]> {
    const url = new URL(`/home_sections`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    addQueryParameter(url, "id", id);
    return fetchAndParse<BlogPost[]>(url.toString(), { method: "GET", headers, signal });
}
export function getPages({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPage[]> {
    const url = new URL(`/pages`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPage[]>(url.toString(), { method: "GET", headers, signal });
}
export function getPdfs({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPdf[]> {
    const url = new URL(`/pdfs`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPdf[]>(url.toString(), { method: "GET", headers, signal });
}
export function getPosts({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang, id }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
    id?: number | number[];
}, signal?: AbortSignal): Promise<BlogPost[]> {
    const url = new URL(`/posts`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    addQueryParameter(url, "id", id);
    return fetchAndParse<BlogPost[]>(url.toString(), { method: "GET", headers, signal });
}
export function getPress_releases({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPage[]> {
    const url = new URL(`/press_releases`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPage[]>(url.toString(), { method: "GET", headers, signal });
}
export function getSitemapdata(signal?: AbortSignal): Promise<SiteMapEntry[]> {
    const url = new URL(`/sitemapData`, baseUrl);
    return fetchAndParse<SiteMapEntry[]>(url.toString(), { method: "GET", headers, signal });
}
export function getTags({ page, per_page, offset, include, slug, lang }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    lang?: string;
}, signal?: AbortSignal): Promise<NamedObject[]> {
    const url = new URL(`/tags`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<NamedObject[]>(url.toString(), { method: "GET", headers, signal });
}
export function getTravel_infos({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang, id }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
    id?: number | number[];
}, signal?: AbortSignal): Promise<BlogPost[]> {
    const url = new URL(`/travel_infos`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    addQueryParameter(url, "id", id);
    return fetchAndParse<BlogPost[]>(url.toString(), { method: "GET", headers, signal });
}
export function getAmbassadorsHeaders({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPostHeader[]> {
    const url = new URL(`/ambassadors/headers`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPostHeader[]>(url.toString(), { method: "GET", headers, signal });
}
export function getAmbassadorsId(id: number, { lang }: {
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPost> {
    const url = new URL(`/ambassadors/${id}`, baseUrl);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPost>(url.toString(), { method: "GET", headers, signal });
}
export function getAmbassadorsbyslugSlug(slug: string, { lang }: {
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPost[]> {
    const url = new URL(`/ambassadorsBySlug/${slug}`, baseUrl);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPost[]>(url.toString(), { method: "GET", headers, signal });
}
export function getDestinationsHeaders({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPostHeader[]> {
    const url = new URL(`/destinations/headers`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPostHeader[]>(url.toString(), { method: "GET", headers, signal });
}
export function getDestinationsId(id: number, { lang }: {
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPost> {
    const url = new URL(`/destinations/${id}`, baseUrl);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPost>(url.toString(), { method: "GET", headers, signal });
}
export function getDestinationsbyslugSlug(slug: string, { lang }: {
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPost[]> {
    const url = new URL(`/destinationsBySlug/${slug}`, baseUrl);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPost[]>(url.toString(), { method: "GET", headers, signal });
}
export function getHome_sectionsHeaders({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPostHeader[]> {
    const url = new URL(`/home_sections/headers`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPostHeader[]>(url.toString(), { method: "GET", headers, signal });
}
export function getHome_sectionsId(id: number, { lang }: {
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPost> {
    const url = new URL(`/home_sections/${id}`, baseUrl);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPost>(url.toString(), { method: "GET", headers, signal });
}
export function getHome_sectionsbyslugSlug(slug: string, { lang }: {
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPost[]> {
    const url = new URL(`/home_sectionsBySlug/${slug}`, baseUrl);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPost[]>(url.toString(), { method: "GET", headers, signal });
}
export function getPagesHeaders({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
}, signal?: AbortSignal): Promise<WpBlogPostHeader[]> {
    const url = new URL(`/pages/headers`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<WpBlogPostHeader[]>(url.toString(), { method: "GET", headers, signal });
}
export function getPagesId(id: number, signal?: AbortSignal): Promise<BlogPage> {
    const url = new URL(`/pages/${id}`, baseUrl);
    return fetchAndParse<BlogPage>(url.toString(), { method: "GET", headers, signal });
}
export function getPdfsHeaders({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
}, signal?: AbortSignal): Promise<WpBlogPostHeader[]> {
    const url = new URL(`/pdfs/headers`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<WpBlogPostHeader[]>(url.toString(), { method: "GET", headers, signal });
}
export function getPdfsId(id: number, signal?: AbortSignal): Promise<BlogPdf> {
    const url = new URL(`/pdfs/${id}`, baseUrl);
    return fetchAndParse<BlogPdf>(url.toString(), { method: "GET", headers, signal });
}
export function getPostsHeaders({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPostHeader[]> {
    const url = new URL(`/posts/headers`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPostHeader[]>(url.toString(), { method: "GET", headers, signal });
}
export function getPostsId(id: number, { lang }: {
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPost> {
    const url = new URL(`/posts/${id}`, baseUrl);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPost>(url.toString(), { method: "GET", headers, signal });
}
export function getPostsbyslugSlug(slug: string, { lang }: {
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPost[]> {
    const url = new URL(`/postsBySlug/${slug}`, baseUrl);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPost[]>(url.toString(), { method: "GET", headers, signal });
}
export function getPress_releasesHeaders({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
}, signal?: AbortSignal): Promise<WpBlogPostHeader[]> {
    const url = new URL(`/press_releases/headers`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<WpBlogPostHeader[]>(url.toString(), { method: "GET", headers, signal });
}
export function getPress_releasesId(id: number, signal?: AbortSignal): Promise<BlogPage> {
    const url = new URL(`/press_releases/${id}`, baseUrl);
    return fetchAndParse<BlogPage>(url.toString(), { method: "GET", headers, signal });
}
export function getTravel_infosHeaders({ page, per_page, offset, include, slug, search, order, orderby, categories, categories_exclude, tags, lang }: {
    page?: number;
    per_page?: number;
    offset?: number;
    include?: number[];
    slug?: string | string[];
    search?: string;
    order?: "asc" | "desc";
    orderby?: ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title") | ("date" | "author" | "id" | "include" | "modified" | "parent" | "relevance" | "slug" | "include_slugs" | "title")[];
    categories?: number | string | number[] | string[];
    categories_exclude?: number | string | number[] | string[];
    tags?: number | string | number[] | string[];
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPostHeader[]> {
    const url = new URL(`/travel_infos/headers`, baseUrl);
    addQueryParameter(url, "page", page);
    addQueryParameter(url, "per_page", per_page);
    addQueryParameter(url, "offset", offset);
    addQueryParameter(url, "include", include);
    addQueryParameter(url, "slug", slug);
    addQueryParameter(url, "search", search);
    addQueryParameter(url, "order", order);
    addQueryParameter(url, "orderby", orderby);
    addQueryParameter(url, "categories", categories);
    addQueryParameter(url, "categories_exclude", categories_exclude);
    addQueryParameter(url, "tags", tags);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPostHeader[]>(url.toString(), { method: "GET", headers, signal });
}
export function getTravel_infosId(id: number, { lang }: {
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPost> {
    const url = new URL(`/travel_infos/${id}`, baseUrl);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPost>(url.toString(), { method: "GET", headers, signal });
}
export function getTravel_infosbyslugSlug(slug: string, { lang }: {
    lang?: string;
}, signal?: AbortSignal): Promise<BlogPost[]> {
    const url = new URL(`/travel_infosBySlug/${slug}`, baseUrl);
    addQueryParameter(url, "lang", lang);
    return fetchAndParse<BlogPost[]>(url.toString(), { method: "GET", headers, signal });
}
export function getAmbassadorsIdLang(id: number, lang: string, signal?: AbortSignal): Promise<BlogPost> {
    const url = new URL(`/ambassadors/${id}/${lang}`, baseUrl);
    return fetchAndParse<BlogPost>(url.toString(), { method: "GET", headers, signal });
}
export function getDestinationsIdLang(id: number, lang: string, signal?: AbortSignal): Promise<BlogPost> {
    const url = new URL(`/destinations/${id}/${lang}`, baseUrl);
    return fetchAndParse<BlogPost>(url.toString(), { method: "GET", headers, signal });
}
export function getHome_sectionsIdLang(id: number, lang: string, signal?: AbortSignal): Promise<BlogPost> {
    const url = new URL(`/home_sections/${id}/${lang}`, baseUrl);
    return fetchAndParse<BlogPost>(url.toString(), { method: "GET", headers, signal });
}
export function getPagesIdLang(id: number, lang: string, signal?: AbortSignal): Promise<BlogPage> {
    const url = new URL(`/pages/${id}/${lang}`, baseUrl);
    return fetchAndParse<BlogPage>(url.toString(), { method: "GET", headers, signal });
}
export function getPdfsIdLang(id: number, lang: string, signal?: AbortSignal): Promise<BlogPdf> {
    const url = new URL(`/pdfs/${id}/${lang}`, baseUrl);
    return fetchAndParse<BlogPdf>(url.toString(), { method: "GET", headers, signal });
}
export function getPostsIdLang(id: number, lang: string, signal?: AbortSignal): Promise<BlogPost> {
    const url = new URL(`/posts/${id}/${lang}`, baseUrl);
    return fetchAndParse<BlogPost>(url.toString(), { method: "GET", headers, signal });
}
export function getPress_releasesIdLang(id: number, lang: string, signal?: AbortSignal): Promise<BlogPage> {
    const url = new URL(`/press_releases/${id}/${lang}`, baseUrl);
    return fetchAndParse<BlogPage>(url.toString(), { method: "GET", headers, signal });
}
export function getTravel_infosIdLang(id: number, lang: string, signal?: AbortSignal): Promise<BlogPost> {
    const url = new URL(`/travel_infos/${id}/${lang}`, baseUrl);
    return fetchAndParse<BlogPost>(url.toString(), { method: "GET", headers, signal });
}
