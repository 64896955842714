import React, { useMemo, useCallback, Suspense as ReactSuspense, useEffect } from "react";
import { RouteProps, RouteChildrenProps, Switch, Route, useRouteMatch } from "react-router";
import { InjectedContext, useParameters } from "../../utils";
import container from "../container";
import Header from '../header.jsx'
import Footer from '../footer.jsx'
import { MobileRedirectionBanner } from '../mobile-redirection-banner'
import { getMobileOperatingSystem } from "../utils";

export const Suspense = (p => {
  const Component = !window.isServerSide ? ReactSuspense : ((p) => <>{p.children}</>) as typeof ReactSuspense;
  return <Component {...p}></Component>
}) as typeof ReactSuspense

type LazyRouteProps<T> = Omit<RouteProps, 'component'> & {
  container?: React.ComponentType<any>,
  children?: React.ReactNode,
  props?: T,
  /** 
   * Shim for older components, that only react to changes to properties in the constructor
   * For newer components prefer to leave this false
  */
  legacy?: boolean
} & (
    | { getComponent: () => Promise<React.ComponentType<T> | { default: React.ComponentType<T> }>; component?: undefined }
    | { getComponent?: undefined; component?: React.ComponentType<T> }
  )
export const fastLoadCache: Record<string, () => React.ComponentType<any> | { default: React.ComponentType<any> }> = {

}


export function* collectModules(element: JSX.Element): Generator<{
  getComponent: () => Promise<React.ComponentType<any> | { default: React.ComponentType<any> }>,
  path: string
}> {
  if (!element.props) return;
  if ('getComponent' in element.props && 'path' in element.props) {
    yield {
      getComponent: element.props.getComponent,
      path: element.props.path
    }
  }
  if ('children' in element.props && element.props.children instanceof Array) {
    const children = (element.props.children as JSX.Element[]).map(x => collectModules(x));
    for (const child of children) {
      yield* child;
    }
  }
}

export function fastLoadOrLazy(p: {
  path?: string | string[],
  getComponent?: () => Promise<React.ComponentType<any> | { default: React.ComponentType<any> }>
}) {
  if (typeof p.path === "string" && fastLoadCache[p.path]) {
    const cachedValue = fastLoadCache[p.path]();
    if ('default' in cachedValue) {
      return container(cachedValue.default)
    } else {
      return container(cachedValue);
    }
  }
  return React.lazy(async () => {
    const module = await p.getComponent!();
    if (typeof p.path === "string") {
      fastLoadCache[p.path] = () => module;
    }
    let LazyTargetComponent =
      'default' in module ? container(module.default) : container(module);
    return { default: LazyTargetComponent };
  })
}

export function LazyRoute<T,>(p: LazyRouteProps<T>) {
  const { component, getComponent, children, container: Container, ...rest } = p;
  let Component = useMemo(() => {
    const LazyTargetComponent = p.component ? p.component : fastLoadOrLazy(p);
    let TargetComponent: React.ComponentType<any>;
    if (Container) {
      TargetComponent = function (p) {
        return <Suspense fallback={() => <div>Loading {rest.path}...</div>} >
          <Container {...p} >
            <Suspense fallback={() => <div>Loading {rest.path}...</div>} >
              <LazyTargetComponent {...p} key={rest.legacy ? p.router.location.key : undefined}></LazyTargetComponent>
            </Suspense>
          </Container>
        </Suspense>
      }
    } else {
      TargetComponent = function (p) {
        return <Suspense fallback={() => <div>Loading {rest.path}...</div>} >
          <LazyTargetComponent {...p} key={rest.legacy ? p.router.location.key : undefined}></LazyTargetComponent>
        </Suspense>
      }
    }
    return TargetComponent;
  }, [getComponent]);

  return <ContainerRoute component={Component} {...rest} legacy={false}>
    {children}
  </ContainerRoute>
}
type ContainerRouteProps = Omit<RouteProps, 'component'> & {
  component: React.ComponentType<any>,
  children: React.ReactNode,
  props?: any,
  /** 
   * Shim for older components, that only react to changes to properties in the constructor
   * For newer components prefer to leave this false
  */
  legacy?: boolean
}

declare module 'history' {
  export interface Location<S = LocationState> {
    query: unknown
  }
}
export function ContainerRoute({ component: Component, children, props, ...rest }: ContainerRouteProps) {
  const CompundComponent = useCallback((rp: RouteChildrenProps) => {

    const params = useParameters();

    if (!rp.location.query) {
      const urlSearch = new URLSearchParams(rp.location.search);
      rp.location.query = Object.fromEntries(urlSearch.entries());
    }
    const injectedRouterShim = {
      ...rp.history,
      params,
      getCurrentLocation() {
        return rp.location
      },
      location: rp.location
    }

    return <Component {...props} {...rp} router={injectedRouterShim} location={rp.location} params={params}
      // shim for older components, that only react to changes to properties in the constructor
      // For newer components prefer to leave this false
      key={rest.legacy ? rp.location.key : undefined}>
      <Switch>
        {children}
      </Switch>
    </Component>
  }, [children, Component])
  return <Route {...rest} component={CompundComponent} />
}
const MainLayout = container(function MainLayout(props: InjectedContext & { children?: React.ReactNode }) {
  const mobileOperatingSystem = getMobileOperatingSystem()
  return (
    <div>
      <Header {...props} banner={props.location.pathname.length > 10 ? false : true} />
      <div className="main-layout-content">
        {mobileOperatingSystem === "iOS" ? <MobileRedirectionBanner /> : null}
        {props.children}</div>
      <Footer {...props} />
    </div>
  )
})
const SelfLayout = container(function SelfLayout(props: InjectedContext & { children?: React.ReactNode }) {
  return <div className="main-layout-content">{props.children}</div>
})
export const SelfLayoutRoute = <T,>(p: Omit<LazyRouteProps<T>, 'container'>) => <LazyRoute container={SelfLayout} {...p as LazyRouteProps<T>} ></LazyRoute>
export const MainLayoutRoute = <T,>(p: Omit<LazyRouteProps<T>, 'container'>) => <LazyRoute container={MainLayout} {...p as LazyRouteProps<T>} ></LazyRoute>
