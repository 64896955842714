import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'

const options = {
  fallbackLng: 'en',
  load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE
  debug: false,
  react: {
    wait: true,
    bindI18n: 'languageChanged',
  },
  initImmediate: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
};

i18n.use(XHR)

if (!i18n.isInitialized) i18n.init(options)

export default i18n
