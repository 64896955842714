import React, { useEffect } from "react";
import utils from "../../lib/utils";
import { useHistory, useLocation } from "../../utils";
import { getStateParam, addRehydrateListener, rehydrate } from "../container";


export function FallbackRoute(p: { children?: React.ReactNode}) {
    const location = useLocation();
    const nav = useHistory();
    function onEnterFallbackMatch() {
        /*
        Responsibilities:
        - Handle paths of the form <base>/<whatever> (no trailing slash)
        - Handle paths of the form <base>
        - Add trailing slash if needed
        */

        // Note: pathname always starts with '/' so strs.length>0 and strs[0] is always an empty string
        let strs = location.pathname.split('/')
        if (getStateParam('noStore').rehydrated) {
            if (strs) {
                let path = utils.forceTrailingSlash(location.pathname)
                if (!utils.isLanguageSupported(strs[1])) {
                    nav.replace(getStateParam('locale') + path + location.search)
                } else if (!utils.hasTrailingSlash(strs[1])) {
                    nav.replace(path + location.search)
                }
            }
        } else {
            addRehydrateListener(onEnterFallbackMatch);
            if (strs) {
                rehydrate(strs[1])
            } else {
                rehydrate()
            }
        }
    }
    useEffect(onEnterFallbackMatch, []);
    return <>
        {p.children}
    </>
}