import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

import container from './container.jsx'

//accounts/confirmation
import ConfirmationsPage from './confirmations-page.jsx'
import PasswordResetPage from './password-reset-page.jsx'
import { MobileMenuContextProvider } from './blog/menu-bar/menu'
import { LangaugeConatiner } from './routing-helpers/language-helper'
import { ContainerRoute, LazyRoute, MainLayoutRoute, SelfLayoutRoute } from './routing-helpers/custom-routes'
import { locationRoutes } from './routing-helpers/location-routing'
import { SeoRedirect } from './routing-helpers/seo-redirect'
import { FallbackRoute } from './routing-helpers/fallback-route'

declare global {
  const GOOGLE_ANALYTICS_ID: string;
  interface Window {
    isServerSide?: boolean
  }
}

ReactGA.initialize(GOOGLE_ANALYTICS_ID)

function onUpdateRouter() {
  ReactGA.pageview(window.location.pathname + window.location.search)
}

const RouterWrapper: React.FC = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    onUpdateRouter();
  }, [location]);
  return <>
    {children}
  </>
}

export const routerConf = (
  <Switch >
    <ContainerRoute path="/:lang" component={LangaugeConatiner}>
      {/*<SelfLayoutRoute path="/:lang/search*" getComponent={() => import('./search-properties')} />*/}
      <SelfLayoutRoute path="/:lang/properties/:propertyId" getComponent={() => import('./property-page/property-page')} />
      <SelfLayoutRoute path="/:lang/properties/:propertyId/puts/:putId" getComponent={() => import('./property-page/property-page')} />
      <MainLayoutRoute path="/:lang" exact getComponent={() => import("./home-page")} />
      <MainLayoutRoute path="/:lang/about" getComponent={() => import('./about-page')} />
      <MainLayoutRoute path="/:lang/terms" getComponent={() => import("./terms-page")} />
      <MainLayoutRoute path="/:lang/howItWorks" getComponent={() => import("./how-it-works")} />
      <MainLayoutRoute path="/:lang/uspAccData" getComponent={() => import("./usp-accessibility-data")} />
      <MainLayoutRoute path="/:lang/uspCustomerService" getComponent={() => import("./usp-customer-service")} />
      <MainLayoutRoute path="/:lang/uspGreatPrice" getComponent={() => import("./usp-great-price")} />
      <MainLayoutRoute path="/:lang/best-price-guarantee" getComponent={() => import("./best-price-guarantee")} />
      <MainLayoutRoute path="/:lang/finding_booking_accessible_accommodation" getComponent={() => import("./finding-booking-accessible-accommodation")} />
      <MainLayoutRoute path="/:lang/privacy-policy" getComponent={() => import("./privacy-policy-page")} />
      <MainLayoutRoute path="/:lang/signup" getComponent={() => import('./signup-page')} />
      <MainLayoutRoute path="/:lang/gdpr" getComponent={() => import('./gdpr-page')} />
      <MainLayoutRoute path="/:lang/signup-landing" getComponent={() => import('./signup-landing-page')} />
      <MainLayoutRoute path="/:lang/accounts/password-recovery-confirmation/" component={container(PasswordResetPage)} />
      <MainLayoutRoute path="/:lang/accounts/confirmations/" component={container(ConfirmationsPage)} />
      <MainLayoutRoute path="/:lang/content" getComponent={() => /* webpackChunkName:"blog" */ import("./blog-home-page/blog-root-page")} >
        <LazyRoute path="/:lang/content/home" getComponent={() => /* webpackChunkName:"blog" */ import("./blog-home-page/blog-home-page")} />
        <LazyRoute path="/:lang/content/ambassadors/:blogSlug" getComponent={() => /* webpackChunkName:"blog" */ import("./blog/single-generic-page")} props={{ collection: "ambassadors" as const }} />
        <LazyRoute path="/:lang/content/destination/:blogSlug" getComponent={() => /* webpackChunkName:"blog" */ import("./blog/single-generic-page")} props={{ collection: "destination" as const }} />
        <LazyRoute path="/:lang/content/ebook/:blogSlug" getComponent={() => /* webpackChunkName:"blog" */ import("./blog/single-book-page")} props={{ collection: "ebook" as const }} />
        <LazyRoute path="/:lang/content/travel-info/:blogSlug" getComponent={() => /* webpackChunkName:"blog" */ import("./blog/single-generic-page")} props={{ collection: "travel-info" as const }} />
        <LazyRoute path="/:lang/content/page/:blogSlug" getComponent={() => /* webpackChunkName:"blog" */ import("./blog/single-generic-page")} props={{ collection: "page" as const }} />
        <LazyRoute path="/:lang/content/results/:collection/:searchType/:searchText" getComponent={() => /* webpackChunkName:"blog" */ import("./blog-home-page/blog-results-page")} />
        <LazyRoute path="/:lang/content/results/:collection" getComponent={() => /* webpackChunkName:"blog" */ import("./blog-home-page/blog-results-page")} />
        <LazyRoute path="/:lang/content/results" getComponent={() => /* webpackChunkName:"blog" */ import("./blog-home-page/blog-results-page")} />
        <LazyRoute path="/:lang/content/post/:blogSlug" getComponent={() => /* webpackChunkName:"blog" */ import("./blog/single-blog-page")} />
        <LazyRoute path="/:lang/content/:blogSlug" getComponent={() => /* webpackChunkName:"blog" */ import("./blog/single-blog-page")} />
        <LazyRoute path="/:lang/content/podcast/:blogSlug" getComponent={() => /* webpackChunkName:"blog" */ import("./blog/single-blog-page")} />
      </MainLayoutRoute>

      <MainLayoutRoute path="/:lang/profile/:userId" getComponent={() => import("./personal-profile/personal-profile-page")} />
      <MainLayoutRoute path="/:lang/dashboard/" getComponent={() => import("./dashboard-page")} >
        <Redirect path="/:lang/dashboard/" exact to="/:lang/dashboard/inbox/"></Redirect>
        <LazyRoute path="/:lang/dashboard/inbox/:chatId/" getComponent={() => import("./inbox-page/inbox-conversation")} />
        <LazyRoute path="/:lang/dashboard/inbox/" getComponent={() => import("./inbox-page/inbox-page")} />
        <LazyRoute path="/:lang/dashboard/mrp/" getComponent={() => import("./properties-settings-page")}>
          <LazyRoute path="/:lang/dashboard/mrp/properties" getComponent={() => import('./properties-page/mrp/index')} />
          <LazyRoute path="/:lang/dashboard/mrp/:hotelId/edit" getComponent={() => import('./properties-page/mrp/mrp-hotel-edit')} />
          <LazyRoute path="/:lang/dashboard/mrp/:hotelId/edit-pictures" getComponent={() => import('./properties-page/mrp/mrp-hotel-edit-pictures')} />
          <LazyRoute path="/:lang/dashboard/mrp/:hotelId/list" getComponent={() => import('./properties-page/mrp/mrp-hotel-rooms-list')} />
          <LazyRoute path="/:lang/dashboard/mrp/:hotelId/dashboard" getComponent={() => import('./list-your-property/mrp-hotel-dashboard-page')} />
        </LazyRoute>
        <LazyRoute path="/:lang/dashboard/properties/" getComponent={() => import('./properties-page/properties-page')}>
          <LazyRoute path="/:lang/dashboard/properties/" getComponent={() => import("./properties-settings-page")}>
          </LazyRoute>
        </LazyRoute>

        <LazyRoute path="/:lang/dashboard/bookings/" exact getComponent={() => import('./dashboard-booking/bookings-page')} >
          <LazyRoute path="/:lang/dashboard/bookings/" getComponent={() => import("./properties-settings-page")}>
          </LazyRoute>
        </LazyRoute>
        <LazyRoute path="/:lang/dashboard/profile/" getComponent={() => import("./profile-settings-page")}>
          <LazyRoute path="/:lang/dashboard/profile/channel-managers" getComponent={() => import('./handle-channel-managers-page')} />
          <LazyRoute path="/:lang/dashboard/profile/reviews" getComponent={() => import('./handle-reviews-page')} />
          <LazyRoute path="/:lang/dashboard/profile/verifications" getComponent={() => import('./verifications-page')} />
          <LazyRoute path="/:lang/dashboard/profile/photos" getComponent={() => import('./profile-photos-page')} />
          {/* These two are suspect, proabably shoudl not be in a switch */}
          <LazyRoute path="/:lang/dashboard/profile/host" getComponent={() => import('./become-host-page')} />
          <LazyRoute path="/:lang/dashboard/profile/host" getComponent={() => import('./host-profile-page')} />
          <LazyRoute path="/:lang/dashboard/profile" exact getComponent={() => import('./edit-profile-page')} />
        </LazyRoute>
        <LazyRoute path="/:lang/dashboard/profile-new/" getComponent={() => import('./user-profile-page/user-profile-page')} />
        <LazyRoute path="/:lang/dashboard/lys/" getComponent={() => import('./lys/lys-page')} />
        <LazyRoute path="/:lang/dashboard/settings/" getComponent={() => import('./settings-page')} >
          <LazyRoute path="/:lang/dashboard/settings/payment-methods" getComponent={() => import('./payment-methods-page')} />
          <LazyRoute path="/:lang/dashboard/settings/payouts" getComponent={() => import('./dashboard-payout-methods/payout-preferences-page')} />
          <LazyRoute path="/:lang/dashboard/settings/transactions" getComponent={() => import('./transactions-page.jsx')} />
          <LazyRoute path="/:lang/dashboard/settings/security" getComponent={() => import('./security-page.jsx')} />
          <LazyRoute path="/:lang/dashboard/settings/settings" getComponent={() => import('./dashboard-settings/settings-cancel-account-page')} />
          <LazyRoute path="/:lang/dashboard/settings/id-verification" getComponent={() => import('./id-verification-page.jsx')} />
          <LazyRoute path="/:lang/dashboard/settings" exact getComponent={() => import('./payment-methods-page')} />
        </LazyRoute>
      </MainLayoutRoute>

      <LazyRoute path="/:lang/bookings/:bookingId/payment" getComponent={() => import("./payment-page")} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/puts/:putId/general-info" getComponent={() => import('./list-your-property/put-general-info-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/puts/:putId/basic-info" getComponent={() => import('./list-your-property/put-basic-info-page.jsx')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/puts/:putId/accessibility" getComponent={() => import('./list-your-property/put-accessibility-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/puts/:putId/facilities" getComponent={() => import('./list-your-property/put-facilities-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/puts/:putId/policies" getComponent={() => import('./list-your-property/put-policies-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/puts/:putId/availability" getComponent={() => import('./list-your-property/put-availability-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/puts/:putId/calendar" getComponent={() => import('./list-your-property/put-availability-calendar-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/puts/:putId/pricing" getComponent={() => import('./list-your-property/put-basic-pricing-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/puts/:putId/seasons" getComponent={() => import('./list-your-property/put-seasons-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/puts/:putId/images" getComponent={() => import('./list-your-property/put-image-upload-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/puts/:putId/srp-images" getComponent={() => import('./list-your-property/srp-image-upload-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/puts/:putId" getComponent={() => import('./list-your-property/overview-srp-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/location" getComponent={() => import('./list-your-property/property-general-info-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/accessibility" getComponent={() => import('./list-your-property/property-accessibility-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/images" getComponent={() => import('./list-your-property/property-image-upload-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId/facilities" getComponent={() => import('./list-your-property/property-facilities-page')} />
      <LazyRoute path="/:lang/list-your-property/:propertyId" getComponent={() => import('./list-your-property/overview-srp-page')} />
      <LazyRoute path="/:lang/list-your-property" exact getComponent={() => import('./list-your-property/property-general-info-page')} />
      {locationRoutes}
      <SelfLayoutRoute
        path="/:lang/*"
        component={container(SeoRedirect)}
      />
    </ContainerRoute>
    <MainLayoutRoute path="/:lang/404" getComponent={() => import("./page-not-found/page-not-found")} />
    <Route path="*" component={FallbackRoute} />
  </Switch>
)

class App extends React.Component {
  render() {
    return <MobileMenuContextProvider>
      <RouterWrapper>
        {routerConf}
      </RouterWrapper>
    </MobileMenuContextProvider>
  }
}

export default App

