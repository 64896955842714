import React from 'react'
import ComponentWithLogin from './component-with-login.jsx'
import { Link } from 'react-router-dom'
import utils from '../lib/utils.js'
import { withTranslation as translate } from 'react-i18next'
import Cookies from 'universal-cookie'
import Banner from './banner.jsx'
import UserDisabilityForm from './user-disability-data/user-disability-form.jsx'
import { MobileMenuContext } from './blog/menu-bar/menu'


/**
 * @extends {ComponentWithLogin<{banner: boolean} & import('react-i18next').WithTranslation>}
 */
class Header extends ComponentWithLogin {
  constructor(props) {
    super(props)
    this.state = Object.assign(this.state, {
      banner: this.props.location.pathname.length > 10 ? false : true,
      userDisabilityForm: false,
      isSignuPageUrl: this.props.location.pathname.endsWith('signup/'),
    })
    this.state.accepted_policy = 0
    this.closeBanner = this.closeBanner.bind(this)
    this.openUserDisabilityForm = this.openUserDisabilityForm.bind(this)
    this.closeUserDisabilityForm = this.closeUserDisabilityForm.bind(this)
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname.length !== this.props.location.pathname.length) {
      this.setState({
        banner: this.props.location.pathname.length > 10 ? false:true,
        isSignuPageUrl: this.props.location.pathname.endsWith('signup/')
      })
    }

    const { userDisabilityForm } = this.state
    const user = this.props.state.user
    if (user && this.props.state.accessToken) {
      if (!user.has_completed_disability_profile && !userDisabilityForm && !this.props.showMrp) {
        this.openUserDisabilityForm()
      } else if (user.has_completed_disability_profile && userDisabilityForm) {
        this.closeUserDisabilityForm()
      }
    }
  }
  openUserDisabilityForm() {
    this.setState({
      userDisabilityForm: true,
    })
  }
  closeUserDisabilityForm() {
    this.setState({
      userDisabilityForm: false,
    })
  }
  componentWillMount() {
    const cookies = new Cookies();
    let accepted_policy = cookies.get('accepted_policy')
    this.state.accepted_policy = (accepted_policy) ? 1 : 0
  }
  toggleMobileMenu() {
    this.context.setIsOpen(!this.context.isOpen)

  }
  closeBanner() {
    this.setState({ banner: false })
  }
  getMobileMenu() {
    const _t = this.props.t
    var rightSide = null
    const self = this;
    if (this.props.isSignedIn()) {
      return (
        <nav className={`drop-down-close ${this.context.isOpen ? 'drop-down-open' : ''}`}>
          <ul className="drop-down_list">
            <MobileMenuContext.Consumer>
              {({ menuList }) => menuList}
            </MobileMenuContext.Consumer>
            <li className="drop-down__item">
              <Link
                className="drop-down__item-link"
                to={`/${this.props.state.locale}/dashboard/inbox/`}
                onClick={this.toggleMobileMenu.bind(this)}
              >
                {_t('menu_inbox')}
              </Link>
            </li>
            <li className="drop-down__item">
              <Link
                className="drop-down__item-link"
                to={`/${this.props.state.locale}/dashboard/bookings/`}
                onClick={this.toggleMobileMenu.bind(this)}
              >
                {_t('menu_bookings')}
              </Link>
            </li>
            <li className="drop-down__item">
              <a
                className="drop-down__item-link"
                href="https://handiscover.zendesk.com/hc/en-us"
                target='_blank'
                onClick={this.toggleMobileMenu.bind(this)}
              >
                {_t('footer_help')}
              </a>
            </li>
            <li className="drop-down__item">
              <a
                className="drop-down__item-link"
                onClick={() => {
                  self.toggleMobileMenu()
                  self.onSignOut()
                }}
              >
                {_t('menu_logout')}
              </a>
            </li>
          </ul>
        </nav>
      )
    }
    else {
      return (
        <MobileMenuContext.Consumer>
          {({ menuList }) =>
            <nav className={`drop-down-close ${this.context.isOpen ? 'drop-down-open' : ''}`}>
              <ul className="drop-down_list">
                {menuList}
                <li className="drop-down__item">
                  <a
                    className="drop-down__item-link"
                    href="https://handiscover.zendesk.com/hc/en-us"
                    target='_blank'
                    onClick={this.toggleMobileMenu.bind(this)}
                  >
                    {_t('footer_help')}
                  </a>
                </li>
                {/*<li className="drop-down__item"*/}
                {/*  style={{ display: this.state.isSignuPageUrl ? 'none' : 'list-item' }}>*/}
                {/*  <a*/}

                {/*    className="drop-down__item-link"*/}
                {/*    onClick={() => {*/}
                {/*      self.toggleMobileMenu()*/}
                {/*      self.onOpenLoginModal()*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    {_t('menu_login')}*/}
                {/*  </a>*/}
                {/*</li>*/}
                {/*<li className="drop-down__item"*/}
                {/*  style={{ display: this.state.isSignuPageUrl ? 'none' : 'list-item' }}>*/}
                {/*  <a*/}
                {/*    className="drop-down__item-link"*/}
                {/*    onClick={() => {*/}
                {/*      self.toggleMobileMenu()*/}
                {/*      self.onOpenSignupModal()*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    {_t('settings_page_member_join')}*/}
                {/*  </a>*/}
                {/*</li>*/}
              </ul>
            </nav>}
        </MobileMenuContext.Consumer>
      )
    }
  }

  getDesktopMenu() {
    const isStaff = (typeof (this.props.state.user) !== 'undefined') ? this.props.state.user.is_staff : false;
    const isSuperUser = (typeof (this.props.state.user) !== 'undefined') ? this.props.state.user.is_superuser : false;
    const _t = this.props.t
    if (this.props.isSignedIn()) {
      let avatarStyle = {}
      if (this.props.state.user) {
        let url = utils.getAvatarUrl(this.props.state.user)
        if (url && url.length > 0) {
          avatarStyle.backgroundImage = `url(${url})`
        }
      }
      let editPageLink = null
      if ((this.props.showMrp && isStaff) || (this.props.showMrp && isSuperUser)) {
        editPageLink = (
          <div>
            <Link
              className="display-none-modif"
              to={`/${this.props.state.locale}/dashboard/mrp/${this.props.property.id}/list`}
            >
              <span>Edit</span>
            </Link>
          </div>

        )

      } else if ((!this.props.showMrp && isStaff && this.props.property) || (!this.props.showMrp && isSuperUser && this.props.property)) {
        editPageLink = (
          <div>
            <Link
              className="display-none-modif"
              to={`/${this.props.state.locale}/list-your-property/${this.props.property.id}/puts/${this.props.puts[0].id}`}
            >
              <span>Edit</span>
            </Link>
          </div>

        )
      }
      return (
        <div className="right">
          <div
            className={`drop_menu ${this.context.isOpen ? 'drop_menu_back' : ''}`}
            onClick={this.toggleMobileMenu.bind(this)}
          >
            {_t('header_menu')}
          </div>
          {editPageLink}
          <Link
            className="display-none-modif"
            to={`/${this.props.state.locale}/dashboard/inbox/`}
          >
            {_t('menu_inbox')}
          </Link>
          <Link
            className="display-none-modif"
            to={`/${this.props.state.locale}/dashboard/bookings/`}
          >
            {_t('menu_bookings')}
          </Link>
          <a
            className="display-none-modif"
            href="https://handiscover.zendesk.com/hc/en-us"
            target='_blank'
          >
            {_t('footer_help')}
          </a>
          <a
            className="display-none-modif"
            onClick={this.onSignOut.bind(this)}
          >
            {_t('menu_logout')}
          </a>
          <Link to={`/${this.props.state.locale}/dashboard/`}>
            <div className="avatar" style={avatarStyle} />
          </Link>
        </div>
      )
    }
    else {
      return (
        <div className="right">
          {/* <button onClick={this.openUserDisabilityForm}>DDD</button> */}
          <div
            className={`drop_menu ${this.context.isOpen ? 'drop_menu_back' : ''}`}
            onClick={this.toggleMobileMenu.bind(this)}
          >
            {_t('header_menu')}
          </div>
          <a
            className="hidden-xs display-none-modif"
            href="https://handiscover.zendesk.com/hc/en-us"
            target='_blank'
          >
            {_t('footer_help')}
          </a>
          {/*<div className="vertical-separator hidden-xs" style={{display: this.state.isSignuPageUrl ? 'none' : 'inline-block'}} />*/}
          {/*<a style={{ display: this.state.isSignuPageUrl ? 'none' : 'inline-flex' }}*/}
          {/*  className="hidden-xs display-none-modif"*/}
          {/*  onClick={this.onOpenLoginModal.bind(this)}*/}
          {/*>*/}
          {/*  {_t('menu_login')}*/}
          {/*</a>*/}
          {/*<div className="vertical-separator hidden-xs" style={{display: this.state.isSignuPageUrl ? 'none' : 'inline-block'}} />*/}
          {/*<a style={{ display: this.state.isSignuPageUrl ? 'none' : 'inline-flex' }}*/}
          {/*  onClick={this.onOpenSignupModal.bind(this)}*/}
          {/*  className="hidden-xs display-none-modif header-join-us"*/}
          {/*>*/}
          {/*  {_t('settings_page_member_join')}*/}
          {/*</a>*/}

        </div>
      )
    }
  }

  render() {
    var cookieBar = null
    let rightSide = this.getDesktopMenu()
    let mobilContent = this.getMobileMenu()
    if (!this.state.accepted_policy) {
      cookieBar = (
        <div className="cookie-bar" >
          <div className="inner" data-nosnippet="data-nosnippet">
            <p>This site uses cookies as described in our <span>&nbsp;</span>
              <a href={`${HOST_URL}/${this.props.state.locale}/privacy-policy/`}>Cookie Policy</a>.<span>&nbsp;</span>
         If you agree to our use of cookies, please continue to use our site by clicking "I Accept"<span>&nbsp;</span>
              <a className="link" onClick={this.onAcceptClick.bind(this)}>I Accept.</a></p>
          </div>
          {/* <button onClick={this.onAcceptClick.bind(this)}>&times;</button> */}
        </div>
      )
    }
    //const closedCovidBanner = sessionStorage.getItem('covid-banner')
    let className = 'header'
    if (!this.state.accepted_policy) {
      className += ' policy-not-accepted'
    }
    if(this.state.banner /* && (closedCovidBanner !== 'closed')*/){
      className += ' visible-banner'
    }
    if (this.props.className) {
      className += ' ' + this.props.className
    }

    return (
      <div className={className}>
        {cookieBar}
        {/* Covid banner */}
        {/* <Banner
        show={this.state.banner}
        closeBanner={this.closeBanner}
        /> */}
        <UserDisabilityForm
          {...this.props}
          open={this.state.userDisabilityForm}
          closeUserDisabilityForm={this.closeUserDisabilityForm}
          deviceSize={this.props.state.noStore.deviceSize}
        />
        <div className="inner">
          <Link to={`/${this.props.state.locale}/`} className="logo-link" aria-label="back to main page link">
            <div className="logo" />
          </Link>
          {mobilContent}
          {this.renderLoginModal()}
          {rightSide}
        </div>
      </div>
    )
  }
  onAcceptClick() {
    const cookies = new Cookies();
    cookies.set('accepted_policy', 1)
    this.setState({ 'accepted_policy': 1 })
  }
}
Header.contextType = MobileMenuContext


export default translate()(Header)
