import React from 'react'

class UserDisabilityRadioButtons extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      value: this.props.value,
    }
    this.renderContent = this.renderContent.bind(this)
    this.handleOptionChange = this.handleOptionChange.bind(this)
  }
  componentDidUpdate(prevProps){
    if(prevProps.value !== this.props.value){
      this.setState({value: this.props.value})
    }
  }
  handleOptionChange(changeEvent) {
    const selectedOption = changeEvent.target.value
    this.props.onChange(selectedOption)
  }
  renderContent(){
    const {options, name} = this.props
    const {value} = this.state
    const inputs = options.map(item => {
      const title = item.title? item.title: false
      const isChecked = (item.value == value)
      let labelClassName = `user-disability-modal__radio-label user-disability-modal__radio-label_${item.value}`
      if(isChecked){
        labelClassName += ' user-disability-modal__radio-label_checked' 
      }
      return(
        <label
          className={labelClassName}
          key={item.value}
        >
          <input
            className='user-disability-modal__radio-input' 
            type="radio" 
            name={name} 
            checked={value === item.value}
            onChange={this.handleOptionChange}
            value={item.value}/>
          <span
            className='user-disability-modal__radio-input-custom'
          ></span>
          {title? <b>{this.props.t(title)}</b>: null}
          {this.props.t(item.label)}  
        </label>
      )
      
    })
    return (
      <div className='user-disability-modal__radio-wrapper'>
        {inputs}
      </div>
    )
  }  
  render(){
    return (
      this.renderContent()
    )
  }    
}
export default UserDisabilityRadioButtons