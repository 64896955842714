import React from 'react'
import GoogleInputField from '../google-input-field.jsx'
import UserDisabilityRadioButtons from './user-disability-radio-buttons.jsx'
import UserDisabilityCheckboxes from './user-disability-checkboxes.jsx'
import Slider from 'rc-slider'
import InputField from '../input-field.jsx'

class UserDisabilityQuestions extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      questionAgeValue: 18,
      allergyCustomField: '',
      equipmentExtraInfoField: '',
    }
    this.questions = {
      '1': 'dis_profile_who_is_travelling_with_a_disability',
      '1.1': 'dis_profile_choose_an_age',
      '2': 'dis_profile_where_do_you_live',
      '3': 'dis_profile_discover',
      '4': 'dis_profile_what_type_of_disability',
      '4.1': 'dis_profile_what_equipment',
      '4.2': 'dis_profile_what_kind_of_allergies',
      '5': 'landing_page_mobility_header',         
    }
    this.questionWhoOptions = [
      {value: 'me', label: 'dis_profile_me'},
      {value: 'family_members', label: 'dis_profile_family_member'},
      {value: 'assistant', label: 'dis_profile_assistant'},
      {value: 'others', label: 'dis_profile_other'},
    ]
    this.destinationOptions = [
      {value: 'France', label: 'France'},
      {value: 'Spain', label: 'Spain'},
      {value: 'UK', label: 'UK'},
    ]
    this.disabilityOptions = [
      {value: 'mobility_impairment', label: 'dis_profile_mobility_impairment'},
      {value: 'hearing_impairment', label: 'dis_profile_hearing_impairment'},
      {value: 'visual_impairment', label: 'dis_profile_visual_impairment'},
      {value: 'intellectual_disability', label: 'dis_profile_intellectual_disability'},
      {value: 'allergy', label: 'dis_profile_allergy'},
    ]
    this.mobilityEquipmentOptions = [
      {value: 'manual_wheelchair', label: 'dis_profile_manual_wheelchair'},
      {value: 'electrical_wheelchair', label: 'dis_profile_electrical_wheelchair'},
      {value: 'walker', label: 'dis_profile_walker'},
      {value: 'crutch', label: 'dis_profile_crutch'},
      {value: 'stick', label: 'dis_profile_walking_stick'},
      {value: 'leading_dog', label: 'dis_profile_assistance_dog'},
    ]
    this.mobilityOptions = [
      {value: '1',
        label: 'landing_page_category_1_text',
        title: 'landing_page_category_1_header'
      }, 
      {value: '2',
        label: 'mobile_dropdown_category_2_description',
        title: 'landing_page_category_2_header'
      },
      {value: '3',
        label: 'dis_profile_accessible_accommodation',
        title: 'landing_page_category_3_header'
      },
    ]
    this.allergyOptions = [
      {value: 'mold', label: 'dis_profile_allergy_mold'},
      {value: 'dust', label: 'dis_profile_allergy_dust'},
      {value: 'fur', label: 'dis_profile_allergy_fur'},
      {value: 'food', label: 'dis_profile_allergy_food'},
    ]
    this.renderWhoQuestion = this.renderWhoQuestion.bind(this)
    this.renderAgeQuestion = this.renderAgeQuestion.bind(this)
    this.renderResidenceQuestion = this.renderResidenceQuestion.bind(this)
    this.onQuestionAgeChange = this.onQuestionAgeChange.bind(this)
    this.renderDestinationQuestion = this.renderDestinationQuestion.bind(this)
    this.renderDisabilityQuestion = this.renderDisabilityQuestion.bind(this)
    this.renderEquipmentQuestion = this.renderEquipmentQuestion.bind(this)
    this.renderMobilityQuestion = this.renderMobilityQuestion.bind(this)
    this.renderAllergyQuestion = this.renderAllergyQuestion.bind(this)
    this.onReceiveDestinationResult = this.onReceiveDestinationResult.bind(this)
    this.handleAllergyCustomInput = this.handleAllergyCustomInput.bind(this)
    this.onAddCustomAllergy = this.onAddCustomAllergy.bind(this)
    this.renderCustomAllergyInfo = this.renderCustomAllergyInfo.bind(this)
    this.clearCustomAllergyInfo = this.clearCustomAllergyInfo.bind(this)
    this.clearEquipmentExtraInfo = this.clearEquipmentExtraInfo.bind(this)
    this.renderEquipmentExtraInfo = this.renderEquipmentExtraInfo.bind(this)
    this.handleEquipmentExtraInfo = this.handleEquipmentExtraInfo.bind(this)
    this.onAddEquipmentExtraInfo = this.onAddEquipmentExtraInfo.bind(this)
  }
  clearCustomAllergyInfo(index){
    let {allergyExtraInfo} = this.props
    allergyExtraInfo.splice(index, 1)
    this.props.onAddCustomAllergy(allergyExtraInfo)
  }
  clearEquipmentExtraInfo(index){
    let {mobilityEquipmentExtraInfo} = this.props
    mobilityEquipmentExtraInfo.splice(index, 1)
    this.props.onAddEquipmentExtraInfo(mobilityEquipmentExtraInfo)
  }
  renderEquipmentExtraInfo(){
    const {mobilityEquipmentExtraInfo} =this.props
    let layout = null
    if(mobilityEquipmentExtraInfo.length){
      layout = mobilityEquipmentExtraInfo.map((item, index)=>{
        return (
          <div 
            className='user-disability-modal__question_allergies-custom-allergy-wrapper'
            key={`${item}-${index}-mobilityEquipmentExtraInfo`}
          >
            <span className='user-disability-modal__question_allergies-custom-allergy-title'>
              {item}
            </span>
            <button
              className='user-disability-modal__question_allergies-custom-allergy-button'
              onClick={this.clearEquipmentExtraInfo.bind(null, index)}
            />
          </div>
        )
      })
    }
    return layout
  }
  renderCustomAllergyInfo(){
    const {allergyExtraInfo} =this.props
    let layout = null
    if(allergyExtraInfo.length){
      layout = allergyExtraInfo.map((item, index)=>{
        return (
          <div 
            className='user-disability-modal__question_allergies-custom-allergy-wrapper'
            key={`${item}-${index}-allergyExtraInfo`}
          >
            <span className='user-disability-modal__question_allergies-custom-allergy-title'>
              {item}
            </span>
            <button
              className='user-disability-modal__question_allergies-custom-allergy-button'
              onClick={this.clearCustomAllergyInfo.bind(null, index)}
            />
          </div>
        )
      })
    }
    return layout
  }
  handleAllergyCustomInput(allergyCustomField){
    this.setState({allergyCustomField})
  }
  handleEquipmentExtraInfo(equipmentExtraInfoField){
    this.setState({equipmentExtraInfoField})
  }
  onAddCustomAllergy(){
    let {allergyExtraInfo} = this.props
    const {allergyCustomField} = this.state
    allergyExtraInfo.push(allergyCustomField)
    this.setState({allergyCustomField: ''}, ()=>{
      this.props.onAddCustomAllergy(allergyExtraInfo)
    })
  }
  onAddEquipmentExtraInfo(){
    let {mobilityEquipmentExtraInfo} = this.props
    const {equipmentExtraInfoField} = this.state
    mobilityEquipmentExtraInfo.push(equipmentExtraInfoField)
    this.setState({equipmentExtraInfoField: ''}, ()=>{
      this.props.onAddEquipmentExtraInfo(mobilityEquipmentExtraInfo)
    })
  }
  renderAllergyQuestion(){
    const {allergies,
      onAllergiesChange,
    } = this.props
    return(
      <div className='user-disability-modal__question user-disability-modal__question_allergies'>
        <div className='user-disability-modal__question_allergies-checkboxes-wrapper'>
          <UserDisabilityCheckboxes
            t={this.props.t}
            options={this.allergyOptions}
            values={allergies}
            onChange={onAllergiesChange}
          />
        </div>
        <div className='user-disability-modal__question_allergies-custom-allergy'>
          <div className='user-disability-modal__question_allergies-input-wrapper'>
            <InputField
              placeholder={this.props.t('dis_profile_type_additional_info')}
              onChange={this.handleAllergyCustomInput}
              value={this.state.allergyCustomField}
            />
            <button
              disabled={this.state.allergyCustomField.trim() ? false : true } 
              className='user-disability-modal__question_allergies-button'
              onClick={this.onAddCustomAllergy}
            >Add</button>
          </div>
          <div className='user-disability-modal__question_allergies-custom-allergy-cards'>
            {this.renderCustomAllergyInfo()}
          </div>
        </div>
      </div>
    )
  }
  renderMobilityQuestion(){
    const {mobility, onMobilityChange} = this.props
    return(
      <div className='user-disability-modal__question user-disability-modal__question_mobility'>
        <UserDisabilityRadioButtons
          t={this.props.t}
          value={mobility}
          onChange={onMobilityChange}
          options={this.mobilityOptions}
          name={'mobility'}
        />
      </div>
    )
  }
  renderEquipmentQuestion(){
    const {mobileEquipment, onMobileEquipmentChange} = this.props
    return(
      <div className='user-disability-modal__question user-disability-modal__question_equipment'>
        <UserDisabilityCheckboxes
          t={this.props.t}
          options={this.mobilityEquipmentOptions}
          values={mobileEquipment}
          onChange={onMobileEquipmentChange}
        />
        <div className='user-disability-modal__question_allergies-custom-allergy'>
          <div className='user-disability-modal__question_allergies-input-wrapper'>
            <InputField
              placeholder={this.props.t('dis_profile_type_additional_info')}
              onChange={this.handleEquipmentExtraInfo}
              value={this.state.equipmentExtraInfoField}
            />
            <button 
              disabled={this.state.equipmentExtraInfoField.trim() ? false : true }
              className='user-disability-modal__question_allergies-button'
              onClick={this.onAddEquipmentExtraInfo}
            >Add</button>
          </div>
          <div className='user-disability-modal__question_allergies-custom-allergy-cards'>
            {this.renderEquipmentExtraInfo()}
          </div>
        </div>
      </div>
    )
  }
  renderWhoQuestion(){
    return(
      <div className='user-disability-modal__question user-disability-modal__question-who'>
        <UserDisabilityRadioButtons
          t={this.props.t}
          value={this.props.questionWhoValue}
          onChange={this.props.onQuestionWhoChange}
          options={this.questionWhoOptions}
          name={'who'}
        />
      </div>
    )
  }
  onQuestionAgeChange(questionAgeValue){
    this.setState({questionAgeValue})
  }

  onReceiveDestinationResult(val){
    const {onDestinationsChange, destinations} = this.props
    const newDestination = {value: val, label: val}
    const destinationExist = this.destinationOptions.find((item) =>{
      return item.value ===  newDestination.value
    })
    if(!destinationExist){
      this.destinationOptions.push(newDestination)
    }
    let selectedDestinations = destinations
    selectedDestinations.push(val)
    onDestinationsChange(selectedDestinations)
  }
  renderDestinationQuestion(){
    const {
      destinations,
      onDestinationsChange,
      destinationField,
      onDestinationFieldChange
    } = this.props
    return(
      <div className='user-disability-modal__question user-disability-modal__question_destination'>
        <GoogleInputField 
          value={destinationField}
          onChange={onDestinationFieldChange}
          addressOnly={true}
          placeholder={this.props.t('dis_profile_start_typing_a_location')}
          onReceivedSearchResults={this.onReceiveDestinationResult}
        />
        <UserDisabilityCheckboxes
          t={this.props.t}
          values={destinations}
          onChange={onDestinationsChange}
          options={this.destinationOptions}
        />
      </div>
    )
  }
  renderAgeQuestion(){
    const {questionAgeValue} = this.state
    return(
      <div className='user-disability-modal__question user-disability-modal__question_age'>
        <span
          className="user-disability-modal__question-age-value"
        >
          {questionAgeValue}
        </span>
        <Slider
          className='user-disability-modal__question-age-slider'
          min={18}
          max={122}
          onChange={this.onQuestionAgeChange}
          onAfterChange={this.props.onQuestionAgeChange}
          value={questionAgeValue}
        />
      </div>
    )
  }
  renderResidenceQuestion(){
    const {residence, onResidenceChange} = this.props
    return(
      <div className='user-disability-modal__question user-disability-modal__question_residence'>
        <GoogleInputField 
          value={residence}
          onChange={onResidenceChange}
          addressOnly={true}
          placeholder={this.props.t('dis_profile_start_typing_a_location')}
        />
      </div>
    )
  } 
  renderDisabilityQuestion(){
    const {disabilities, onDisabilitiesChange} = this.props
    return(
      <div className='user-disability-modal__question user-disability-modal__question_disability'>
        <UserDisabilityCheckboxes
          t={this.props.t}
          options={this.disabilityOptions}
          values={disabilities}
          onChange={onDisabilitiesChange}
        />
      </div>
    )
  }
  renderQuestion(question){
    switch(question){
    case 1:
      return this.renderWhoQuestion()
    case 1.1:
      return this.renderAgeQuestion()
    case 2:
      return this.renderResidenceQuestion()
    case 3:
      return this.renderDestinationQuestion()
    case 4:
      return this.renderDisabilityQuestion()
    case 4.1:
      return this.renderEquipmentQuestion()
    case 4.2:
      return this.renderAllergyQuestion()  
    case 5:
      return this.renderMobilityQuestion()          
    }
    
  }
 
  render(){
    const {question} = this.props
    return(
      <React.Fragment>
        <div className='user-disability-modal__questions-title'>
          <div className='user-disability-modal__questions-number'>
            {`${question}`}
          </div>  
          <span className='user-disability-modal__questions-text'>
            {this.props.t(this.questions[question])}
          </span>
        </div>
        <div
          className='user-disability-modal__question-wrapper'
        >
          {this.renderQuestion.call(this, question)}
        </div>
      </React.Fragment>
    )
  }
}
export default UserDisabilityQuestions