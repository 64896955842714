/* eslint-disable no-mixed-spaces-and-tabs */
module.exports = {
  putFacilities: [
    {
		  priority: 1,
		  label: 'Wireless Internet',
		  value: 'wireless_internet',
		  image: 'wifi.svg'
    },
    {
		  priority: 2,
		  label: 'Free Cable Internet',
		  value: 'free_cable_internet',
		  image: 'free_cable_internet.svg'
    },
    {	  priority: 4,
		  label: 'Minibar',
		  value: 'minibar',
		  image: 'minibar.svg'
    },
    {	  priority: 5,
		  label: 'Tv',
		  value: 'tv',
		  image: 'tv.svg'
    },
    {
		  label: 'Smart Tv',
		  value: 'smart_tv',
		  image: 'smart_tv.svg'
    },
    {
		  label: 'Alarm Clock',
		  value: 'alarm_clock',
		  image: 'alarm_clock.svg'
    },
    {
		  label: 'Satellite Tv',
		  value: 'satellite_tv',
		  image: 'satellite_tv.svg'
    },
    {
		  label: 'Suitable For Children',
		  value: 'suitable_for_children',
		  image: 'suitable_for_children.svg'
    },
    {
		  label: 'Safe',
		  value: 'safe',
		  image: 'safe.svg'
    },
    {
		  label: 'Iron',
		  value: 'iron',
		  image: 'iron.svg'
    },
    {
		  label: 'Safe Deposit',
		  value: 'safe_deposit',
		  image: 'safe_deposit.svg'
    },
    {
		  label: 'Ironing Board',
		  value: 'ironing_board',
		  image: 'ironing_board.svg'
    },
    {
		  label: 'Refrigerator',
		  value: 'refrigerator',
		  image: 'refrigerator.svg'
    },
    {
		  label: 'Heating',
		  value: 'heating',
		  mage: 'heating.svg'
    },
    {
		  label: 'Fridge',
		  value: 'fridge',
		  image: 'fridge.svg'
    },
    {
		  label: 'Fridge / Freezer',
		  value: 'fridge_freezer',
		  image: 'fridge.svg'
    },
    {
		  label: 'Coffee Maker',
		  value: 'coffee_maker',
		  image: 'coffee_maker.svg'
    },
    {
		  label: 'Complimentary Tea & Coffee',
		  value: 'complimentary_tea_&_coffee',
		  image: 'complimentary_tea.svg'
    },
    {
		  label: 'Kettle',
		  value: 'kettle',
		  image: 'kettle.svg'
    },
    {
		  label: 'Bathrobes',
		  value: 'bathrobes',
		  image: 'bathrobes.svg'
    },
    {
		  label: 'Wardrobe',
		  value: 'wardrobe',
		  image: 'wardrobe.svg'
    },
    {
		  label: 'Wake-Up Service',
		  value: 'wake_up_service',
		  image: 'wake_up_service.svg'
    }, 
    {
		  label: 'Built-In Wardrobes',
		  value: 'built_in_wardrobes',
		  image: 'built_in_wardrobes.svg'
    },
    {
		  label: 'Bed Linen',
		  value: 'bed_linen',
		  image: 'bed_linen.svg'
    },
    {
		  label: 'Desk',
		  value: 'desk',
		  image: 'desk.svg'
    }, 
    {
		  label: 'Cupboards',
		  value: 'cupboards',
		  image: 'cupboards.svg'
    },
    {
		  label: 'Fan',
		  value: 'fan',
		  image: 'fan.svg'
    },
    {
		  label: 'Internet Browser Tv',
		  value: 'internet_browser_tv',
		  image: 'internet_browser_tv.svg'
    },
    {
		  label: 'Paid Cable Internet',
		  value: 'paid_cable_internet',
		  image: 'paid_cable_internet.svg'
    },
    {
		  label: 'Paid Wireless Internet',
		  value: 'paid_wireless_internet',
		  image: 'paid_wireless_internet.svg'
    },
    {
		  label: 'Night Table',
		  value: 'night_table',
		  image: 'night_table.svg'
    },
    {
		  label: 'Night Tables',
		  value: 'night_tables',
		  image: 'night_table.svg'
    }, 
    {
		  label: 'Reading Lamps',
		  value: 'reading_lamps',
		  image: 'reading_lamps.svg'
    },
    {
		  label: 'Bottled Water',
		  value: 'bottled_water',
		  image: 'bottled_water.svg'
    }, 
    {
		  label: 'Tv (Local Channels Only)',
		  value: 'tv_local_channels_only',
		  image: 'tv.svg'
    }, 
    {
		  label: 'Hair Dryer',
		  value: 'hair_dryer',
		  image: 'hairdryer.svg'
    },
    {
		  label: 'Slippers',
		  value: 'slippers',
		  image: 'slippers.svg'
    }, 
    {
		  label: 'Toilet',
		  value: 'toilet',
		  image: 'toilet.svg'
    },
    {
		  priority: 5,
		  label: 'Toiletries',
		  value: 'toiletries',
		  image: 'toiletries.svg'
    },
    {
		  label: 'Towels',
		  value: 'towels',
		  image: 'towels.svg'
    },
	  ],
  propertyFacilities: [
    {
      priority: 6,
      label: '24 Hour Front Desk',
      value: 'breakfast_booking_possible',
      image: 'breakfast_available.svg'
    },
    {
      priority: 9,
      label: 'Fitness Center Or Spa',
      value: 'fitness_center_or_spa',
      image: 'fitness.svg'
    },
    {
      priority: 1,
      label: 'Free Parking',
      value: 'free_parking',
      image: 'free_parking.svg'
    },
    {
      priority: 2,
      label: 'Parking',
      value: 'parking',
      image: 'parking.svg'
    },
    {
      priority: 10,
      label: 'Swimming Pool',
      value: 'swimming_pool',
      image: 'pool.svg'
    },
    {
      priority: 7,
      label: 'Restaurant',
      value: 'restaurant',
      image: 'restaurant.svg'
    },
    {
      priority: 5,
      label: 'Room Service',
      value: 'room_service',
      image: 'room_service.svg'
    },
    {
      priority: 3,
      label: 'Smoke-free Rooms',
      value: 'no_smoking_rooms_facilities',
      image: 'smoke-free.svg'
    },
    {
      priority: 4,
      label: 'Free Wireless Internet',
      value: 'free_wireless_internet',
      image: 'wifi.svg'
    },
  ],
  propertyTypeMapSearch: [
    {
      key: 'Homes and apartments',
      value: 'home_and_apartments',
      type: 'srp'
    },
    {
      key: 'Hotel',
      value: 'hotel',
      type: 'mrp'
    },
    {
      key: 'Bed and breakfast',
      value: 'bed_and_breakfast',
      type: 'multiSrp'
    },
    {
      key: 'Cottages',
      value: 'cottages',
      type: 'multiSrp'
    },
    {
      key: 'Other',
      value: 'other',
      type: 'multiSrp'
    },
  ],
  propertyTypeMap: [
    {
      key: 'Apartment',
      value: 'apartment',
      type: 'srp'
    },
    {
      key: 'Serviced apartment',
      value: 'serviced_apartment',
      type: 'multiSrp'
    },
    {
      key: 'House',
      value: 'house',
      type: 'srp'
    },
    {
      key: 'Bed and breakfast',
      value: 'bed_and_breakfast',
      type: 'multiSrp'
    },
    {
      key: 'Hotel',
      value: 'hotel',
      type: 'mrp'
    },
    {
      key: 'Guesthouse',
      value: 'guesthouse',
      type: 'multiSrp'
    },
    {
      key: 'Bungalow',
      value: 'bungalow',
      type: 'multiSrp'
    },
    {
      key: 'Cabin',
      value: 'cabin',
      type: 'multiSrp'
    },
    {
      key: 'Condominium',
      value: 'condominium',
      type: 'multiSrp'
    },
    {
      key: 'Villa',
      value: 'villa',
      type: 'srp'
    },
    {
      key: 'Chalet',
      value: 'chalet',
      type: 'srp'
    },
    {
      key: 'Townhouse',
      value: 'townhouse',
      type: 'srp'
    },
    {
      key: 'Vacation home',
      value: 'vacation_home',
      type: 'srp'
    },
    {
      key: 'Boutique hotel',
      value: 'boutique_hotel',
      type: 'multiSrp'
    },
    {
      key: 'Nature lodge',
      value: 'nature_lodge',
      type: 'multiSrp'
    },
    {
      key: 'Hostel',
      value: 'hostel',
      type: 'mrp'
    },
    {
      key: 'Casa particular',
      value: 'casa_particular',
      type: 'multiSrp'
    },
    {
      key: 'Posada',
      value: 'posada',
      type: 'mrp'
    },
    {
      key: 'Minsu (Taiwan)',
      value: 'minsu_taiwan',
      type: 'srp'
    },
    {
      key: 'Ryokan (Japan)',
      value: 'ryokan_japan',
      type: 'srp',
    },
    {
      key: 'Pension (Korea)',
      value: 'pension_korea',
      type: 'srp'
    },
    {
      key: 'Heritage hotel (India)',
      value: 'heritage_hotel_india',
      type: 'mrp'
    },
    {
      key: 'Boat',
      value: 'boat',
      type: 'srp'
    },
    {
      key: 'Castle',
      value: 'castle',
      type: 'multiSrp'
    },
    {
      key: 'Cave',
      value: 'cave',
      type: 'srp'
    },
    {
      key: 'Earth house',
      value: 'earth_house',
      type: 'srp'
    },
    {
      key: 'Hut',
      value: 'hut',
      type: 'srp'
    },
    {
      key: 'Igloo',
      value: 'igloo',
      type: 'srp'
    },
    {
      key: 'Island',
      value: 'island',
      type: 'srp'
    },
    {
      key: 'Lighthouse',
      value: 'lighthouse',
      type: 'srp'
    },
    {
      key: 'Loft',
      value: 'loft',
      type: 'srp'
    },
    {
      key: 'Plane',
      value: 'plane',
      type: 'multiSrp'
    },
    {
      key: 'Camper/RV',
      value: 'camper_rv',
      type: 'srp'
    },
    {
      key: 'Tent',
      value: 'tent',
      type: 'multiSrp'
    },
    {
      key: 'Tipi',
      value: 'tipi',
      type: 'multiSrp'
    },
    {
      key: 'Yurt',
      value: 'yurt',
      type: 'multiSrp'
    },
    {
      key: 'Train',
      value: 'train',
      type: 'multiSrp'
    },
    {
      key: 'Treehouse',
      value: 'treehouse',
      type: 'multiSrp'
    },
  ],
  defaultModalStyle: {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 51, 0.6)'
    },
    content: {
      position: 'absolute',
      top: '42%',
      left: '50%',
      width: '360px',
      height: '280px',
      boxShadow: '0 20px 100px 0 rgba(24, 24, 40, 0.25), 0 12px 20px 0 rgba(24, 24, 40, 0.04)',
      background: '#fff',
      borderRadius: '3px',
      border: 'none',
      outline: 'none',
      padding: 0,
      transform: 'translate3d(-50%, -50%, 0)',
      boxSizing: 'content-box',
    }
  },
  MIN_PASSWORD_LENGTH: 3,
  propertyImageMandatoryOptions: [
    {label: 'Cover', value: 'cover'},
    {label: 'Property front', value: 'property_front'},
    {label: 'Property entrance', value: 'property_entry_door'},
  ],
  propertyImageOptionalOptions: [
    {label: 'Parking', value: 'parking'},
    {label: 'Garden', value: 'garden'},
  ],
  putImageMandatoryOptions: [
    {label: 'Bathroom', value: 'bathroom'},
    {label: 'Toilet', value: 'toilet'},
    {label: 'Bedroom', value: 'bedroom'},
    {label: 'Kitchen', value: 'kitchen'},
    {label: 'Living Room', value: 'living_room'},
  ],
  putImageOptionalOptions: [
    // {label: 'Kitchen', value: 'kitchen'},
  ],
  mrpHotelImageMandatoryOptions: [
    {label: 'Cover', value: 'cover'},
    {label: 'Property front', value: 'property_front'},
    {label: 'Property entrance', value: 'property_entry_door'},
  ],
  mrpHotelImageOptionalOptions: [
    {label: 'Parking', value: 'parking'},
    {label: 'Garden', value: 'garden'},
    {label: 'Guest room', value: 'guest_room'},
    {label: 'Restaurant', value: 'restaurant'},
    {label: 'Recreation', value: 'recreation'},
    {label: 'Lobby', value: 'lobby'},
    {label: 'Business Center', value: 'business_center'},
    {label: 'Pool', value: 'pool'},
    {label: 'Gym', value: 'gym'},
    {label: 'Spa', value: 'spa'},
    {label: 'Bar or lounge', value: 'bar_or_lounge'},
    {label: 'Map', value: 'map'},
    {label: 'Surroundings', value: 'surroundings'},
    {label: 'Exterior', value: 'exterior'},
    {label: 'Unlabeled', value: 'unlabeled'}
  ],
  mrpRoomImageMandatoryOptions: [
    {label: 'Bathroom', value: 'bathroom'},
    {label: 'Toilet', value: 'toilet'},
    {label: 'Bedroom', value: 'bedroom'},
    {label: 'Kitchen', value: 'kitchen'},
    {label: 'Living Room', value: 'living_room'},
  ],
  mrpRoomImageOptionalOptions: [
    {label: 'Cover', value: 'cover'},
    {label: 'Unlabeled', value: 'unlabeled'}
  ],
  cancellationPolicyMap: {
    strict: 'Strict',
    very_strict: 'Very strict',
    moderate: 'Moderate',
    light: 'Light',
    no_refund: 'No refund'
  },
  supportedLanguages: [
    {
      code: 'en',
      label: 'English',
      locale: 'en-gb'
    },
    {
      code: 'sv',
      label: 'Swedish',
      locale: 'sv-se'
    },
    {
      code: 'da',
      label: 'Danish',
      locale: 'da-dk'
    },
    {
      code: 'de',
      label: 'German',
      locale: 'de-de'
    },
    {
      code: 'es',
      label: 'Spanish',
      locale: 'es-es'
    },
    {
      code: 'fr',
      label: 'French',
      locale: 'fr-fr'
    },
    {
      code: 'hr',
      label: 'Croatian',
      locale: 'hr-hr'
    },
    {
      code: 'nn',
      label: 'Norwegian',
      locale: 'nn-no'
    },
  ],
  DEFAULT_LOCALE: 'en-gb',
  DEFAULT_CURRENCY: 'EUR',
  search: {
    rangeMinVal: 0,
    rangeMaxVal: 1000
  }
}
